<div class="container-full-width">
  <div *ngIf="isTimerWarning" class="alert alert-danger">
    <strong>Time is almost up.</strong> You have approximately 1 minute left to finish this item. It will be placed back into the queue after this. <span>{{ secondsLeft | number: '1.0-0' }} seconds</span>
  </div>
  <div *ngIf="isTimerComplete" class="alert alert-danger"><strong>Time is up.</strong> This item is being placed back into the queue.</div>
  <div class="alert alert-warning" role="alert">
    <a [routerLink]="['/content', 'user-generated', content.content.accountKey, content.content.contentKey]" class="btn btn-warning">Continue moderating this item</a>
  </div>
  <div class="row">
    <div class="col-3">
      <h5>Uploaded: {{ content.content.createdOn | date: 'medium' }}</h5>
      <div style="width:440px;height:600px;">
        <app-resource-viewer [url]="content.content.resourceUrl" [mediaType]="content.content.resourceMediaType"></app-resource-viewer>
      </div>
    </div>
    <div class="col-9">
      <div class="card w-25 float-left" *ngFor="let item of content.review.participantItems">
        <h5 class="card-title" [title]="item.contentKey">Uploaded: {{ item.createdOn | date: 'medium' }}</h5>
        <div style="width:320px;height:480px;">
          <app-resource-viewer [url]="item.resourceUrl" [mediaType]="item.resourceMediaType"></app-resource-viewer>
        </div>
        <div class="card-body">
          <span class="badge badge-lg" [class.badge-warning]="item.fingerprintIndicatorIsConfident" [class.badge-info]="item.fingerprintIndicatorIsFuzzy" [title]="item.fingerprintIndicatorTooltip">{{ item.fingerprintIndicatorText }}</span>
          <span [ngClass]="item.outcomeIndicatorClasses">{{ item.outcomeIndicatorText }}</span>
          <a [routerLink]="['/content', 'user-generated', content.content.accountKey, content.content.contentKey, 'compare', item.contentKey]" class="btn btn-link">Compare</a>
          <a *ngIf="item.outcomeIndicatorText === 'Q'" [routerLink]="['/content', 'user-generated', item.accountKey, item.contentKey]" target="_blank" class="btn btn-link" title="Ctrl/Cmd+Click to rapidly open in new background tab">Assign to Me</a>
        </div>
      </div>
    </div>
  </div>
</div>
