/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-generated-moderate-not-found.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./user-generated-moderate-not-found.component";
var styles_UserGeneratedModerateNotFoundComponent = [i0.styles];
var RenderType_UserGeneratedModerateNotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserGeneratedModerateNotFoundComponent, data: {} });
export { RenderType_UserGeneratedModerateNotFoundComponent as RenderType_UserGeneratedModerateNotFoundComponent };
export function View_UserGeneratedModerateNotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "center-stage"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Item Not Found"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "lead"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This item was not found or has been assigned to someone else."]))], null, null); }
export function View_UserGeneratedModerateNotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-generated-moderate-not-found", [], null, null, null, View_UserGeneratedModerateNotFoundComponent_0, RenderType_UserGeneratedModerateNotFoundComponent)), i1.ɵdid(1, 114688, null, 0, i2.UserGeneratedModerateNotFoundComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserGeneratedModerateNotFoundComponentNgFactory = i1.ɵccf("app-user-generated-moderate-not-found", i2.UserGeneratedModerateNotFoundComponent, View_UserGeneratedModerateNotFoundComponent_Host_0, {}, {}, []);
export { UserGeneratedModerateNotFoundComponentNgFactory as UserGeneratedModerateNotFoundComponentNgFactory };
