/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./receipt-moderate-not-found.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./receipt-moderate-not-found.component";
var styles_ReceiptModerateNotFoundComponent = [i0.styles];
var RenderType_ReceiptModerateNotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReceiptModerateNotFoundComponent, data: {} });
export { RenderType_ReceiptModerateNotFoundComponent as RenderType_ReceiptModerateNotFoundComponent };
export function View_ReceiptModerateNotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "center-stage"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Item Not Found"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "lead"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This item was not found or has been assigned to someone else."]))], null, null); }
export function View_ReceiptModerateNotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-receipt-moderate-not-found", [], null, null, null, View_ReceiptModerateNotFoundComponent_0, RenderType_ReceiptModerateNotFoundComponent)), i1.ɵdid(1, 114688, null, 0, i2.ReceiptModerateNotFoundComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReceiptModerateNotFoundComponentNgFactory = i1.ɵccf("app-receipt-moderate-not-found", i2.ReceiptModerateNotFoundComponent, View_ReceiptModerateNotFoundComponent_Host_0, {}, {}, []);
export { ReceiptModerateNotFoundComponentNgFactory as ReceiptModerateNotFoundComponentNgFactory };
