/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./resource-thumbnail-viewer.component";
import * as i3 from "../../services/file-identification.service";
var styles_ResourceThumbnailViewerComponent = [];
var RenderType_ResourceThumbnailViewerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ResourceThumbnailViewerComponent, data: {} });
export { RenderType_ResourceThumbnailViewerComponent as RenderType_ResourceThumbnailViewerComponent };
function View_ResourceThumbnailViewerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "pdf-thumbnail"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["PDF"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 1, 0, currVal_0); }); }
function View_ResourceThumbnailViewerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "pdf-thumbnail"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Video"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 1, 0, currVal_0); }); }
function View_ResourceThumbnailViewerComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["height", "64"], ["width", "64"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 0, 0, currVal_0); }); }
function View_ResourceThumbnailViewerComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "pdf-thumbnail"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.file.extensionDisplay; _ck(_v, 2, 0, currVal_1); }); }
export function View_ResourceThumbnailViewerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceThumbnailViewerComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceThumbnailViewerComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceThumbnailViewerComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceThumbnailViewerComponent_4)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.file.isPDF; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.file.isVideo; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.file.isImage; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.file.isUnknown; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_ResourceThumbnailViewerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-resource-thumbnail-viewer", [], null, null, null, View_ResourceThumbnailViewerComponent_0, RenderType_ResourceThumbnailViewerComponent)), i0.ɵdid(1, 114688, null, 0, i2.ResourceThumbnailViewerComponent, [i3.FileIdentificationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResourceThumbnailViewerComponentNgFactory = i0.ɵccf("app-resource-thumbnail-viewer", i2.ResourceThumbnailViewerComponent, View_ResourceThumbnailViewerComponent_Host_0, { url: "url", mediaType: "mediaType" }, {}, []);
export { ResourceThumbnailViewerComponentNgFactory as ResourceThumbnailViewerComponentNgFactory };
