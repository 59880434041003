import { OnInit } from '@angular/core';
import { FileIdentificationService } from '../../services/file-identification.service';
var ResourceThumbnailViewerComponent = /** @class */ (function () {
    function ResourceThumbnailViewerComponent(fileIdentifier) {
        this.fileIdentifier = fileIdentifier;
        this.ngxImages = new Array();
        this.ngxImageConfig = {
            allowFullscreen: true
        };
    }
    ResourceThumbnailViewerComponent.prototype.ngOnInit = function () {
        this.file = this.fileIdentifier.identify(this.url, this.mediaType);
        if (this.file.isImage) {
            this.ngxImages.push(this.url);
        }
    };
    return ResourceThumbnailViewerComponent;
}());
export { ResourceThumbnailViewerComponent };
