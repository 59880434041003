<div *ngIf="file.isPDF" class="pdf">
  <a [href]="url" target="_blank">View PDF in new tab</a>
</div>
<ngx-image-viewer *ngIf="file.isImage" [src]="ngxImages" [config]="ngxImageConfig"></ngx-image-viewer>
<video *ngIf="file.isVideo" controls width="100%">
  <source [src]="url" />
  Sorry, your browser doesn't support embedded videos.
</video>
<div *ngIf="file.isUnknown" class="pdf">
  <a [href]="url" target="_blank">Open {{ file.extensionDisplay }} in new tab</a>
</div>
