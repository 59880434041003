<div class="container-full-width">
  <h2>
    Receipt Moderation<small class="ml-4"> <a *ngIf="content.review" [routerLink]="['/content', 'receipt', content.content.accountKey, content.content.contentKey, 'review']">Return to Receipt Review</a></small>
  </h2>
  <div *ngIf="content">
    <div *ngIf="isTimerWarning" class="alert alert-danger">
      <strong>Time is almost up.</strong> You have approximately 1 minute left to finish this item. It will be placed back into the queue after this. <span>{{ secondsLeft | number: '1.0-0' }} seconds</span>
    </div>
    <div *ngIf="isTimerComplete" class="alert alert-danger"><strong>Time is up.</strong> This item is being placed back into the queue.</div>
    <div *ngIf="content.consumer" class="alert alert-warning-orange"><strong>Consumer Information Warning</strong> The data for this receipt has been modified by the consumer. Please review with care.</div>
    <app-fraud-score-indicator [fraudScore]="content.content.resourceFraudScore" [fraudThreshold]="content.settings.fraudThreshold"></app-fraud-score-indicator>
    <app-resubmit-indicator type="receipt" [accountKey]="content.content.accountKey" [contentKey]="content.content.parentContentKey" ></app-resubmit-indicator>
    <div *ngIf="!isTimerComplete" class="row">
      <div class="col-3" id="imageAndFingerprintWarnings">
        <div style="width:440px;height:600px;"><app-resource-viewer [url]="content.content.resourceUrl" [mediaType]="content.content.resourceMediaType"></app-resource-viewer></div>
        <h5>Upload Date: {{ content.content.createdOn | date: 'medium' }}</h5>
        <app-digital-duplicate-indicator [isDuplicate]="content.receipt.isDuplicate" [parentContentKey]="content.content.parentContentKey"></app-digital-duplicate-indicator>
        <dl>
          <dd *ngFor="let fingerprintGroup of content.receipt.fingerprintCollisionGroups">
            <div class="alert" [class.alert-danger]="fingerprintGroup.category === constants.threeFactorCategory" [class.alert-warning]="fingerprintGroup.category === constants.twoFactorCategory">
              <i class="fa mr-3 pointer" [class.fa-minus-square-o]="fingerprintGroup.showCollisions" [class.fa-plus-square-o]="!fingerprintGroup.showCollisions" (click)="fingerprintGroup.showCollisions = !fingerprintGroup.showCollisions"></i>
              <span class="badge badge-light mr-3">{{ fingerprintGroup.collisions.length }}</span>
              <span class="pointer" (click)="fingerprintGroup.showCollisions = !fingerprintGroup.showCollisions">{{ fingerprintGroup.categoryDescription }}</span>
              <span class="view-duplicates"><a [routerLink]="['/content', 'receipt', content.content.accountKey, content.content.contentKey, 'duplicates', fingerprintGroup.category]" [queryParams]="{categoryDescription: fingerprintGroup.categoryDescription}">View All</a></span>
              <dl *ngIf="fingerprintGroup.showCollisions">
                <dd *ngFor="let fingerprint of fingerprintGroup.collisions">
                  <i *ngIf="fingerprint.isSecondPass" class="fa fa-exclamation-triangle mr-2" title="New Collision"></i><span>{{ fingerprint.createdOn | date: 'medium' }}</span>
                  <a [routerLink]="['/content', 'examine', 'receipt', fingerprint.accountKey, fingerprint.contentKey]" target="_blank">[View]</a>
                  <a [routerLink]="['/content', 'search', fingerprint.participantKey]" target="_blank">[View Participant]</a>
                  <i *ngIf="content.content.participantKey === fingerprint.participantKey" class="fa fa-user ml-2" title="Same Participant"></i>
                  <span class="badge-sm" [ngClass]="fingerprint.outcomeIndicatorClasses" [title]="fingerprint.outcomeIndicatorTooltip">{{ fingerprint.outcomeIndicatorText }}</span>
                </dd>
              </dl>
            </div>
          </dd>
        </dl>
      </div>
      <div class="col-6">
        <table class="table table-sm receipt-date">
          <thead>
            <tr>
              <th class="data-2-cols">Date Text</th>
              <th class="data-2-cols">Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ content.receipt.purchasedOnText }}</td>
              <td [class.consumer]="content.consumer && content.consumer.purchasedOn">
                <div class="input-group">
                  <input [owlDateTime]="purchasedOn" #purchasedOnInput="ngModel" required [(ngModel)]="content.receipt.purchasedOn" type="text" class="form-control" name="purchasedOnInput" />
                  <owl-date-time #purchasedOn pickerMode="dialog" pickerType="both" (afterPickerClosed)="onAfterPurchasedOnPickerClosed()" [hour12Timer]="true"></owl-date-time>
                  <div class="input-group-append" [owlDateTimeTrigger]="purchasedOn">
                    <span class="input-group-text"> <span class="fa fa-calendar cursor-pointer"></span> </span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table table-sm receipt-store">
          <thead>
            <tr>
              <th class="data-2-cols">Retailer Text</th>
              <th class="data-2-cols">Retailer</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ content.receipt.storeText }}</td>
              <td [class.consumer]="content.consumer && content.consumer.storeKey">
                <a href="javascript:void(0)" (click)="selectStoreForEditing()">{{ content.receipt?.storeName || 'Add' }}</a>
              </td>
            </tr>
          </tbody>
        </table>
        <table *ngIf="content.settings.isStoreNumberCaptured" class="table table-sm receipt-store-number">
          <thead>
            <tr>
              <th class="data-2-cols"></th>
              <th class="data-2-cols">Store Number</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td><input type="text" class="form-control" [(ngModel)]="content.receipt.storeNumber" /></td>
            </tr>
          </tbody>
        </table>
        <table *ngIf="content.settings.showTotal" class="table table-sm receipt-total">
          <thead>
            <tr>
              <th class="data-2-cols">Receipt Total Text</th>
              <th class="data-2-cols">Receipt Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ content.receipt.totalText }}</td>
              <td><input type="number" class="form-control" [(ngModel)]="content.receipt.total" step="0.01" /></td>
            </tr>
          </tbody>
        </table>
        <table class="table table-sm receipt-item-list">
          <thead>
            <tr>
              <th>Text</th>
              <th>Product</th>
              <th class="text-right" *ngIf="content.settings.showQuantity">Quantity</th>
              <th class="text-right" *ngIf="content.settings.showTotal">Total Price</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let receiptItem of content.receiptItems" [class.receipt-has-product]="receiptItem.productKey" [class.selected-receipt-item]="receiptItem == selectedReceiptItem" [class.consumer]="receiptItem.isConsumer">
              <td>
                <i class="fa fa-times" title="Remove Item" (click)="removeAddedReceiptItem(receiptItem)"></i>
                {{ receiptItem.text || (receiptItem.isConsumer ? 'ENTERED BY CONSUMER' : 'QUICK ADD BY MODERATOR') }}
              </td>
              <td>
                <a href="javascript:void(0)" (click)="selectReceiptItemForEditing(receiptItem)">{{ receiptItem.productName || 'Add' }}</a>
              </td>
              <td class="text-right" *ngIf="content.settings.showQuantity">
                <input type="number" class="form-control" *ngIf="receiptItem.productName" [(ngModel)]="receiptItem.quantity" />
                <ng-container *ngIf="!receiptItem.productName">{{ receiptItem.quantity }}</ng-container>
              </td>
              <td class="text-right" *ngIf="content.settings.showTotal">
                <input type="number" class="form-control" *ngIf="receiptItem.productName" [(ngModel)]="receiptItem.total" step="0.01" />
                <ng-container *ngIf="!receiptItem.productName">{{ receiptItem.total | currency }}</ng-container>
              </td>
            </tr>
            <tr *ngIf="!isAddingReceiptItem">
              <td><button class="btn btn-primary" (click)="startAddingReceiptItem()">Add Item</button></td>
              <td></td>
              <td></td>
            </tr>
            <tr *ngIf="isAddingReceiptItem">
              <td><input type="text" class="form-control" [(ngModel)]="newReceiptItemText" /></td>
              <td colspan="2">
                <button class="btn btn-danger float-right" (click)="abortAddingReceiptItem()"><i class="fa fa-times"></i></button>
                <button class="btn btn-success float-right mr-3" [disabled]="!newReceiptItemText" (click)="saveAddingReceiptItem()"><i class="fa fa-check"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-3">
        <ng-container *ngIf="content.settings.quickActions && content.settings.quickActions.length > 0">
          <ul class="actions">
            <li *ngFor="let action of content.settings.quickActions">
              <button class="btn btn-outline-secondary" (click)="performQuickAction(action)" [disabled]="action.performed">{{ action.caption }}</button>
            </li>
          </ul>
        </ng-container>
        <ng-container *ngIf="isEditingStore">
          <table class="table table-sm store-list">
            <thead>
              <tr>
                <th>Store</th>
              </tr>
              <tr>
                <th><input #storeFilter type="text" placeholder="Filter" class="form-control" /></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let store of (content.stores | searchfilter: 'name':storeFilter.value)">
                <td>
                  <button class="btn btn-primary btn-sm" (click)="selectStore(store)"><i class="fa fa-arrow-left" aria-hidden="true"></i></button> {{ store.name }}
                </td>
              </tr>
            </tbody>
          </table>
          <div><button *ngIf="content.receipt.storeKey" class="btn btn-danger" (click)="removeStoreFromReceipt()">Remove</button> <button class="btn" (click)="cancelStoreForEditing()">Cancel</button></div>
        </ng-container>
        <ng-container *ngIf="!isEditingStore">
          <table class="table table-sm product-list">
            <thead>
              <tr>
                <th>Product</th>
              </tr>
              <tr>
                <th><input #productFilter type="text" placeholder="Filter" class="form-control" /></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let product of (content.products | searchfilter: 'name':productFilter.value)">
                <td>
                  <button *ngIf="!selectedReceiptItem" class="btn btn-basic btn-sm quickAddButton" (click)="selectProductForReceiptItem(product)"><i class="fa fa-plus quickAddIcon" aria-hidden="true"></i></button>
                  <button *ngIf="selectedReceiptItem" class="btn btn-primary btn-sm" (click)="selectProductForReceiptItem(product)"><i class="fa fa-arrow-left" aria-hidden="true"></i></button> {{ product.name }}
                </td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="selectedReceiptItem"><button *ngIf="selectedReceiptItem" class="btn btn-danger" (click)="removeProductFromReceiptItem()">Remove</button> <button class="btn" (click)="cancelReceiptItemForEditing()">Cancel</button></div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="isSubmitting" class="row justify-content-md-center">
      <div class="col-4">
        <div class="card-group">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Saving...</h4>
              <app-loading-spinner></app-loading-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!isSubmitting && !isTimerComplete">
      <div class="col-12">
        <div class="card-group">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Cancel</h4>
              <button class="btn btn-warning mt-3" type="button" (click)="abortModeration()">Nevermind (unassign this from me)</button>
              <fieldset>
                <legend>Parking Lot</legend>
                <form #parkForm="ngForm" (ngSubmit)="parkModeration(parkForm)">
                  <input type="text" class="form-control" maxlength="1000" name="parkReason" [(ngModel)]="parkReason" required placeholder="Reason for parking this item" />
                  <button type="submit" class="btn btn-warning mt-3" [disabled]="parkForm.invalid">Park Item</button>
                </form>
              </fieldset>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Rejection Criteria</h4>
              <button *ngFor="let outcome of content.rejectionReasons" class="btn btn-danger mr-3 mt-3" (click)="cannotModerate(outcome)">{{ outcome.text }}</button>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Valid Receipt</h4>
              <div *ngIf="validatationErrorMessage" class="alert alert-danger">
                <strong>{{ validatationErrorMessage }}</strong>
              </div>
              <button class="btn btn-success mt-3" type="button" (click)="saveModeration()">Next <i class="fa fa-arrow-right"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div bsModal #saveWarningModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Warnings</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cancelSaveModeration()"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <p>The list of item(s) should be reviewed before proceeding.</p>
        <ul>
          <li *ngFor="let warning of saveWarnings">{{ warning }}</li>
        </ul>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-warning" (click)="confirmSaveModeration()">Continue with Warnings</button> <button type="button" class="btn btn-primary" (click)="cancelSaveModeration()">Return to Item</button>
      </div>
    </div>
  </div>
</div>
<div bsModal #secondPassCollisionsWarningModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Warnings</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cancelSecondPassWarning()"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <p>Based on the data you've provided, the updated fingerprint has returned {{ secondPassCollisionCount }} new fingerprint collisions.</p>
        <p>Please review before proceeding.</p>
        <div class="modal-footer">
          <button type="button" class="btn btn-warning" (click)="confirmSecondPassWarning()">Continue with Warnings</button> <button type="button" class="btn btn-primary" (click)="cancelSecondPassWarning()">Return to Item</button>
        </div>
      </div>
    </div>
  </div>
</div>
