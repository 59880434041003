import { TimerService } from './services/timer-service';
import { UnauthorizedWatcherService } from './services/unauthorized-watcher.service';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { AuthModule, OidcSecurityService, OpenIDImplicitFlowConfiguration } from 'angular-auth-oidc-client';
import { DateTimeAdapter, OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { environment } from '../environments/environment';
import { ImageViewerModule } from 'ngx-image-viewer';
import { ModalModule } from 'ngx-bootstrap/modal';
import { UtcDateTimeAdapter } from './adapters/UtcDateTimeAdapter';

import { ModerationApiService } from './apis/moderation-api.service';
import { CurrentContentProviderService } from './services/current-content-provider.service';
import { AppComponent } from './app.component';
import { TopnavComponent } from './nav/topnav/topnav.component';
import { ForbiddenComponent } from './pages/forbidden/forbidden.component';
import { SessionExpiredComponent } from './pages/sessionexpired/sessionexpired.component';
import { HomeComponent } from './pages/home/home.component';
import { AuthInterceptor } from './services/auth-interceptor.service';
import { LoadingSpinnerComponent } from './widgets/loading-spinner/loading-spinner.component';
import { ReceiptModerateParentComponent } from './pages/receipt-moderate-parent.component';
import { ReceiptModerateComponent } from './pages/receipt-moderate/receipt-moderate.component';
import { ReceiptModerateReviewReceiptsComponent } from './pages/receipt-moderate-review-receipts/receipt-moderate-review-receipts.component';
import { NextAvailableComponent } from './pages/next-available/next-available.component';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { ReceiptExamineDetailComponent } from './pages/receipt-examine-detail/receipt-examine-detail.component';
import { ReceiptModerateCompareComponent } from './pages/receipt-moderate-compare/receipt-moderate-compare.component';
import { ContentResolver } from './resolvers/content-resolver.service';
import { ReceiptModerateNotFoundComponent } from './pages/receipt-moderate-not-found/receipt-moderate-not-found.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProgramDashboardComponent } from './pages/program-dashboard/program-dashboard.component';
import { ResourceViewerComponent } from './widgets/resource-viewer/resource-viewer.component';
import { ResourceThumbnailViewerComponent } from './widgets/resource-thumbnail-viewer/resource-thumbnail-viewer.component';
import { ProgramDataExportComponent } from './pages/program-data-export/program-data-export.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { UserGeneratedModerateParentComponent } from './pages/user-generated-moderate-parent.component';
import { UserGeneratedModerateComponent } from './pages/user-generated-moderate/user-generated-moderate.component';
import { UserGeneratedContentResolver } from './resolvers/user-generated-content-resolver.service';
import { UserGeneratedModerateNotFoundComponent } from './pages/user-generated-moderate-not-found/user-generated-moderate-not-found.component';
import { NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';
import { UserGeneratedModerateReviewComponent } from './pages/user-generated-moderate-review/user-generated-moderate-review.component';
import { UserGeneratedModerateCompareComponent } from './pages/user-generated-moderate-compare/user-generated-moderate-compare.component';
import { FileIdentificationService } from './services/file-identification.service';
import { ContentSearchComponent } from './pages/content-search/content-search.component';
import { UserGeneratedExamineDetailComponent } from './pages/user-generated-examine-detail/user-generated-examine-detail.component';
import { ReceiptModerateDuplicatesComponent } from './pages/receipt-moderate-duplicates/receipt-moderate-duplicates.component';
import { ReceiptExamineParentComponent } from './pages/receipt-examine-parent/receipt-examine-parent.component';
import { ReceiptExamineDuplicatesComponent } from './pages/receipt-examine-duplicates/receipt-examine-duplicates.component';
import { ReceiptExamineContentResolver } from './resolvers/receipt-examine-content-resolver.service';
import { ReportsHomeComponent } from './pages/reports-home/reports-home.component';
import { ResubmitIndicatorComponent } from './widgets/resubmit-indicator/resubmit-indicator.component';
import { DigitalDuplicateIndicatorComponent } from './widgets/digital-duplicate-indicator/digital-duplicate-indicator.component';
import { FraudScoreIndicatorComponent } from './widgets/fraud-score-indicator/fraud-score-indicator.component';

const appRoutes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'forbidden', component: ForbiddenComponent },
  { path: 'sessionexpired', component: SessionExpiredComponent },
  { path: 'reports', component: ReportsHomeComponent },
  { path: 'program/:programKey', component: ProgramDashboardComponent },
  { path: 'program/:programKey/data', component: ProgramDataExportComponent },
  {
    path: 'content/receipt/:accountKey/:contentKey',
    component: ReceiptModerateParentComponent,
    children: [
      { path: '', component: ReceiptModerateComponent, resolve: { thisIsAlwaysNullAndNotUsed: ContentResolver } },
      { path: 'review', component: ReceiptModerateReviewReceiptsComponent, resolve: { thisIsAlwaysNullAndNotUsed: ContentResolver } },
      { path: 'compare/:compareContentKey', component: ReceiptModerateCompareComponent, resolve: { thisIsAlwaysNullAndNotUsed: ContentResolver } },
      { path: 'duplicates/:category', component: ReceiptModerateDuplicatesComponent, resolve: { thisIsAlwaysNullAndNotUsed: ContentResolver } },
      { path: 'not-found', component: ReceiptModerateNotFoundComponent }
    ]
  },
  {
    path: 'content/user-generated/:accountKey/:contentKey',
    component: UserGeneratedModerateParentComponent,
    children: [
      { path: '', component: UserGeneratedModerateComponent, resolve: { thisIsAlwaysNullAndNotUsed: UserGeneratedContentResolver } },
      { path: 'review', component: UserGeneratedModerateReviewComponent, resolve: { thisIsAlwaysNullAndNotUsed: UserGeneratedContentResolver } },
      { path: 'compare/:compareContentKey', component: UserGeneratedModerateCompareComponent, resolve: { thisIsAlwaysNullAndNotUsed: UserGeneratedContentResolver } },
      { path: 'not-found', component: UserGeneratedModerateNotFoundComponent }
    ]
  },
  { path: 'content/search', component: ContentSearchComponent },
  { path: 'content/search/:criteria', component: ContentSearchComponent },
  {
    path: 'content/examine/receipt/:accountKey/:contentKey',
    component: ReceiptExamineParentComponent,
    children: [
      { path: '', component: ReceiptExamineDetailComponent, resolve: { thisIsAlwaysNullAndNotUsed: ReceiptExamineContentResolver } },
      { path: 'duplicates/:category', component: ReceiptExamineDuplicatesComponent, resolve: { thisIsAlwaysNullAndNotUsed: ReceiptExamineContentResolver } }
    ]
  },
  { path: 'content/examine/user-generated/:accountKey/:contentKey', component: UserGeneratedExamineDetailComponent },
  { path: 'content/next/program/:programKey', component: NextAvailableComponent },
  { path: '**', component: HomeComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    TopnavComponent,
    HomeComponent,
    ForbiddenComponent,
    SessionExpiredComponent,
    LoadingSpinnerComponent,
    ResourceViewerComponent,
    ResourceThumbnailViewerComponent,
    ReceiptModerateComponent,
    NextAvailableComponent,
    SearchFilterPipe,
    ReceiptExamineDetailComponent,
    ReceiptModerateCompareComponent,
    ReceiptModerateParentComponent,
    ReceiptModerateReviewReceiptsComponent,
    ReceiptModerateNotFoundComponent,
    ProgramDashboardComponent,
    ProgramDataExportComponent,
    UserGeneratedModerateParentComponent,
    UserGeneratedModerateNotFoundComponent,
    UserGeneratedModerateComponent,
    UserGeneratedModerateReviewComponent,
    UserGeneratedModerateCompareComponent,
    ContentSearchComponent,
    ReceiptModerateDuplicatesComponent,
    UserGeneratedExamineDetailComponent,
    ReceiptExamineParentComponent,
    ReceiptExamineDuplicatesComponent,
    ReportsHomeComponent,
    ResubmitIndicatorComponent,
    DigitalDuplicateIndicatorComponent,
    FraudScoreIndicatorComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AuthModule.forRoot(),
    ModalModule.forRoot(),
    HttpClientModule,
    FormsModule,
    NgbButtonsModule,
    NgSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    RouterModule.forRoot(appRoutes),
    NgMultiSelectDropDownModule.forRoot(),
    ImageViewerModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: DateTimeAdapter, useClass: UtcDateTimeAdapter },
    ModerationApiService,
    FileIdentificationService,
    TimerService,
    UnauthorizedWatcherService,
    ContentResolver,
    ReceiptExamineContentResolver,
    UserGeneratedContentResolver,
    CurrentContentProviderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(public oidcSecurityService: OidcSecurityService) {
    const openIDImplicitFlowConfiguration = new OpenIDImplicitFlowConfiguration();
    openIDImplicitFlowConfiguration.stsServer = environment.oidc.server;
    openIDImplicitFlowConfiguration.redirect_url = environment.oidc.redirectUrl;
    openIDImplicitFlowConfiguration.client_id = environment.oidc.clientId;
    openIDImplicitFlowConfiguration.response_type = 'id_token token';
    openIDImplicitFlowConfiguration.scope = environment.oidc.scopes;
    openIDImplicitFlowConfiguration.post_logout_redirect_uri = environment.oidc.logoutRedirectUrl;
    openIDImplicitFlowConfiguration.start_checksession = false;
    openIDImplicitFlowConfiguration.silent_renew = true;
    openIDImplicitFlowConfiguration.silent_renew_offset_in_seconds = 0;
    openIDImplicitFlowConfiguration.post_login_route = '/home';
    openIDImplicitFlowConfiguration.trigger_authorization_result_event = true; // don't redirect to post_login_route
    openIDImplicitFlowConfiguration.forbidden_route = '/forbidden';
    openIDImplicitFlowConfiguration.unauthorized_route = '/unauthorized';
    openIDImplicitFlowConfiguration.auto_userinfo = true;
    openIDImplicitFlowConfiguration.log_console_warning_active = true;
    openIDImplicitFlowConfiguration.log_console_debug_active = false;
    openIDImplicitFlowConfiguration.max_id_token_iat_offset_allowed_in_seconds = 999;
    openIDImplicitFlowConfiguration.override_well_known_configuration = false;
    openIDImplicitFlowConfiguration.override_well_known_configuration_url = '';
    // example from sample - 'https://localhost:44386/wellknownconfiguration.json';
    // Comment out this storage override to use default Session Storage, which is tab specific
    openIDImplicitFlowConfiguration.storage = localStorage;

    this.oidcSecurityService.setupModule(openIDImplicitFlowConfiguration);

    // if you need custom parameters
    // oidcSecurityService.setCustomRequestParameters({ 't4': 'ABC abc 123', 't3': 'wo' });
  }
}
