import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-container',
  templateUrl: './user-generated-moderate-parent.component.html',
  styleUrls: [ ]
})
export class UserGeneratedModerateParentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
