import * as tslib_1 from "tslib";
import { DateTimeAdapter } from 'ng-pick-datetime';
import * as moment from 'moment';
import 'moment-timezone';
/**
 * This file is mostly lifed directly from
 *  https://github.com/DanielYKPan/date-time-picker/blob/master/src/date-time/adapter/native-date-time-adapter.class.ts
 * the source code for the NativeDateTimeAdapter class.
 *
 * I've re-created it mostly here so we can customize it as see fit,
 *  namely to display dates in the EASTERN time zone as opposed to local.
 */
/** The default month names to use if Intl API is not available. */
var DEFAULT_MONTH_NAMES = {
    'long': [
        'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
        'October', 'November', 'December'
    ],
    'short': ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    'narrow': ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']
};
/** The default day of the week names to use if Intl API is not available. */
var DEFAULT_DAY_OF_WEEK_NAMES = {
    'long': ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    'short': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    'narrow': ['S', 'M', 'T', 'W', 'T', 'F', 'S']
};
var TARGET_TZ_NAME = 'UTC';
/**
 * Matches strings that have the form of a valid RFC 3339 string
 * (https://tools.ietf.org/html/rfc3339). Note that the string may not actually be a valid date
 * because the regex will match strings an with out of bounds month, date, etc.
 */
var ISO_8601_REGEX = /^\d{4}-\d{2}-\d{2}(?:T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|(?:(?:\+|-)\d{2}:\d{2}))?)?$/;
/** Creates an array and fills it with values. */
function range(length, valueFunction) {
    var valuesArray = Array(length);
    for (var i = 0; i < length; i++) {
        valuesArray[i] = valueFunction(i);
    }
    return valuesArray;
}
var UtcDateTimeAdapter = /** @class */ (function (_super) {
    tslib_1.__extends(UtcDateTimeAdapter, _super);
    function UtcDateTimeAdapter(owlDateTimeLocale) {
        var _this = _super.call(this) || this;
        _this.owlDateTimeLocale = owlDateTimeLocale;
        _super.prototype.setLocale.call(_this, owlDateTimeLocale);
        _this.useUtcForDisplay = !(typeof document === 'object' && !!document &&
            /(msie|trident)/i.test(navigator.userAgent));
        return _this;
    }
    UtcDateTimeAdapter.prototype.getDateNames = function () {
        throw new Error('Method not implemented.');
    };
    UtcDateTimeAdapter.prototype.getYear = function (date) {
        return date.year();
    };
    UtcDateTimeAdapter.prototype.getMonth = function (date) {
        return date.month();
    };
    UtcDateTimeAdapter.prototype.getDay = function (date) {
        return date.day();
    };
    UtcDateTimeAdapter.prototype.getDate = function (date) {
        return date.date();
    };
    UtcDateTimeAdapter.prototype.getHours = function (date) {
        return date.hours();
    };
    UtcDateTimeAdapter.prototype.getMinutes = function (date) {
        return date.minutes();
    };
    UtcDateTimeAdapter.prototype.getSeconds = function (date) {
        return date.seconds();
    };
    UtcDateTimeAdapter.prototype.getTime = function (date) {
        return date.valueOf();
    };
    UtcDateTimeAdapter.prototype.getNumDaysInMonth = function (date) {
        return date.daysInMonth();
    };
    UtcDateTimeAdapter.prototype.differenceInCalendarDays = function (dateLeft, dateRight) {
        if (this.isValid(dateLeft) && this.isValid(dateRight)) {
            var dateLeftStartOfDay = this.createDate(this.getYear(dateLeft), this.getMonth(dateLeft), this.getDate(dateLeft));
            var dateRightStartOfDay = this.createDate(this.getYear(dateRight), this.getMonth(dateRight), this.getDate(dateRight));
            var timeStampLeft = this.getTime(dateLeftStartOfDay) + dateLeftStartOfDay.utcOffset() * this.milliseondsInMinute;
            var timeStampRight = this.getTime(dateRightStartOfDay) + dateRightStartOfDay.utcOffset() * this.milliseondsInMinute;
            return Math.round((timeStampLeft - timeStampRight) / this.millisecondsInDay);
        }
        else {
            return null;
        }
    };
    UtcDateTimeAdapter.prototype.getYearName = function (date) {
        return String(moment.tz(TARGET_TZ_NAME).year());
    };
    UtcDateTimeAdapter.prototype.getMonthNames = function (style) {
        return DEFAULT_MONTH_NAMES[style];
    };
    UtcDateTimeAdapter.prototype.getDayOfWeekNames = function (style) {
        return DEFAULT_DAY_OF_WEEK_NAMES[style];
    };
    UtcDateTimeAdapter.prototype.toIso8601 = function (date) {
        return date.toISOString();
    };
    UtcDateTimeAdapter.prototype.isEqual = function (dateLeft, dateRight) {
        if (this.isValid(dateLeft) && this.isValid(dateRight)) {
            return dateLeft.valueOf() === dateRight.valueOf();
        }
        else {
            return false;
        }
    };
    UtcDateTimeAdapter.prototype.isSameDay = function (dateLeft, dateRight) {
        if (this.isValid(dateLeft) && this.isValid(dateRight)) {
            var dateLeftStartOfDay = this.clone(dateLeft);
            var dateRightStartOfDay = this.clone(dateRight);
            dateLeftStartOfDay.hours(0).minutes(0).seconds(0).milliseconds(0);
            dateRightStartOfDay.hours(0).minutes(0).seconds(0).milliseconds(0);
            return dateLeftStartOfDay.valueOf() === dateRightStartOfDay.valueOf();
        }
        else {
            return false;
        }
    };
    UtcDateTimeAdapter.prototype.isValid = function (date) {
        return date && !isNaN(date.valueOf());
    };
    UtcDateTimeAdapter.prototype.invalid = function () {
        return moment.tz(NaN, TARGET_TZ_NAME);
    };
    UtcDateTimeAdapter.prototype.isDateInstance = function (obj) {
        return moment.isMoment(obj);
    };
    UtcDateTimeAdapter.prototype.addCalendarYears = function (date, amount) {
        return this.addCalendarMonths(date, amount * 12);
    };
    UtcDateTimeAdapter.prototype.addCalendarMonths = function (date, amount) {
        var result = this.clone(date);
        amount = Number(amount);
        var desiredMonth = result.month() + amount;
        var dateWithDesiredMonth = moment.tz([result.year(), desiredMonth, 1, 0, 0, 0, 0], TARGET_TZ_NAME);
        var daysInMonth = this.getNumDaysInMonth(dateWithDesiredMonth);
        // Set the last day of the new month
        // if the original date was the last day of the longer month
        result.month(desiredMonth).day(Math.min(daysInMonth, result.day()));
        return result;
    };
    UtcDateTimeAdapter.prototype.addCalendarDays = function (date, amount) {
        var result = this.clone(date);
        amount = Number(amount);
        result.day(result.day() + amount);
        return result;
    };
    UtcDateTimeAdapter.prototype.setHours = function (date, amount) {
        var result = this.clone(date);
        result.hours(amount);
        return result;
    };
    UtcDateTimeAdapter.prototype.setMinutes = function (date, amount) {
        var result = this.clone(date);
        result.minutes(amount);
        return result;
    };
    UtcDateTimeAdapter.prototype.setSeconds = function (date, amount) {
        var result = this.clone(date);
        result.seconds(amount);
        return result;
    };
    UtcDateTimeAdapter.prototype.createDate = function (year, month, date, hours, minutes, seconds) {
        if (hours === void 0) { hours = 0; }
        if (minutes === void 0) { minutes = 0; }
        if (seconds === void 0) { seconds = 0; }
        if (month < 0 || month > 11) {
            throw Error("Invalid month index \"" + month + "\". Month index has to be between 0 and 11.");
        }
        if (date < 1) {
            throw Error("Invalid date \"" + date + "\". Date has to be greater than 0.");
        }
        if (hours < 0 || hours > 23) {
            throw Error("Invalid hours \"" + hours + "\". Hours has to be between 0 and 23.");
        }
        if (minutes < 0 || minutes > 59) {
            throw Error("Invalid minutes \"" + minutes + "\". Minutes has to between 0 and 59.");
        }
        if (seconds < 0 || seconds > 59) {
            throw Error("Invalid seconds \"" + seconds + "\". Seconds has to be between 0 and 59.");
        }
        var result = this.createDateWithOverflow(year, month, date, hours, minutes, seconds);
        // Check that the date wasn't above the upper bound for the month, causing the month to overflow
        // For example, createDate(2017, 1, 31) would try to create a date 2017/02/31 which is invalid
        if (result.month() !== month) {
            throw Error("Invalid date \"" + date + "\" for month with index \"" + month + "\".");
        }
        return result;
    };
    UtcDateTimeAdapter.prototype.clone = function (date) {
        return moment.tz(date.valueOf(), date.tz());
    };
    UtcDateTimeAdapter.prototype.now = function () {
        return moment.tz(TARGET_TZ_NAME);
    };
    UtcDateTimeAdapter.prototype.format = function (date, displayFormat) {
        if (!this.isValid(date)) {
            throw Error('MomentJS: Cannot format invalid date.');
        }
        return this.stripDirectionalityCharacters(date.tz(TARGET_TZ_NAME).format('M/D/YY, h:mm A'));
    };
    UtcDateTimeAdapter.prototype.parse = function (value, parseFormat) {
        return value ? moment.tz(value, TARGET_TZ_NAME) : null;
    };
    /**
     * Returns the given value if given a valid Date or null. Deserializes valid ISO 8601 strings
     * (https://www.ietf.org/rfc/rfc3339.txt) into valid Dates and empty string into null. Returns an
     * invalid date for all other values.
     */
    UtcDateTimeAdapter.prototype.deserialize = function (value) {
        if (typeof value === 'string') {
            if (!value) {
                return null;
            }
            // The `Date` constructor accepts formats other than ISO 8601, so we need to make sure the
            // string is the right format first.
            if (ISO_8601_REGEX.test(value)) {
                var date = moment.tz(value, TARGET_TZ_NAME);
                if (this.isValid(date)) {
                    return date;
                }
            }
        }
        return _super.prototype.deserialize.call(this, value);
    };
    /**
     * Creates a date but allows the month and date to overflow.
     * @param {number} year
     * @param {number} month
     * @param {number} date
     * @param {number} hours -- default 0
     * @param {number} minutes -- default 0
     * @param {number} seconds -- default 0
     * @returns The new date, or null if invalid.
     * */
    UtcDateTimeAdapter.prototype.createDateWithOverflow = function (year, month, date, hours, minutes, seconds) {
        if (hours === void 0) { hours = 0; }
        if (minutes === void 0) { minutes = 0; }
        if (seconds === void 0) { seconds = 0; }
        var result = moment.tz([year, month, date, hours, minutes, seconds], TARGET_TZ_NAME);
        return result;
    };
    /**
     * Strip out unicode LTR and RTL characters. Edge and IE insert these into formatted dates while
     * other browsers do not. We remove them to make output consistent and because they interfere with
     * date parsing.
     * @param str The string to strip direction characters from.
     * @returns The stripped string.
     */
    UtcDateTimeAdapter.prototype.stripDirectionalityCharacters = function (str) {
        return str.replace(/[\u200e\u200f]/g, '');
    };
    return UtcDateTimeAdapter;
}(DateTimeAdapter));
export { UtcDateTimeAdapter };
