import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-fraud-score-indicator',
  templateUrl: './fraud-score-indicator.component.html',
  styleUrls: ['./fraud-score-indicator.component.css'],
})
export class FraudScoreIndicatorComponent implements OnInit {
  @Input() public fraudScore: number | null;
  @Input() public fraudThreshold: number;

  public showIndicator = false;

  constructor() {}

  ngOnInit() {
    this.showIndicator = this.fraudScore !== null && this.fraudScore > this.fraudThreshold;
  }
}
