import { Component, OnInit } from '@angular/core';
import { SecurePageBase } from '../secure-page-base';
import { Constants } from '../../constants';
import { UserGeneratedContentForModerationModel } from '../../models/user-generated-content-for-moderation.model';
import { Subscription, Observable, interval } from 'rxjs';
import { CurrentContentProviderService } from '../../services/current-content-provider.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';
import { TimerService } from '../../services/timer-service';
import { ModerationApiService } from '../../apis/moderation-api.service';
import { UserGeneratedContentForModerationReviewItemModel } from '../../models/user-generated-content-for-moderation-review-item.model';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-user-generated-moderate-review',
  templateUrl: './user-generated-moderate-review.component.html',
  styleUrls: ['./user-generated-moderate-review.component.css']
})
export class UserGeneratedModerateReviewComponent extends SecurePageBase {
  constants: typeof Constants;
  content: UserGeneratedContentForModerationModel;
  timerName: string;
  isTimerWarning = false;
  isTimerComplete = false;
  showActionButtons = true;
  secondsLeft: number;
  private abortSubscription: Subscription;
  private timerWarningSeconds = Constants.moderationTimeoutWarningSeconds;
  private isAbortingModeration = false;

  constructor(oidc: OidcSecurityService, router: Router, private _currentContent: CurrentContentProviderService, private _timer: TimerService, private _moderationApi: ModerationApiService) {
    super(oidc, router);
    this.constants = Constants;
  }

  onAuthorized(): void {
    const model = this._currentContent.getCurrentForUserGenerated();

    model.review.participantItems.forEach(i => {
      this.determineOutcomeIndicators(i);
    });

    this.timerName = model.content.contentKey;
    this.prepareTimer();

    this.content = model;
  }

  private determineOutcomeIndicators(item: UserGeneratedContentForModerationReviewItemModel): void {
    switch (item.moderationOutcome) {
      case Constants.moderationOutcomeSuccessful:
        item.outcomeIndicatorText = 'P';
        item.outcomeIndicatorBadgeClass = 'badge-success';
        item.outcomeIndicatorTooltip = 'Processed';
        break;
      case Constants.moderationOutcomeDuplicateImage:
      case Constants.moderationOutcomeDuplicateImageHuman:
        item.outcomeIndicatorText = 'D';
        item.outcomeIndicatorBadgeClass = 'badge-dark';
        item.outcomeIndicatorTooltip = 'Duplicate Item';
        break;
      default:
        if (item.moderationOutcome) {
          item.outcomeIndicatorText = 'R';
          item.outcomeIndicatorBadgeClass = 'badge-danger';
          item.outcomeIndicatorTooltip = `Rejected - ${item.moderationOutcome}`;
        } else {
          // not moderated yet
          item.outcomeIndicatorText = 'Q';
          item.outcomeIndicatorBadgeClass = 'badge-secondary';
          item.outcomeIndicatorTooltip = 'Queued';
        }
        break;
    }

    item.outcomeIndicatorClasses = {
      badge: true,
      'badge-lg': true
    };

    item.outcomeIndicatorClasses[item.outcomeIndicatorBadgeClass] = true;
  }

  onNgOnDestroy(): void {
    if (this.abortSubscription) {
      this.abortSubscription.unsubscribe();
    }
  }
  onShouldDisplayOwnUnauthorizedMessage(): boolean {
    return false;
  }

  //#region Timer
  private prepareTimer(): void {
    this._timer.delTimer(this.timerName);
    this._timer.newTimer(this.timerName, 1);
    this._timer.subscribe(this.timerName, () => this.timerTick());
  }
  private getTimeoutInSeconds(): number {
    return this.content.content.timeoutInMinutes * 60;
  }
  private timerTick(): void {
    const assignedOn = new Date(this.content.content.assignedOn);
    const milliseconds = new Date().getTime() - assignedOn.getTime();
    const seconds = milliseconds / 1000;
    const timeoutInSeconds = this.getTimeoutInSeconds();
    this.secondsLeft = timeoutInSeconds - seconds;

    if (this.secondsLeft < 0) {
      this.isTimerWarning = false;
      this.isTimerComplete = true;
      this._timer.delTimer(this.timerName);
      interval(3000)
        .pipe(takeWhile(() => !this.isAbortingModeration))
        .subscribe(() => {
          this.abortModeration();
        });
    } else if (this.secondsLeft < this.timerWarningSeconds) {
      this.isTimerWarning = true;
    }
  }
  //#endregion

  abortModeration(): void {
    this.isAbortingModeration = true;
    this.showActionButtons = false;
    this._timer.delTimer(this.timerName);

    this.abortSubscription = this._moderationApi.abortModerationOfContent(this.content.content.accountKey, this.content.content.contentKey).subscribe(() => {
      this.router.navigate(['/program', this.content.content.programKey]);
    });
  }
}
