import * as tslib_1 from "tslib";
import { Router, ActivatedRoute } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ModerationApiService } from '../../apis/moderation-api.service';
import { SecurePageBase } from '../secure-page-base';
import { CurrentContentProviderService } from '../../services/current-content-provider.service';
import { Constants } from '../../constants';
import { RuleSetType } from '../../models/rule-set-type.enum';
var ProgramDashboardComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ProgramDashboardComponent, _super);
    function ProgramDashboardComponent(oidc, router, _route, moderationApi, currentContent) {
        var _this = _super.call(this, oidc, router) || this;
        _this._route = _route;
        _this.moderationApi = moderationApi;
        _this.showActionButtons = true;
        _this.currentList = 'available';
        _this.isItemRequesting = false;
        _this.programKey = _this._route.snapshot.params['programKey'];
        _this.constants = Constants;
        _this.ruleSetTypes = RuleSetType;
        currentContent.clear();
        return _this;
    }
    ProgramDashboardComponent.prototype.populateDashboard = function () {
        var _this = this;
        this.dashboardSubscription = this.moderationApi.getProgramDashboard(this.programKey).subscribe(function (programDashboardModel) {
            _this.model = programDashboardModel;
        }, function (error) {
            _this.error = error;
        });
    };
    ProgramDashboardComponent.prototype.populateAvailableContentList = function () {
        var _this = this;
        this.showActionButtons = false;
        this.myContentSubscription = this.moderationApi.getMyStartedContent(this.programKey).subscribe(function (model) {
            _this.myContent = model;
            if (_this.myContent.items.length > 0) {
                _this.currentList = 'myWork';
            }
            else {
                _this.currentList = 'available';
            }
            _this.availableContentSubscription = _this.moderationApi.getAvailableContent(_this.programKey).subscribe(function (availableModel) {
                _this.availableContent = availableModel;
                _this.showActionButtons = true;
            });
            _this.parkedContentSubscription = _this.moderationApi.getParkedContent(_this.programKey).subscribe(function (parkedModel) {
                _this.parkedContent = parkedModel;
            });
        });
    };
    ProgramDashboardComponent.prototype.onNgOnDestroy = function () {
        if (this.dashboardSubscription) {
            this.dashboardSubscription.unsubscribe();
        }
        if (this.availableContentSubscription) {
            this.availableContentSubscription.unsubscribe();
        }
        if (this.myContentSubscription) {
            this.myContentSubscription.unsubscribe();
        }
        if (this.parkedContentSubscription) {
            this.parkedContentSubscription.unsubscribe();
        }
        if (this.unparkSubscription) {
            this.unparkSubscription.unsubscribe();
        }
    };
    ProgramDashboardComponent.prototype.onAuthorized = function () {
        this.populateDashboard();
        this.populateAvailableContentList();
    };
    ProgramDashboardComponent.prototype.onShouldDisplayOwnUnauthorizedMessage = function () {
        return true;
    };
    ProgramDashboardComponent.prototype.refreshAvailable = function () {
        this.populateDashboard();
        this.populateAvailableContentList();
    };
    ProgramDashboardComponent.prototype.activateList = function (list) {
        this.currentList = list;
    };
    ProgramDashboardComponent.prototype.requestItem = function (content) {
        this.isItemRequesting = true;
        var typeRoute = content.contentModerationType === RuleSetType.UserGenerated ? 'user-generated' : 'receipt';
        this.router.navigate(['/content', typeRoute, content.accountKey, content.contentKey]);
    };
    ProgramDashboardComponent.prototype.unpark = function (content) {
        var _this = this;
        this.showActionButtons = false;
        this.isItemRequesting = true;
        if (content.contentModerationType === RuleSetType.UserGenerated) {
            this.unparkSubscription = this.moderationApi.unparkAndGetUserGeneratedContentForModeration(content.accountKey, content.contentKey).subscribe(function () {
                _this.router.navigate(['/content', 'user-generated', content.accountKey, content.contentKey]);
            });
        }
        else {
            this.unparkSubscription = this.moderationApi.unparkAndGetReceiptContentForModeration(content.accountKey, content.contentKey).subscribe(function () {
                _this.router.navigate(['/content', 'receipt', content.accountKey, content.contentKey]);
            });
        }
    };
    return ProgramDashboardComponent;
}(SecurePageBase));
export { ProgramDashboardComponent };
