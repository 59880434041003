import { Router } from '@angular/router';
import { timer } from 'rxjs';
import { take, map, catchError } from 'rxjs/operators';
import { ModerationApiService } from '../apis/moderation-api.service';
import { CurrentContentProviderService } from '../services/current-content-provider.service';
var UserGeneratedContentResolver = /** @class */ (function () {
    function UserGeneratedContentResolver(_api, _router, _currentContent) {
        this._api = _api;
        this._router = _router;
        this._currentContent = _currentContent;
    }
    UserGeneratedContentResolver.prototype.resolve = function (route, state) {
        var _this = this;
        var accountKey = route.paramMap.get('accountKey') || route.parent.paramMap.get('accountKey');
        var contentKey = route.paramMap.get('contentKey') || route.parent.paramMap.get('contentKey');
        if (this._currentContent.isCurrent(accountKey, contentKey)) {
            return timer(0).pipe(map(function () {
                return null;
            }));
        }
        return this._api.assignAndGetUserGeneratedContentForModeration(accountKey, contentKey).pipe(take(1), catchError(function (err, caught) {
            if (err.status === 401) {
                _this._router.navigate(['/sessionexpired']);
                return null;
            }
            else {
                _this._router.navigate(['/content', 'user-generated', accountKey, contentKey, 'not-found']);
                return null;
            }
        }), map(function (model) {
            if (model) {
                // prepare the model as a view model with some defaults
                // So far, nothing to do on User Generated Content, but leaving this hook in here
                _this._currentContent.setCurrent(model);
                return null;
            }
            else {
                _this._currentContent.clear();
                _this._router.navigate(['/content', 'user-generated', accountKey, contentKey, 'not-found']);
                return null;
            }
        }));
    };
    return UserGeneratedContentResolver;
}());
export { UserGeneratedContentResolver };
