/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/ngx-image-viewer/ngx-image-viewer.ngfactory";
import * as i2 from "ngx-image-viewer";
import * as i3 from "@angular/common";
import * as i4 from "./resource-viewer.component";
import * as i5 from "../../services/file-identification.service";
var styles_ResourceViewerComponent = [];
var RenderType_ResourceViewerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ResourceViewerComponent, data: {} });
export { RenderType_ResourceViewerComponent as RenderType_ResourceViewerComponent };
function View_ResourceViewerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "pdf"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["View PDF in new tab"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 1, 0, currVal_0); }); }
function View_ResourceViewerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ngx-image-viewer", [], null, [["window", "keyup.ArrowRight"], ["window", "keyup.ArrowLeft"], [null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("window:keyup.ArrowRight" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).nextImage($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:keyup.ArrowLeft" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).prevImage($event) !== false);
        ad = (pd_1 && ad);
    } if (("mouseover" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1).onMouseOver() !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_ImageViewerComponent_0, i1.RenderType_ImageViewerComponent)), i0.ɵdid(1, 114688, null, 0, i2.ImageViewerComponent, [[2, "config"]], { src: [0, "src"], config: [1, "config"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ngxImages; var currVal_1 = _co.ngxImageConfig; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ResourceViewerComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "video", [["controls", ""], ["width", "100%"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "source", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Sorry, your browser doesn't support embedded videos.\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 1, 0, currVal_0); }); }
function View_ResourceViewerComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "pdf"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(2, null, ["Open ", " in new tab"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.file.extensionDisplay; _ck(_v, 2, 0, currVal_1); }); }
export function View_ResourceViewerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceViewerComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceViewerComponent_2)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceViewerComponent_3)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceViewerComponent_4)), i0.ɵdid(7, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.file.isPDF; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.file.isImage; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.file.isVideo; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.file.isUnknown; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_ResourceViewerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-resource-viewer", [], null, null, null, View_ResourceViewerComponent_0, RenderType_ResourceViewerComponent)), i0.ɵdid(1, 114688, null, 0, i4.ResourceViewerComponent, [i5.FileIdentificationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResourceViewerComponentNgFactory = i0.ɵccf("app-resource-viewer", i4.ResourceViewerComponent, View_ResourceViewerComponent_Host_0, { url: "url", mediaType: "mediaType", wheelZoom: "wheelZoom" }, {}, []);
export { ResourceViewerComponentNgFactory as ResourceViewerComponentNgFactory };
