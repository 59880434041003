/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./fraud-score-indicator.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./fraud-score-indicator.component";
var styles_FraudScoreIndicatorComponent = [i0.styles];
var RenderType_FraudScoreIndicatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FraudScoreIndicatorComponent, data: {} });
export { RenderType_FraudScoreIndicatorComponent as RenderType_FraudScoreIndicatorComponent };
function View_FraudScoreIndicatorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "alert alert-fraud"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Possible Fraud"])), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["The participant for this item has a fraud score that exceeds the threshold set for this promotion. Please review carefully and proceed with caution. "])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "small", [["class", "pull-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["title", "Current Fraud Score"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" / "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["title", "Fraud Threshold Setting"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fraudScore; _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.fraudThreshold; _ck(_v, 10, 0, currVal_1); }); }
export function View_FraudScoreIndicatorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FraudScoreIndicatorComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showIndicator; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FraudScoreIndicatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-fraud-score-indicator", [], null, null, null, View_FraudScoreIndicatorComponent_0, RenderType_FraudScoreIndicatorComponent)), i1.ɵdid(1, 114688, null, 0, i3.FraudScoreIndicatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FraudScoreIndicatorComponentNgFactory = i1.ɵccf("app-fraud-score-indicator", i3.FraudScoreIndicatorComponent, View_FraudScoreIndicatorComponent_Host_0, { fraudScore: "fraudScore", fraudThreshold: "fraudThreshold" }, {}, []);
export { FraudScoreIndicatorComponentNgFactory as FraudScoreIndicatorComponentNgFactory };
