import { Component, OnInit } from '@angular/core';
import { Constants } from '../../constants';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { SecurePageBase } from '../secure-page-base';
import { Router, ActivatedRoute } from '@angular/router';
import { ModerationApiService } from '../../apis/moderation-api.service';
import { TimerService } from '../../services/timer-service';
import { CurrentContentProviderService } from '../../services/current-content-provider.service';
import { UserGeneratedContentForModerationModel } from '../../models/user-generated-content-for-moderation.model';
import { NgForm } from '@angular/forms';
import { Subscription, Observable, interval } from 'rxjs';
import { SaveModeratedUserGeneratedResults } from '../../models/save-moderated-user-generated-results.model';
import { ModerationOutcomeModel } from '../../models/moderation-models';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-user-generated-moderate',
  templateUrl: './user-generated-moderate.component.html',
  styleUrls: ['./user-generated-moderate.component.css']
})
export class UserGeneratedModerateComponent extends SecurePageBase {
  constants: typeof Constants;
  accountKey: string;
  contentKey: string;
  programKey: string;
  content: UserGeneratedContentForModerationModel;
  timerName: string;
  private isAbortingModeration = false;
  abortSubscription: Subscription;
  parkReason: string;
  parkSubscription: Subscription;
  saveSubscription: Subscription;
  contentSubscription: Subscription;
  isSubmitting = false;
  isTimerWarning = false;
  isTimerComplete = false;
  secondsLeft: number;
  private timerWarningSeconds = Constants.moderationTimeoutWarningSeconds;
  private timerOverrideSeconds: number | null = null;
  validatationErrorMessage = '';
  savingErrorMessage = '';

  constructor(oidc: OidcSecurityService, router: Router, private moderationApi: ModerationApiService, private _route: ActivatedRoute, private _timer: TimerService, private _currentContent: CurrentContentProviderService) {
    super(oidc, router);
    this.constants = Constants;
    this.accountKey = this._route.snapshot.params['accountKey'];
    this.contentKey = this._route.snapshot.params['contentKey'];
    if (_route.snapshot.queryParams['timeout']) {
      this.timerOverrideSeconds = +_route.snapshot.queryParams['timeout'];
    }

    this._route.data.subscribe((data: { content: UserGeneratedContentForModerationModel }) => {
      const model: UserGeneratedContentForModerationModel = _currentContent.getCurrentForUserGenerated();

      this.programKey = model.content.programKey;
      this.content = model;
      this.timerName = model.content.contentKey;
      this.prepareTimer();
    });
  }

  onNgOnDestroy(): void {
    if (this.contentSubscription) {
      this.contentSubscription.unsubscribe();
    }
    if (this.abortSubscription) {
      this.abortSubscription.unsubscribe();
    }
    if (this.saveSubscription) {
      this.saveSubscription.unsubscribe();
    }
    if (this.parkSubscription) {
      this.parkSubscription.unsubscribe();
    }

    this._timer.delTimer(this.timerName);
  }

  //#region Timer
  private prepareTimer(): void {
    this._timer.delTimer(this.timerName);
    this._timer.newTimer(this.timerName, 1);
    this._timer.subscribe(this.timerName, () => this.timerTick());
  }
  private getTimeoutInSeconds(): number {
    return this.timerOverrideSeconds || this.content.content.timeoutInMinutes * 60;
  }
  private timerTick(): void {
    const assignedOn = new Date(this.content.content.assignedOn);
    const milliseconds = new Date().getTime() - assignedOn.getTime();
    const seconds = milliseconds / 1000;
    const timeoutInSeconds = this.getTimeoutInSeconds();
    this.secondsLeft = timeoutInSeconds - seconds;

    if (this.secondsLeft < 0) {
      this.isTimerWarning = false;
      this.isTimerComplete = true;
      this._timer.delTimer(this.timerName);
      interval(3000)
        .pipe(takeWhile(() => !this.isAbortingModeration))
        .subscribe(() => {
          this.abortModeration();
        });
    } else if (this.secondsLeft < this.timerWarningSeconds) {
      this.isTimerWarning = true;
    }
  }
  //#endregion

  onAuthorized(): void {}

  onShouldDisplayOwnUnauthorizedMessage(): boolean {
    return false;
  }

  abortModeration(): void {
    this.isAbortingModeration = true;
    this.isSubmitting = true;
    this._timer.delTimer(this.timerName);

    this.abortSubscription = this.moderationApi.abortModerationOfContent(this.accountKey, this.contentKey).subscribe(() => {
      this.router.navigate(['/program', this.programKey]);
    });
  }

  parkModeration(form: NgForm): void {
    if (form.valid) {
      this.isAbortingModeration = true;
      this.isSubmitting = true;
      this._timer.delTimer(this.timerName);

      this.parkSubscription = this.moderationApi.parkReceiptContent(this.accountKey, this.contentKey, this.parkReason).subscribe(() => {
        this.router.navigate(['/program', this.programKey]);
      });
    }
  }

  saveModeration(): void {
    if (!this.isValid()) {
      return;
    }

    this.confirmSaveModeration();
  }

  confirmSaveModeration() {
    this.isSubmitting = true;
    this._timer.delTimer(this.timerName);

    this.saveSubscription = this.moderationApi.saveModerationForUserGenerated(this.accountKey, this.contentKey, this.content).subscribe((result: SaveModeratedUserGeneratedResults) => {
      if (result.didSave) {
        this.next();
      } else {
        // This should never happen, but just in case...
        this.savingErrorMessage = 'An unknown error occurred saving this data. Please refresh and try again.';
      }
    });
  }

  cannotModerate(outcome: ModerationOutcomeModel): void {
    this.isSubmitting = true;
    this._timer.delTimer(this.timerName);

    this.saveSubscription = this.moderationApi.saveCannotModerate(this.accountKey, this.contentKey, outcome.value).subscribe(() => {
      this.next();
    });
  }

  private isValid(): boolean {
    if (this.content.rules.filter(rule => rule.booleanValue === undefined || rule.booleanValue === null).length > 0) {
      this.validatationErrorMessage = 'All questions need to be answered.';
      return false;
    }

    this.validatationErrorMessage = '';
    return true;
  }

  private next(): void {
    this.router.navigate(['/content', 'next', 'program', this.programKey]);
  }
}
