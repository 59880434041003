import * as tslib_1 from "tslib";
import { SecurePageBase } from '../secure-page-base';
import { ModerationApiService } from '../../apis/moderation-api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { RuleSetType } from '../../models/rule-set-type.enum';
var NextAvailableComponent = /** @class */ (function (_super) {
    tslib_1.__extends(NextAvailableComponent, _super);
    function NextAvailableComponent(oidc, router, moderationApi, _route) {
        var _this = _super.call(this, oidc, router) || this;
        _this.moderationApi = moderationApi;
        _this._route = _route;
        _this.noItemsAvailable = false;
        _this.programKey = _route.snapshot.params['programKey'];
        return _this;
    }
    NextAvailableComponent.prototype.onNgOnDestroy = function () {
        if (this.nextSubscription) {
            this.nextSubscription.unsubscribe();
        }
    };
    NextAvailableComponent.prototype.getNextAvailableContent = function () {
        var _this = this;
        this.nextSubscription = this.moderationApi.getNextAvailableContent(this.programKey).subscribe(function (data) {
            _this.sendTo(data);
        }, function (err) {
            if (err.status === 404) {
                _this.noItemsAvailable = true;
            }
        });
    };
    NextAvailableComponent.prototype.onAuthorized = function () {
        this.getNextAvailableContent();
    };
    NextAvailableComponent.prototype.onShouldDisplayOwnUnauthorizedMessage = function () {
        return false;
    };
    NextAvailableComponent.prototype.sendTo = function (next) {
        var typeRoute = next.contentModerationType === RuleSetType.UserGenerated ? 'user-generated' : 'receipt';
        this.router.navigate(['/content', typeRoute, next.accountKey, next.contentKey]);
    };
    return NextAvailableComponent;
}(SecurePageBase));
export { NextAvailableComponent };
