/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-generated-moderate-compare.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "../../widgets/resource-viewer/resource-viewer.component.ngfactory";
import * as i5 from "../../widgets/resource-viewer/resource-viewer.component";
import * as i6 from "../../services/file-identification.service";
import * as i7 from "./user-generated-moderate-compare.component";
import * as i8 from "angular-auth-oidc-client";
import * as i9 from "../../services/current-content-provider.service";
import * as i10 from "../../services/timer-service";
import * as i11 from "../../apis/moderation-api.service";
var styles_UserGeneratedModerateCompareComponent = [i0.styles];
var RenderType_UserGeneratedModerateCompareComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserGeneratedModerateCompareComponent, data: {} });
export { RenderType_UserGeneratedModerateCompareComponent as RenderType_UserGeneratedModerateCompareComponent };
function View_UserGeneratedModerateCompareComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "alert alert-danger"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Time is almost up."])), (_l()(), i1.ɵted(-1, null, [" You have approximately 1 minute left to finish this item. It will be placed back into the queue after this. "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", " seconds"])), i1.ɵppd(6, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), _co.secondsLeft, "1.0-0")); _ck(_v, 5, 0, currVal_0); }); }
function View_UserGeneratedModerateCompareComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "alert alert-danger"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Time is up."])), (_l()(), i1.ɵted(-1, null, [" This item is being placed back into the queue. "]))], null, null); }
export function View_UserGeneratedModerateCompareComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "container-full-width"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserGeneratedModerateCompareComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserGeneratedModerateCompareComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "alert"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "a", [["class", "btn btn-info"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(9, 5), (_l()(), i1.ɵted(-1, null, ["Close (Esc)"])), (_l()(), i1.ɵeld(11, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-resource-viewer", [], null, null, null, i4.View_ResourceViewerComponent_0, i4.RenderType_ResourceViewerComponent)), i1.ɵdid(14, 114688, null, 0, i5.ResourceViewerComponent, [i6.FileIdentificationService], { url: [0, "url"], mediaType: [1, "mediaType"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "app-resource-viewer", [], null, null, null, i4.View_ResourceViewerComponent_0, i4.RenderType_ResourceViewerComponent)), i1.ɵdid(17, 114688, null, 0, i5.ResourceViewerComponent, [i6.FileIdentificationService], { url: [0, "url"], mediaType: [1, "mediaType"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isTimerWarning; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.isTimerComplete; _ck(_v, 5, 0, currVal_1); var currVal_4 = _ck(_v, 9, 0, "/content", "user-generated", _co.content.content.accountKey, _co.content.content.contentKey, "review"); _ck(_v, 8, 0, currVal_4); var currVal_5 = _co.content.content.resourceUrl; var currVal_6 = _co.content.content.resourceMediaType; _ck(_v, 14, 0, currVal_5, currVal_6); var currVal_7 = _co.compare.resourceUrl; var currVal_8 = _co.compare.resourceMediaType; _ck(_v, 17, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 8).target; var currVal_3 = i1.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_2, currVal_3); }); }
export function View_UserGeneratedModerateCompareComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-generated-moderate-compare", [], null, [["window", "keyup"]], function (_v, en, $event) { var ad = true; if (("window:keyup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onKeyup($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_UserGeneratedModerateCompareComponent_0, RenderType_UserGeneratedModerateCompareComponent)), i1.ɵdid(1, 245760, null, 0, i7.UserGeneratedModerateCompareComponent, [i8.OidcSecurityService, i3.Router, i3.ActivatedRoute, i9.CurrentContentProviderService, i10.TimerService, i11.ModerationApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserGeneratedModerateCompareComponentNgFactory = i1.ɵccf("app-user-generated-moderate-compare", i7.UserGeneratedModerateCompareComponent, View_UserGeneratedModerateCompareComponent_Host_0, {}, {}, []);
export { UserGeneratedModerateCompareComponentNgFactory as UserGeneratedModerateCompareComponentNgFactory };
