import * as tslib_1 from "tslib";
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { catchError, retry } from 'rxjs/operators';
import { BaseApi } from './base-api';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Constants } from '../constants';
var ModerationApiService = /** @class */ (function (_super) {
    tslib_1.__extends(ModerationApiService, _super);
    function ModerationApiService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    ModerationApiService.prototype.getVersion = function () {
        return this.http
            .get(_super.prototype.urlFor.call(this, '/.well-check/version'))
            .pipe(retry(environment.retryAttempts), catchError(this.handleError));
    };
    ModerationApiService.prototype.getDashboard = function (search) {
        var showNonLivePrograms = search.showNonLivePrograms == null ? '' : search.showNonLivePrograms.toString();
        return this.http
            .get(_super.prototype.urlFor.call(this, "/moderation/dashboard?keyword=" + encodeURI(search.keyword) + "&showNonLivePrograms=" + encodeURI(showNonLivePrograms)))
            .pipe(retry(environment.retryAttempts), catchError(this.handleError));
    };
    ModerationApiService.prototype.getProgramDashboard = function (programKey) {
        return this.http
            .get(_super.prototype.urlFor.call(this, "/moderation/dashboard/" + programKey))
            .pipe(retry(environment.retryAttempts), catchError(this.handleError));
    };
    ModerationApiService.prototype.getProgramAnalyticsReportDefinitions = function (programKey) {
        return this.http
            .get(_super.prototype.urlFor.call(this, "/analytics/analytics/reports/" + programKey))
            .pipe(retry(environment.retryAttempts), catchError(this.handleError));
    };
    ModerationApiService.prototype.getProgramAnalyticsExtract = function (programKey, extract) {
        return this.http
            .get(_super.prototype.urlFor.call(this, "/analytics/analytics/reports/" + programKey + "/extract?extract=" + JSON.stringify(extract)), { responseType: 'arraybuffer' })
            .pipe(retry(environment.retryAttempts), catchError(this.handleError));
    };
    ModerationApiService.prototype.getReportMain = function (extract) {
        return this.http
            .get(_super.prototype.urlFor.call(this, "/analytics/analytics/reports/main?extract=" + JSON.stringify(extract)), { responseType: 'arraybuffer' })
            .pipe(retry(environment.retryAttempts), catchError(this.handleError));
    };
    ModerationApiService.prototype.getAvailableContent = function (programKey) {
        return this.http
            .get(_super.prototype.urlFor.call(this, "/moderation/content/available/" + programKey + "?maxRecords=" + Constants.homepageAvailableMaxRecords))
            .pipe(retry(environment.retryAttempts), catchError(this.handleError));
    };
    ModerationApiService.prototype.getParkedContent = function (programKey) {
        return this.http
            .get(_super.prototype.urlFor.call(this, "/moderation/content/parked/" + programKey))
            .pipe(retry(environment.retryAttempts), catchError(this.handleError));
    };
    ModerationApiService.prototype.searchContent = function (keyword) {
        return this.http
            .post(_super.prototype.urlFor.call(this, '/moderation/content/search'), { keyword: keyword })
            .pipe(retry(environment.retryAttempts), catchError(this.handleError));
    };
    ModerationApiService.prototype.getNextAvailableContent = function (programKey) {
        // Don't retry POSTs.
        // Don't catchError() as the caller handles it explicitly.
        return this.http.post(_super.prototype.urlFor.call(this, '/moderation/content/next'), { programKey: programKey });
    };
    ModerationApiService.prototype.getMyStartedContent = function (programKey) {
        return this.http
            .get(_super.prototype.urlFor.call(this, "/moderation/content/available/my/" + programKey))
            .pipe(retry(environment.retryAttempts), catchError(this.handleError));
    };
    ModerationApiService.prototype.assignAndGetReceiptContentForModeration = function (accountKey, contentKey) {
        // Don't retry POSTs
        // Don't catchError() as the caller handles it explicitly.
        return this.http
            .post(_super.prototype.urlFor.call(this, "/moderation/content/receipt/" + accountKey + "/" + contentKey + "/assign"), {});
    };
    ModerationApiService.prototype.assignAndGetUserGeneratedContentForModeration = function (accountKey, contentKey) {
        // Don't retry POSTs
        // Don't catchError() as the caller handles it explicitly.
        return this.http
            .post(_super.prototype.urlFor.call(this, "/moderation/content/user-generated/" + accountKey + "/" + contentKey + "/assign"), {});
    };
    ModerationApiService.prototype.parkReceiptContent = function (accountKey, contentKey, reason) {
        // Don't retry POSTs
        return this.http
            .post(_super.prototype.urlFor.call(this, "/moderation/content/receipt/" + accountKey + "/" + contentKey + "/park"), { reason: reason })
            .pipe(catchError(this.handleError));
    };
    ModerationApiService.prototype.unparkAndGetReceiptContentForModeration = function (accountKey, contentKey) {
        // Don't retry POSTs
        return this.http
            .post(_super.prototype.urlFor.call(this, "/moderation/content/receipt/" + accountKey + "/" + contentKey + "/unpark"), {})
            .pipe(catchError(this.handleError));
    };
    ModerationApiService.prototype.unparkAndGetUserGeneratedContentForModeration = function (accountKey, contentKey) {
        // Don't retry POSTs
        return this.http
            .post(_super.prototype.urlFor.call(this, "/moderation/content/user-generated/" + accountKey + "/" + contentKey + "/unpark"), {})
            .pipe(catchError(this.handleError));
    };
    ModerationApiService.prototype.getReceiptContentForExamine = function (accountKey, contentKey) {
        return this.http
            .get(_super.prototype.urlFor.call(this, "/moderation/content/receipt/" + accountKey + "/" + contentKey + "/review"))
            .pipe(retry(environment.retryAttempts), catchError(this.handleError));
    };
    ModerationApiService.prototype.getUserGeneratedContentForExamine = function (accountKey, contentKey) {
        return this.http
            .get(_super.prototype.urlFor.call(this, "/moderation/content/user-generated/" + accountKey + "/" + contentKey + "/review"))
            .pipe(retry(environment.retryAttempts), catchError(this.handleError));
    };
    ModerationApiService.prototype.abortModerationOfContent = function (accountKey, contentKey) {
        // Don't retry DELETEs
        return this.http.delete(_super.prototype.urlFor.call(this, "/moderation/content/" + accountKey + "/" + contentKey + "/assign")).pipe(catchError(this.handleError));
    };
    ModerationApiService.prototype.saveModerationForReceipt = function (accountKey, contentKey, data, bypassNewSecondPassCollisions) {
        // Don't retry POSTs
        return this.http
            .post(_super.prototype.urlFor.call(this, "/moderation/content/receipt/" + accountKey + "/" + contentKey + "/save?bypassNewSecondPassCollisions=" + bypassNewSecondPassCollisions), data)
            .pipe(catchError(this.handleError));
    };
    ModerationApiService.prototype.saveModerationForUserGenerated = function (accountKey, contentKey, data) {
        // Don't retry POSTs
        return this.http
            .post(_super.prototype.urlFor.call(this, "/moderation/content/user-generated/" + accountKey + "/" + contentKey + "/save"), data)
            .pipe(catchError(this.handleError));
    };
    ModerationApiService.prototype.saveCannotModerate = function (accountKey, contentKey, reason) {
        // Don't retry POSTs
        return this.http
            .post(_super.prototype.urlFor.call(this, "/moderation/content/" + accountKey + "/" + contentKey + "/cannot-moderate"), {
            reason: reason
        })
            .pipe(catchError(this.handleError));
    };
    /**
     * Handles errors for http calls. Should not handle 401 responses,
     *  as those are handled by the {@link AuthInterceptor}.
     *
     * @param {any} err the error that was caused.
     * @param {Observable} caught the source observable that was caught.
     * @return {ErrorObservable} An observable that simply contains the error message.
     * @name handleError
     */
    ModerationApiService.prototype.handleError = function (err, caught) {
        var message = 'An error has occurred. Please try again.';
        if (err.error instanceof ErrorEvent) {
            // client-side
            console.error('An error occurred:', err.error.message);
        }
        else {
            // backend response
            console.error("Backend return code " + err.status + ", body was: " + err.error);
            // We don't catch 401 here as that is handled in auth-interceptor.service.ts
            switch (err.status) {
                // TODO: Add more HTTP response code messages here.
                case 500:
                    message = 'A problem occurred on the server. Advise a developer to investigate.';
                    break;
                case 404:
                case 0:
                default:
                    message = 'Unable to connect to server. Please try again.';
                    break;
            }
        }
        return ErrorObservable.create(message);
    };
    return ModerationApiService;
}(BaseApi));
export { ModerationApiService };
