import { EventEmitter, Output, Injectable } from '@angular/core';

@Injectable()
export class UnauthorizedWatcherService {

    @Output()
    unauthorizedRecieved: EventEmitter<any> = new EventEmitter<any>();

    constructor(
    ) {
    }

    emitUnauthorizedEvent() {
      this.unauthorizedRecieved.emit(null);
    }
}
