import { RuleSetType } from './rule-set-type.enum';
import { ContentForExamineModel } from './content-for-examine.model';
import { ContentForExamineRuleSetModel } from './content-for-examine-rule-set.model';
import { Moment } from 'moment';
import { FingerprintCollisionGroupModel } from './fingerprint-collisions-group.model';

export class VersionModel {
  public version: string;
}
export class ModerationOutcomeModel {
  value: string;
  text: string;
}

export class RejectionReasonModel {
  value: string;
  text: string;
}

export class NextContentForModerationModel {
  public accountKey: string;
  public contentKey: string;
  public contentModerationType: RuleSetType;
}

//#region ReceiptContentForModeration
export class ReceiptContentForModerationQuickActionModel {
  public name: string;
  public caption: string;
  public storeKey: string;
  public total: number | null;
  public storeNumber: string;
  public purchasedOn: Date | string | null;
  public products: Array<ReceiptContentForModerationQuickActionProductModel>;

  // View Model
  public performed: boolean;
}

export class ReceiptContentForModerationQuickActionProductModel {
  public productKey: string;
  public quantity: number | null;
  public total: number | null;
}

export class ReceiptContentForModerationSettingsModel {
  public showQuantity: boolean;
  public showTotal: boolean;
  public requireQuantity: boolean;
  public requireTotal: boolean;
  public requirePurchasedOn: boolean;
  public requireStore: boolean;
  public receiptTotalWarningThreshold: number;
  public isStoreNumberCaptured: boolean;
  public isStoreNumberRequired: boolean;
  public quickActions: Array<ReceiptContentForModerationQuickActionModel>;
  public fraudThreshold: number;
}
export class ReceiptContentForModerationContentModel {
  public accountKey: string;
  public contentKey: string;
  public programKey: string;
  public resourceUrl: string;
  public resourceMediaType: string;
  public resourceFraudScore: number | null;
  public createdOn: Date;
  public assignedOn: Date;
  public timeoutInMinutes: number;
  public participantKey: string;
  public parentContentKey: string;
}

export class ReceiptContentForModerationReceiptItemModel {
  public text: string;
  public quantity: number;
  public total: number;
  public productKey: string;
  public productName: string;
  public index: number | null;

  // View Model
  public isConsumer = false;
  public isManuallyAdded = false;
}

export class ReceiptContentForModerationFingerprintModel {
  public kind: string;
  public value: string;
}

export class ReceiptContentForModerationReceiptModel {
  public storeKey: string;
  public storeText: string;
  public storeName: string;
  public purchasedOnText: string;
  public purchasedOn: Date | string | Moment;
  public isDuplicate: boolean;
  public fingerprintCollisionGroups: Array<FingerprintCollisionGroupModel>;
  public totalText: string;
  public total: number;
  public storeNumber: string;
}

export class ReceiptContentForModerationReviewItemModel {
  public accountKey: string;
  public contentKey: string;
  public resourceUrl: string;
  public resourceMediaType: string;
  public createdOn: Date;
  public participantKey: string;
  public moderationOutcome: string;
  public moderatedOn: Date;
  public purchasedOn: Date;
  public stateName: string;
  public fingerprints: Array<ReceiptContentForModerationFingerprintModel>;

  // These are viewModel properties. Do not want to make separate models at this time
  public fingerprintIndicatorText: string;
  public fingerprintIndicatorTooltip: string;
  public fingerprintIndicatorIsConfident: boolean;
  public fingerprintIndicatorIsFuzzy: boolean;
  public outcomeIndicatorText: string;
  public outcomeIndicatorBadgeClass: string;
  public outcomeIndicatorTooltip: string;
  public outcomeIndicatorClasses: any;
}
export class ReceiptContentForModerationReviewModel {
  public participantItems: Array<ReceiptContentForModerationReviewItemModel>;
}

export class ReceiptContentForModerationConsumerModel {
  public createdOn: Date;
  public purchasedOn: Date;
  public storeKey: string;
  public storeName: string;
  public receiptItems: Array<ReceiptContentForModerationConsumerReceiptItemModel>;
}

export class ReceiptContentForModerationConsumerReceiptItemModel {
  public productKey: string;
  public productName: string;
  public quantity: number;
  public total: number;
}

export class ReceiptContentForModerationModel {
  public settings: ReceiptContentForModerationSettingsModel;
  public content: ReceiptContentForModerationContentModel;
  public receipt: ReceiptContentForModerationReceiptModel;
  public receiptItems: Array<ReceiptContentForModerationReceiptItemModel>;
  public products: Array<ProductSelectionModel>;
  public stores: Array<StoreSelectionModel>;
  public review: ReceiptContentForModerationReviewModel;
  public rejectionReasons: Array<RejectionReasonModel>;
  public consumer: ReceiptContentForModerationConsumerModel;
}

//#endregion

//#region ReceiptContentForReview

export class ReceiptContentForExamineReceiptItemModel {
  public text: string;
  public quantity: number;
  public total: number;
  public productKey: string;
  public productName: string;
  public index: number | null;
}

export class ReceiptContentForExamineReceiptModel {
  public storeKey: string;
  public storeText: string;
  public storeName: string;
  public storeNumber: string;
  public purchasedOnText: string;
  public purchasedOn: Date;
  public totalText: string;
  public total: number;
  public isDuplicate: boolean;
  public fingerprintCollisionGroups: Array<FingerprintCollisionGroupModel>;
}

export class ReceiptContentForExamineModel {
  public content: ContentForExamineModel;
  public receipt: ReceiptContentForExamineReceiptModel;
  public receiptItems: Array<ReceiptContentForExamineReceiptItemModel>;
  public ruleSets: Array<ContentForExamineRuleSetModel>;
  public consumer: ReceiptContentForModerationConsumerModel;
}

//#endregion

export class ProductSelectionModel {
  public name: string;
  public productKey: string;
}

export class StoreSelectionModel {
  public name: string;
  public storeKey: string;
}
