import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ModerationApiService } from '../../apis/moderation-api.service';
import { DashboardModel, DashboardSearchModel } from '../../models/dashboard-model';
import { SecurePageBase } from '../secure-page-base';
import { CurrentContentProviderService } from '../../services/current-content-provider.service';
import { Constants } from '../../constants';
var HomeComponent = /** @class */ (function (_super) {
    tslib_1.__extends(HomeComponent, _super);
    function HomeComponent(oidc, router, moderationApi, currentContent) {
        var _this = _super.call(this, oidc, router) || this;
        _this.moderationApi = moderationApi;
        _this.showActionButtons = true;
        _this.currentList = 'available';
        _this.constants = Constants;
        _this.searchPayload = new DashboardSearchModel();
        _this.searchPayload.keyword = '';
        _this.searchPayload.showNonLivePrograms = null;
        currentContent.clear();
        return _this;
    }
    HomeComponent.prototype.populateDashboard = function () {
        var _this = this;
        this.model = null;
        this.showActionButtons = false;
        this.dashboardSubscription = this.moderationApi.getDashboard(this.searchPayload).subscribe(function (dashboardModel) {
            _this.model = dashboardModel;
            _this.showActionButtons = true;
        }, function (error) {
            _this.error = error;
        });
    };
    HomeComponent.prototype.onNgOnDestroy = function () {
        if (this.dashboardSubscription) {
            this.dashboardSubscription.unsubscribe();
        }
    };
    HomeComponent.prototype.onAuthorized = function () {
        this.populateDashboard();
    };
    HomeComponent.prototype.onShouldDisplayOwnUnauthorizedMessage = function () {
        return true;
    };
    HomeComponent.prototype.refreshGrid = function () {
        this.populateDashboard();
    };
    return HomeComponent;
}(SecurePageBase));
export { HomeComponent };
