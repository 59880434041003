<div *ngIf="isAuthorized" class="container dashboard">
  <div *ngIf="isItemRequesting">
    <app-loading-spinner></app-loading-spinner>
  </div>
  <div *ngIf="!isItemRequesting">
    <app-loading-spinner *ngIf="!dashboardData && !error"></app-loading-spinner>
    <div *ngIf="dashboardData || error">
      <div *ngIf="error">
        <h1 class="error">Error!</h1>
        <p class="lead">{{ error }}</p>
      </div>
      <h1 *ngIf="dashboardData">{{ dashboardData.programName }}</h1>
      <ng-container *ngIf="analyticsReports">
        <ng-container *ngIf="analyticsReports.reports.length === 0">
          <div class="alert alert-danger">There is no analytics data available for this program.</div>
        </ng-container>
        <ng-container *ngIf="analyticsReports.reports.length > 0">
          <form>
            <div class="row">
              <div class="col-8">
                <div class="form-group">
                  <label>Available Reports</label>
                  <ng-multiselect-dropdown name="reportsFilter" [placeholder]="'Select Reports, or leave blank for All Reports'" [data]="analyticsReports.reports" [(ngModel)]="extractRequest.reports" [settings]="dropdownSettings">
                  </ng-multiselect-dropdown>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label>Start Date</label>
                <div class="input-group">
                  <input [owlDateTime]="startOn" #startOnInput="ngModel" required [(ngModel)]="extractRequest.startOn" type="text" class="form-control" name="startOnInput" />
                  <owl-date-time #startOn pickerMode="dialog" pickerType="calendar" [hour12Timer]="true"></owl-date-time>
                  <div class="input-group-append" [owlDateTimeTrigger]="startOn">
                    <span class="input-group-text"> <span class="fa fa-calendar cursor-pointer"></span> </span>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <label>End Date</label>
                <div class="input-group">
                  <input [owlDateTime]="endOn" #endOnInput="ngModel" required [(ngModel)]="extractRequest.endOn" type="text" class="form-control" name="endOnInput" />
                  <owl-date-time #endOn pickerMode="dialog" pickerType="calendar" [hour12Timer]="true"></owl-date-time>
                  <div class="input-group-append" [owlDateTimeTrigger]="endOn">
                    <span class="input-group-text"> <span class="fa fa-calendar cursor-pointer"></span> </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-8">
                <button class="btn btn-outline-info" (click)="performExtract()" [disabled]="isExtracting">
                  <span class="fa fa-download" *ngIf="!isExtracting"></span><span *ngIf="isExtracting" class="fa fa-spin fa-spinner"></span> Extract
                </button>
              </div>
            </div>
          </form>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
