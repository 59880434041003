import { Component, OnInit } from '@angular/core';
import { SecurePageBase } from '../secure-page-base';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router, ActivatedRoute } from '@angular/router';
import { ModerationApiService } from '../../apis/moderation-api.service';
import { ReceiptContentForExamineModel } from '../../models/moderation-models';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { ContentForExamineRuleSetModel } from '../../models/content-for-examine-rule-set.model';
import { Constants } from '../../constants';
import { CurrentContentProviderService } from '../../services/current-content-provider.service';

declare var jQuery: any;

@Component({
  selector: 'app-receipt-examine-detail',
  templateUrl: './receipt-examine-detail.component.html',
  styleUrls: ['./receipt-examine-detail.component.css']
})
export class ReceiptExamineDetailComponent extends SecurePageBase {
  constants: typeof Constants;
  contentKey: string;
  accountKey: string;
  content: ReceiptContentForExamineModel;
  showPassingRuleSets = true;
  showFailingRuleSets = true;
  error: ErrorObservable<any>;

  constructor(oidc: OidcSecurityService, router: Router, private moderationApi: ModerationApiService, private _route: ActivatedRoute, currentContent: CurrentContentProviderService) {
    super(oidc, router);
    this.constants = Constants;
    this.accountKey = this._route.snapshot.params['accountKey'];
    this.contentKey = this._route.snapshot.params['contentKey'];

    this._route.data.subscribe((data: { content: ReceiptContentForExamineModel }) => {
      this.content = currentContent.getCurrentExamineForReceipt();
    });
  }

  onAuthorized(): void {
    // this.populateContent();
  }

  onNgOnDestroy(): void {
  }

  onShouldDisplayOwnUnauthorizedMessage(): boolean {
    return false;
  }

  toggleRules(ruleSetItem: ContentForExamineRuleSetModel): void {
    ruleSetItem.showRules = !ruleSetItem.showRules;
  }

}
