var Constants = /** @class */ (function () {
    function Constants() {
    }
    Constants.homepageAvailableMaxRecords = 20;
    Constants.moderationTimeoutWarningSeconds = 60;
    Constants.threeFactorCategory = 'ThreeFactor';
    Constants.twoFactorCategory = 'TwoFactor';
    Constants.moderationOutcomeSuccessful = 'Successful';
    Constants.moderationOutcomeDuplicateImage = 'DuplicateImage';
    Constants.moderationOutcomeDuplicateImageHuman = 'DuplicateImageHuman';
    Constants.fingerprintFirstPassDateStore = 'FirstPassDateStore';
    Constants.fingerprintFirstPassDateStoreFuzzy = 'FirstPassDateStoreFuzzy';
    Constants.fingerprintFirstPassDateStoreTotal = 'FirstPassDateStoreTotal';
    Constants.fingerprintFirstPassDateStoreTotalFuzzy = 'FirstPassDateStoreTotalFuzzy';
    Constants.KEY_CODE = {
        ESCAPE: 27
    };
    return Constants;
}());
export { Constants };
