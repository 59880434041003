/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./forbidden.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./forbidden.component";
var styles_ForbiddenComponent = [i0.styles];
var RenderType_ForbiddenComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ForbiddenComponent, data: {} });
export { RenderType_ForbiddenComponent as RenderType_ForbiddenComponent };
export function View_ForbiddenComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "center-stage"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please Log On"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "lead"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please log on using the menu above to get the fun started."]))], null, null); }
export function View_ForbiddenComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-forbidden", [], null, null, null, View_ForbiddenComponent_0, RenderType_ForbiddenComponent)), i1.ɵdid(1, 114688, null, 0, i2.ForbiddenComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForbiddenComponentNgFactory = i1.ɵccf("app-forbidden", i2.ForbiddenComponent, View_ForbiddenComponent_Host_0, {}, {}, []);
export { ForbiddenComponentNgFactory as ForbiddenComponentNgFactory };
