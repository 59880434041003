import { Component, OnInit } from '@angular/core';
import { Constants } from '../../constants';
import { SecurePageBase } from '../secure-page-base';
import { ModerationApiService } from '../../apis/moderation-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { Subscription } from 'rxjs';
import { SearchResultsModel, SearchResultsItemModel } from '../../models/dashboard-model';
import { RuleSetType } from '../../models/rule-set-type.enum';

@Component({
  selector: 'app-content-search',
  templateUrl: './content-search.component.html',
  styleUrls: ['./content-search.component.css']
})
export class ContentSearchComponent extends SecurePageBase {
  constants: typeof Constants;
  ruleSetTypes: typeof RuleSetType;
  public searchCritieria: string;
  public searchCritieriaAsSearched: string;
  public isSearching: boolean;
  results: SearchResultsModel;
  resultsSubscription: Subscription;
  error: ErrorObservable<any>;
  isItemRequesting: Boolean = false;

  constructor(oidc: OidcSecurityService, router: Router, private moderationApi: ModerationApiService, private _route: ActivatedRoute) {
    super(oidc, router);
    this.searchCritieria = this._route.snapshot.params['criteria'];
    this.constants = Constants;
    this.ruleSetTypes = RuleSetType;
    _route.params.subscribe(val => this.performSearch());
  }

  onAuthorized(): void {
    if (this.searchCritieria) {
      this.performSearch();
    }
  }

  private performSearch(): void {
    if (!this.searchCritieria) {
      return;
    }

    this.searchCritieriaAsSearched = this.searchCritieria;
    this.isSearching = true;
    this.results = null;

    this.resultsSubscription = this.moderationApi.searchContent(this.searchCritieria).subscribe(
      (model: SearchResultsModel) => {

        model.items.forEach(item => {
          if (item.moderationOutcome === Constants.moderationOutcomeSuccessful) {
            item.moderationOutcomeSuccessful = true;
            item.moderationOutcomeText = item.moderationOutcome;

          } else if (item.moderationOutcome) {
            item.moderationOutcomeRejected = true;
            item.moderationOutcomeText = item.moderationOutcome;
          } else {
            item.moderationOutcomeText = '';
            item.moderationOutcomeSuccessful = false;
            item.moderationOutcomeRejected = false;
          }
        });

        this.results = model;
        this.isSearching = false;

        // We no long need this subscription. If the criteria changes, we resubscribe anyway
        this.resultsSubscription.unsubscribe();
      },
      (error: ErrorObservable<any>) => {
        this.error = error;
      }
    );
  }
  onNgOnDestroy(): void {
  }

  onShouldDisplayOwnUnauthorizedMessage(): boolean {
    return false;
  }

  public search(): void {
    this.router.navigate(['/content', 'search', this.searchCritieria]);
  }

  requestItem(item: SearchResultsItemModel): void {
    this.isItemRequesting = true;
    const typeRoute = item.contentModerationType === RuleSetType.UserGenerated ? 'user-generated' : 'receipt';
    this.router.navigate(['/content', typeRoute, item.accountKey, item.contentKey]);
  }
}
