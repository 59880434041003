var AppComponent = /** @class */ (function () {
    function AppComponent() {
        this.title = 'app';
    }
    AppComponent.prototype.onActivate = function (event) {
        window.scroll(0, 0);
    };
    return AppComponent;
}());
export { AppComponent };
