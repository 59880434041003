import { Component, OnInit, OnDestroy } from '@angular/core';
import { OidcSecurityService, AuthorizationResult } from 'angular-auth-oidc-client';
import { UnauthorizedWatcherService } from '../../services/unauthorized-watcher.service';
import { ApplicationVersion } from '../../../version';
import { VersionModel } from '../../models/moderation-models';
import { ModerationApiService } from '../../apis/moderation-api.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-nav-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.css']
})
export class TopnavComponent implements OnInit, OnDestroy {
  isAuthorized: boolean;
  userData: any;
  public version: string;
  public apiVersion = '...'; // retrieved later
  public environmentIndicator = 'prd';

  constructor(
    private oidc: OidcSecurityService,
    private moderationApi: ModerationApiService,
    private unauthorizedWatcherService: UnauthorizedWatcherService
  ) {
    if (this.oidc.moduleSetup) {
      this.doCallbackLogicIfRequired();
    } else {
      this.oidc.onModuleSetup.subscribe(() => {
        this.doCallbackLogicIfRequired();
      });
    }
    this.version = ApplicationVersion.version;

    unauthorizedWatcherService.unauthorizedRecieved.subscribe(() => {
      this.isAuthorized = false;
    });

    oidc.onAuthorizationResult.subscribe((result: AuthorizationResult) => {
      this.isAuthorized = result === AuthorizationResult.authorized;
      history.replaceState('', document.title, `${window.location.pathname}${window.location.search}`);
    });
    this.environmentIndicator = this.getEnvironmentIndicator();
  }

  private getEnvironmentIndicator(): string {
    // HACK: Using an existing piece of data that is already in the environment files so they do not have to be updated when upgrading to this version
    const url = environment.oidc.redirectUrl;

    if (url.match(/localhost/i)) {
      return 'loc';
    } else if (url.match(/\.dev\./i)) {
      return 'dev';
    } else if (url.match(/\.tst\./i)) {
      return 'tst';
    } else if (url.match(/\.staging\./i)) {
      return 'stg';
    }

    return 'prd';
  }

  ngOnInit() {
    this.oidc.getIsAuthorized().subscribe(
      (isAuthorized: boolean) => {
        this.isAuthorized = isAuthorized;
      });

    this.oidc.getUserData().subscribe(
      (userData: any) => {
        this.userData = userData;
      }
    );

    this.moderationApi.getVersion().subscribe((data: VersionModel) => {
      this.apiVersion = data.version;
    });

}

  ngOnDestroy(): void {
    this.oidc.onModuleSetup.unsubscribe();
  }

  logon() {
    this.oidc.authorize();
  }

  logoff() {
    this.oidc.logoff();
  }

  private doCallbackLogicIfRequired() {
    if (window.location.hash) {
      this.oidc.authorizedCallback();
    }
  }
}
