import { Component, OnInit } from '@angular/core';
import { SecurePageBase } from '../secure-page-base';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';
import { DashboardModel, DashboardSearchModel, AnalyticsExtractModel } from '../../models/dashboard-model';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { Subscription } from 'rxjs';
import { ModerationApiService } from '../../apis/moderation-api.service';
import { saveAs } from 'file-saver';
import { toDate } from '../../../i18n/date_util';
import * as moment from 'moment';
import { unitOfTime } from 'moment';

@Component({
  selector: 'app-reports-home',
  templateUrl: './reports-home.component.html',
  styleUrls: ['./reports-home.component.css'],
})
export class ReportsHomeComponent extends SecurePageBase {
  dashboard: DashboardModel;
  error: ErrorObservable<any>;
  dashboardSubscription: Subscription;
  isSubmitting = false;
  searchPayload: DashboardSearchModel;
  extractRequest: AnalyticsExtractModel;
  isDownloading = false;

  constructor(oidc: OidcSecurityService, router: Router, private moderationApi: ModerationApiService) {
    super(oidc, router);

    this.searchPayload = new DashboardSearchModel();
    this.searchPayload.keyword = '';
    this.searchPayload.showNonLivePrograms = null;
    this.extractRequest = new AnalyticsExtractModel();
    this.extractRequest.programKey = '';
  }

  onNgOnDestroy(): void {
    if (this.dashboardSubscription) {
      this.dashboardSubscription.unsubscribe();
    }
  }

  onAuthorized(): void {
    this.loadPrograms();
  }

  onShouldDisplayOwnUnauthorizedMessage(): boolean {
    return false;
  }

  private loadPrograms() {
    this.isSubmitting = true;
    this.dashboardSubscription = this.moderationApi.getDashboard(this.searchPayload).subscribe(
      (dashboardModel: DashboardModel) => {
        this.dashboard = dashboardModel;
        this.isSubmitting = false;
      },
      (error: ErrorObservable<any>) => {
        this.error = error;
      }
    );
  }

  performDownload(): void {
    this.isDownloading = true;
    this.moderationApi.getReportMain(this.extractRequest).subscribe(data => {
      const blob = new Blob([data], { type: 'text/csv' });
      saveAs(blob, 'ModerationReport.xlsx');
      this.isDownloading = false;
    });
  }

  setEndOnToEndOfDay() {
    this.extractRequest.endOn = moment(this.extractRequest.endOn)
      .endOf('day')
      .toDate()
      .toISOString();
  }

  setDateRange(unit: unitOfTime.DurationConstructor, amount: number | string) {
    const first = moment()
      .utc()
      .startOf(unit)
      .add(amount, unit)
      .toDate();
    const last = moment()
      .utc()
      .endOf(unit)
      .add(amount, unit)
      .toDate();

    this.extractRequest.startOn = first.toISOString();
    this.extractRequest.endOn = last.toISOString();
  }
}
