var SecurePageBase = /** @class */ (function () {
    function SecurePageBase(oidc, router) {
        this.oidc = oidc;
        this.router = router;
    }
    SecurePageBase.prototype.ngOnInit = function () {
        var _this = this;
        this.authorizedSubscription = this.oidc
            .getIsAuthorized()
            .subscribe(function (isAuthorized) {
            _this.isAuthorized = isAuthorized;
            if (_this.isAuthorized) {
                _this.userDataSubscription = _this.oidc
                    .getUserData()
                    .subscribe(function (data) {
                    _this.displayName = data.name;
                    _this.profileKey = data.sub;
                    _this.onAuthorized();
                });
            }
            else {
                if (_this.onShouldDisplayOwnUnauthorizedMessage()) {
                    // do nothing, the page will display its own message
                }
                else {
                    _this.router.navigateByUrl('/');
                }
            }
        });
    };
    SecurePageBase.prototype.ngOnDestroy = function () {
        if (this.authorizedSubscription) {
            this.authorizedSubscription.unsubscribe();
        }
        if (this.userDataSubscription) {
            this.userDataSubscription.unsubscribe();
        }
        this.onNgOnDestroy();
    };
    return SecurePageBase;
}());
export { SecurePageBase };
