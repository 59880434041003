<div class="container-full-width">
  <h2>
    UGC Moderation<small class="ml-4"> <a *ngIf="content.review" [routerLink]="['/content', 'user-generated', content.content.accountKey, content.content.contentKey, 'review']">Participant Submission Review</a></small>
  </h2>
  <div *ngIf="content">
    <div *ngIf="isTimerWarning" class="alert alert-danger">
      <strong>Time is almost up.</strong> You have approximately 1 minute left to finish this item. It will be placed back into the queue after this. <span>{{ secondsLeft | number: '1.0-0' }} seconds</span>
    </div>
    <div *ngIf="isTimerComplete" class="alert alert-danger"><strong>Time is up.</strong> This item is being placed back into the queue.</div>
    <app-resubmit-indicator type="user-generated" [accountKey]="content.content.accountKey" [contentKey]="content.content.parentContentKey" ></app-resubmit-indicator>
    <div *ngIf="!isTimerComplete" class="row">
      <div class="col-4" id="imageAndFingerprintWarnings">
        <div style="width:440px;height:600px;"><app-resource-viewer [url]="content.content.resourceUrl" [mediaType]="content.content.resourceMediaType"></app-resource-viewer></div>
        <h5>Upload Date: {{ content.content.createdOn | date: 'medium' }}</h5>
        <app-digital-duplicate-indicator [isDuplicate]="content.content.isDuplicate" [parentContentKey]="content.content.parentContentKey"></app-digital-duplicate-indicator>
      </div>
      <div class="col-7">
        <div class="row rule mb-3" *ngFor="let rule of content.rules; let index = index">
          <div class="col-6 rule-text">
            <span class="rule">{{ rule.text }}</span>
          </div>
          <div class="col-5">
            <div class="btn-group btn-group-super btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="rule.booleanValue">
              <label ngbButtonLabel class="xbtn-light"> <input ngbButton type="radio" [value]="undefined" /></label>
              <label ngbButtonLabel [class.btn-light]="rule.booleanValue !== true" [class.btn-info]="rule.booleanValue === true"> <input ngbButton type="radio" [value]="true" /> Yes </label>
              <label ngbButtonLabel [class.btn-light]="rule.booleanValue !== false" [class.btn-info]="rule.booleanValue === false"> <input ngbButton type="radio" [value]="false" /> No </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isSubmitting" class="row justify-content-md-center">
      <div class="col-4">
        <div class="card-group">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Saving...</h4>
              <div *ngIf="savingErrorMessage" class="alert alert-danger">
                <strong>{{ savingErrorMessage }}</strong>
              </div>
              <app-loading-spinner></app-loading-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!isSubmitting && !isTimerComplete">
      <div class="col-12">
        <div class="card-group">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Cancel</h4>
              <button class="btn btn-warning mt-3" type="button" (click)="abortModeration()">Nevermind (unassign this from me)</button>
              <fieldset>
                <legend>Parking Lot</legend>
                <form #parkForm="ngForm" (ngSubmit)="parkModeration(parkForm)">
                  <input type="text" class="form-control" maxlength="1000" name="parkReason" [(ngModel)]="parkReason" required placeholder="Reason for parking this item" />
                  <button type="submit" class="btn btn-warning mt-3" [disabled]="parkForm.invalid">Park Item</button>
                </form>
              </fieldset>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Rejection Criteria</h4>
              <button *ngFor="let outcome of content.rejectionReasons" class="btn btn-danger mr-3 mt-3" (click)="cannotModerate(outcome)">{{ outcome.text }}</button>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Valid Content</h4>
              <div *ngIf="validatationErrorMessage" class="alert alert-danger">
                <strong>{{ validatationErrorMessage }}</strong>
              </div>
              <button class="btn btn-success mt-3" type="button" (click)="saveModeration()">Next <i class="fa fa-arrow-right"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
