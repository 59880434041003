import * as tslib_1 from "tslib";
import { SecurePageBase } from '../secure-page-base';
import { Constants } from '../../constants';
import { Subscription, interval } from 'rxjs';
import { CurrentContentProviderService } from '../../services/current-content-provider.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';
import { TimerService } from '../../services/timer-service';
import { ModerationApiService } from '../../apis/moderation-api.service';
import { takeWhile } from 'rxjs/operators';
var UserGeneratedModerateReviewComponent = /** @class */ (function (_super) {
    tslib_1.__extends(UserGeneratedModerateReviewComponent, _super);
    function UserGeneratedModerateReviewComponent(oidc, router, _currentContent, _timer, _moderationApi) {
        var _this = _super.call(this, oidc, router) || this;
        _this._currentContent = _currentContent;
        _this._timer = _timer;
        _this._moderationApi = _moderationApi;
        _this.isTimerWarning = false;
        _this.isTimerComplete = false;
        _this.showActionButtons = true;
        _this.timerWarningSeconds = Constants.moderationTimeoutWarningSeconds;
        _this.isAbortingModeration = false;
        _this.constants = Constants;
        return _this;
    }
    UserGeneratedModerateReviewComponent.prototype.onAuthorized = function () {
        var _this = this;
        var model = this._currentContent.getCurrentForUserGenerated();
        model.review.participantItems.forEach(function (i) {
            _this.determineOutcomeIndicators(i);
        });
        this.timerName = model.content.contentKey;
        this.prepareTimer();
        this.content = model;
    };
    UserGeneratedModerateReviewComponent.prototype.determineOutcomeIndicators = function (item) {
        switch (item.moderationOutcome) {
            case Constants.moderationOutcomeSuccessful:
                item.outcomeIndicatorText = 'P';
                item.outcomeIndicatorBadgeClass = 'badge-success';
                item.outcomeIndicatorTooltip = 'Processed';
                break;
            case Constants.moderationOutcomeDuplicateImage:
            case Constants.moderationOutcomeDuplicateImageHuman:
                item.outcomeIndicatorText = 'D';
                item.outcomeIndicatorBadgeClass = 'badge-dark';
                item.outcomeIndicatorTooltip = 'Duplicate Item';
                break;
            default:
                if (item.moderationOutcome) {
                    item.outcomeIndicatorText = 'R';
                    item.outcomeIndicatorBadgeClass = 'badge-danger';
                    item.outcomeIndicatorTooltip = "Rejected - " + item.moderationOutcome;
                }
                else {
                    // not moderated yet
                    item.outcomeIndicatorText = 'Q';
                    item.outcomeIndicatorBadgeClass = 'badge-secondary';
                    item.outcomeIndicatorTooltip = 'Queued';
                }
                break;
        }
        item.outcomeIndicatorClasses = {
            badge: true,
            'badge-lg': true
        };
        item.outcomeIndicatorClasses[item.outcomeIndicatorBadgeClass] = true;
    };
    UserGeneratedModerateReviewComponent.prototype.onNgOnDestroy = function () {
        if (this.abortSubscription) {
            this.abortSubscription.unsubscribe();
        }
    };
    UserGeneratedModerateReviewComponent.prototype.onShouldDisplayOwnUnauthorizedMessage = function () {
        return false;
    };
    //#region Timer
    UserGeneratedModerateReviewComponent.prototype.prepareTimer = function () {
        var _this = this;
        this._timer.delTimer(this.timerName);
        this._timer.newTimer(this.timerName, 1);
        this._timer.subscribe(this.timerName, function () { return _this.timerTick(); });
    };
    UserGeneratedModerateReviewComponent.prototype.getTimeoutInSeconds = function () {
        return this.content.content.timeoutInMinutes * 60;
    };
    UserGeneratedModerateReviewComponent.prototype.timerTick = function () {
        var _this = this;
        var assignedOn = new Date(this.content.content.assignedOn);
        var milliseconds = new Date().getTime() - assignedOn.getTime();
        var seconds = milliseconds / 1000;
        var timeoutInSeconds = this.getTimeoutInSeconds();
        this.secondsLeft = timeoutInSeconds - seconds;
        if (this.secondsLeft < 0) {
            this.isTimerWarning = false;
            this.isTimerComplete = true;
            this._timer.delTimer(this.timerName);
            interval(3000)
                .pipe(takeWhile(function () { return !_this.isAbortingModeration; }))
                .subscribe(function () {
                _this.abortModeration();
            });
        }
        else if (this.secondsLeft < this.timerWarningSeconds) {
            this.isTimerWarning = true;
        }
    };
    //#endregion
    UserGeneratedModerateReviewComponent.prototype.abortModeration = function () {
        var _this = this;
        this.isAbortingModeration = true;
        this.showActionButtons = false;
        this._timer.delTimer(this.timerName);
        this.abortSubscription = this._moderationApi.abortModerationOfContent(this.content.content.accountKey, this.content.content.contentKey).subscribe(function () {
            _this.router.navigate(['/program', _this.content.content.programKey]);
        });
    };
    return UserGeneratedModerateReviewComponent;
}(SecurePageBase));
export { UserGeneratedModerateReviewComponent };
