/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./digital-duplicate-indicator.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./digital-duplicate-indicator.component";
var styles_DigitalDuplicateIndicatorComponent = [i0.styles];
var RenderType_DigitalDuplicateIndicatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DigitalDuplicateIndicatorComponent, data: {} });
export { RenderType_DigitalDuplicateIndicatorComponent as RenderType_DigitalDuplicateIndicatorComponent };
function View_DigitalDuplicateIndicatorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "alert alert-warning-blue"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Resubmittal"])), (_l()(), i1.ɵted(-1, null, [" Possible resubmittal of same image."]))], null, null); }
function View_DigitalDuplicateIndicatorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "alert alert-danger"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Fingerprint Alert! - Potential Duplicate Image"])), (_l()(), i1.ɵted(-1, null, [" This image has attributes that identically match other images submitted to this program. "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DigitalDuplicateIndicatorComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.parentContentKey; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_DigitalDuplicateIndicatorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DigitalDuplicateIndicatorComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDuplicate; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DigitalDuplicateIndicatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-digital-duplicate-indicator", [], null, null, null, View_DigitalDuplicateIndicatorComponent_0, RenderType_DigitalDuplicateIndicatorComponent)), i1.ɵdid(1, 114688, null, 0, i3.DigitalDuplicateIndicatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DigitalDuplicateIndicatorComponentNgFactory = i1.ɵccf("app-digital-duplicate-indicator", i3.DigitalDuplicateIndicatorComponent, View_DigitalDuplicateIndicatorComponent_Host_0, { parentContentKey: "parentContentKey", isDuplicate: "isDuplicate" }, {}, []);
export { DigitalDuplicateIndicatorComponentNgFactory as DigitalDuplicateIndicatorComponentNgFactory };
