<div class="container-full-width">
    <h1>{{ contentKey }} Detail</h1>
    <app-loading-spinner *ngIf="!content && !error"></app-loading-spinner>
    <div *ngIf="content || error">
      <div *ngIf="error">
        <h1 class="error">Error!</h1>
        <p class="lead">{{ error }}</p>
      </div>
      <div *ngIf="content">
        <div class="row">
          <div class="col-3">
            <div style="width:440px;height:600px;">
              <app-resource-viewer [url]="content.content.resourceUrl" [mediaType]="content.content.resourceMediaType"></app-resource-viewer>
            </div>
            <h5>Upload Date: {{ content.content.createdOn | date: 'medium' }}</h5>
            <span *ngIf="content.content.moderatedOn; else unmoderated"><b>Moderated By:</b><span *ngIf="content.content.moderatedBy; else moderatedBySystem"> {{ content.content.moderatedBy }}</span>
              <b>On:</b> {{ content.content.moderatedOn | date: 'medium' }}</span>
            <ng-template #moderatedBySystem> System</ng-template>
            <ng-template #unmoderated><i>Pending Moderation</i></ng-template>
            <div *ngIf="content.content.isDuplicate" class="alert alert-danger">
              <strong>Fingerprint Alert! - Potential Duplicate Image</strong> This image has attributes that identically match other images submitted to this program.</div>
          </div>
          <div class="col-6">
            <table class="table table-sm product-list">
              <thead>
                <tr>
                  <th>Rule Sets</th>
                </tr>
                <tr>
                  <td>
                    <label class="mr-3">
                      <input type="checkbox" [(ngModel)]="showPassingRuleSets" />Pass</label>
                    <label class="mr-3">
                      <input type="checkbox" [(ngModel)]="showFailingRuleSets" />Fail</label>
                  </td>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let ruleSetItem of content.ruleSets">
                  <tr *ngIf="(showPassingRuleSets && ruleSetItem.isPassed) || (showFailingRuleSets && !ruleSetItem.isPassed)">
                    <td (click)="toggleRules(ruleSetItem)" [class.table-success]="ruleSetItem.isPassed" [class.table-danger]="!ruleSetItem.isPassed">
                      <label class="pointer"><i class="fa md mr-2 mt-1" [class.fa-caret-right]="!ruleSetItem.showRules" [class.fa-caret-down]="ruleSetItem.showRules" aria-hidden="true"></i>{{ ruleSetItem.text }}</label>
                      <ul *ngIf="ruleSetItem.showRules">
                        <li *ngFor="let ruleItem of ruleSetItem.rules" [class.table-success]="ruleItem.isPassed" [class.table-danger]="!ruleItem.isPassed">
                          {{ ruleItem.text }}
                        </li>
                      </ul>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row justify-content-md-center" *ngIf="content.content.rejectionReason">
          <div class="col-6">
            <div class="card-group">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Rejection Criteria</h4>
                  <button class="btn btn-danger mr-3 mt-3">{{ content.content.rejectionReason }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
