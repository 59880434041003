<div class="container" *ngIf="noItemsAvailable">
  <h1 class="display-3">
    There are no items available at this time.
  </h1>
  <a [routerLink]="['/program', programKey]">Go Home</a>
</div>
<div class="container" *ngIf="!noItemsAvailable">
  <div class="alert alert-info">
    Finding next available item. This should take but a second, so if you see this for too long, something went wrong.
  </div>
</div>
