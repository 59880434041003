import * as tslib_1 from "tslib";
import { SecurePageBase } from '../secure-page-base';
import { Constants } from '../../constants';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subscription, interval } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { CurrentContentProviderService } from '../../services/current-content-provider.service';
import { TimerService } from '../../services/timer-service';
import { ModerationApiService } from '../../apis/moderation-api.service';
import { takeWhile } from 'rxjs/operators';
var UserGeneratedModerateCompareComponent = /** @class */ (function (_super) {
    tslib_1.__extends(UserGeneratedModerateCompareComponent, _super);
    function UserGeneratedModerateCompareComponent(oidc, router, route, _currentContent, _timer, _moderationApi) {
        var _this = _super.call(this, oidc, router) || this;
        _this._currentContent = _currentContent;
        _this._timer = _timer;
        _this._moderationApi = _moderationApi;
        _this.isTimerWarning = false;
        _this.isTimerComplete = false;
        _this.showActionButtons = true;
        _this.timerWarningSeconds = Constants.moderationTimeoutWarningSeconds;
        _this.isAbortingModeration = false;
        _this.compareContentKey = route.snapshot.params['compareContentKey'];
        return _this;
    }
    UserGeneratedModerateCompareComponent.prototype.onKeyup = function (event) {
        if (event.keyCode === Constants.KEY_CODE.ESCAPE) {
            this.router.navigate(['/content', 'user-generated', this.content.content.accountKey, this.content.content.contentKey, 'review']);
        }
    };
    UserGeneratedModerateCompareComponent.prototype.onAuthorized = function () {
        var _this = this;
        var model = this._currentContent.getCurrentForUserGenerated();
        var compareUserGenerated = model.review.participantItems.filter(function (r) { return r.contentKey === _this.compareContentKey; })[0];
        this.timerName = model.content.contentKey;
        this.prepareTimer();
        this.content = model;
        this.compare = compareUserGenerated;
    };
    UserGeneratedModerateCompareComponent.prototype.onNgOnDestroy = function () {
        if (this.abortSubscription) {
            this.abortSubscription.unsubscribe();
        }
    };
    UserGeneratedModerateCompareComponent.prototype.onShouldDisplayOwnUnauthorizedMessage = function () {
        return false;
    };
    //#region Timer
    UserGeneratedModerateCompareComponent.prototype.prepareTimer = function () {
        var _this = this;
        this._timer.delTimer(this.timerName);
        this._timer.newTimer(this.timerName, 1);
        this._timer.subscribe(this.timerName, function () { return _this.timerTick(); });
    };
    UserGeneratedModerateCompareComponent.prototype.getTimeoutInSeconds = function () {
        return this.content.content.timeoutInMinutes * 60;
    };
    UserGeneratedModerateCompareComponent.prototype.timerTick = function () {
        var _this = this;
        var assignedOn = new Date(this.content.content.assignedOn);
        var milliseconds = new Date().getTime() - assignedOn.getTime();
        var seconds = milliseconds / 1000;
        var timeoutInSeconds = this.getTimeoutInSeconds();
        this.secondsLeft = timeoutInSeconds - seconds;
        if (this.secondsLeft < 0) {
            this.isTimerWarning = false;
            this.isTimerComplete = true;
            this._timer.delTimer(this.timerName);
            interval(3000)
                .pipe(takeWhile(function () { return !_this.isAbortingModeration; }))
                .subscribe(function () {
                _this.abortModeration();
            });
        }
        else if (this.secondsLeft < this.timerWarningSeconds) {
            this.isTimerWarning = true;
        }
    };
    //#endregion
    UserGeneratedModerateCompareComponent.prototype.abortModeration = function () {
        var _this = this;
        this.isAbortingModeration = true;
        this.showActionButtons = false;
        this._timer.delTimer(this.timerName);
        this.abortSubscription = this._moderationApi.abortModerationOfContent(this.content.content.accountKey, this.content.content.contentKey).subscribe(function () {
            _this.router.navigate(['/program', _this.content.content.programKey]);
        });
    };
    return UserGeneratedModerateCompareComponent;
}(SecurePageBase));
export { UserGeneratedModerateCompareComponent };
