import * as tslib_1 from "tslib";
import { interval as observableInterval, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { TimerService } from '../../services/timer-service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ModerationApiService } from '../../apis/moderation-api.service';
import { SecurePageBase } from '../secure-page-base';
import { ActivatedRoute, Router } from '@angular/router';
import { ReceiptContentForModerationReceiptItemModel, } from '../../models/moderation-models';
import { CurrentContentProviderService } from '../../services/current-content-provider.service';
import { Constants } from '../../constants';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { toDate } from '../../../i18n/date_util';
import * as moment from 'moment';
var ReceiptModerateComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ReceiptModerateComponent, _super);
    function ReceiptModerateComponent(oidc, router, moderationApi, _route, _timer, _currentContent) {
        var _this = _super.call(this, oidc, router) || this;
        _this.moderationApi = moderationApi;
        _this._route = _route;
        _this._timer = _timer;
        _this._currentContent = _currentContent;
        // Setting the date picker's dataType property to "string" (from "date") so that it serializes as a string and the TimeZone is ignored.
        // Leaving it at "date" sends local time to server and server adjusts to UTC
        _this.datePickerDataType = 'string';
        _this.isEditingStore = false;
        _this.isAddingReceiptItem = false;
        _this.newReceiptItemText = '';
        _this.validatationErrorMessage = '';
        _this.isSubmitting = false;
        _this.isTimerWarning = false;
        _this.isTimerComplete = false;
        _this.timerWarningSeconds = Constants.moderationTimeoutWarningSeconds;
        _this.timerOverrideSeconds = null;
        _this.zoomIsEnabled = false;
        _this.isAbortingModeration = false;
        _this.saveWarnings = new Array();
        _this.secondPassCollisionCount = 0;
        _this.constants = Constants;
        _this.accountKey = _this._route.snapshot.params['accountKey'];
        _this.contentKey = _this._route.snapshot.params['contentKey'];
        if (_route.snapshot.queryParams['timeout']) {
            _this.timerOverrideSeconds = +_route.snapshot.queryParams['timeout'];
        }
        _this._route.data.subscribe(function (data) {
            var model = _currentContent.getCurrentForReceipt();
            model.receipt.fingerprintCollisionGroups.forEach(function (group) {
                group.collisions.forEach(function (collision) {
                    _this.determineOutcomeIndicators(collision);
                });
            });
            _this.mergeConsumerData(model);
            _this.cleanPurchasedOn();
            _this.programKey = model.content.programKey;
            _this.content = model;
            _this.timerName = model.content.contentKey;
            _this.prepareTimer();
            _this.content = model;
        });
        return _this;
    }
    ReceiptModerateComponent.prototype.mergeConsumerData = function (model) {
        if (!model.consumer) {
            return;
        }
        var consumer = model.consumer;
        if (consumer.purchasedOn) {
            model.receipt.purchasedOn = consumer.purchasedOn;
        }
        if (consumer.storeKey) {
            model.receipt.storeKey = consumer.storeKey;
            model.receipt.storeName = consumer.storeName;
        }
        consumer.receiptItems.forEach(function (item) {
            var newItem = new ReceiptContentForModerationReceiptItemModel();
            newItem.productKey = item.productKey;
            newItem.productName = item.productName;
            newItem.quantity = item.quantity;
            newItem.total = item.total;
            newItem.index = null;
            newItem.isConsumer = true;
            model.receiptItems.splice(0, 0, newItem);
        });
    };
    ReceiptModerateComponent.prototype.onAfterPurchasedOnPickerClosed = function () {
        this.cleanPurchasedOn();
    };
    ReceiptModerateComponent.prototype.cleanPurchasedOn = function () {
        // We do not want to include seconds/ms on the purchased date.
        if (this.content && this.content.receipt && this.content.receipt.purchasedOn) {
            // If it is a string, date it and then wrap it in a moment
            if (typeof this.content.receipt.purchasedOn === 'string') {
                this.content.receipt.purchasedOn = moment(toDate(this.content.receipt.purchasedOn));
            }
            // If it is already a date, wrap it in a moment
            if (this.content.receipt.purchasedOn instanceof Date) {
                this.content.receipt.purchasedOn = moment(this.content.receipt.purchasedOn);
            }
            // Otherwise, it is already a moment
            // @ts-ignore Not sure why this line is complaining about assigning a type, so ignoring it on this line.
            var mom = this.content.receipt.purchasedOn;
            mom.seconds(0);
            mom.milliseconds(0);
        }
    };
    ReceiptModerateComponent.prototype.onNgOnDestroy = function () {
        if (this.contentSubscription) {
            this.contentSubscription.unsubscribe();
        }
        if (this.abortSubscription) {
            this.abortSubscription.unsubscribe();
        }
        if (this.saveSubscription) {
            this.saveSubscription.unsubscribe();
        }
        if (this.parkSubscription) {
            this.parkSubscription.unsubscribe();
        }
        this._timer.delTimer(this.timerName);
    };
    //#region Link Product to Receipt Item
    ReceiptModerateComponent.prototype.selectReceiptItemForEditing = function (item) {
        this.selectedReceiptItem = item;
    };
    ReceiptModerateComponent.prototype.cancelReceiptItemForEditing = function () {
        this.selectedReceiptItem = null;
    };
    ReceiptModerateComponent.prototype.selectProductForReceiptItem = function (product) {
        if (this.selectedReceiptItem == null) {
            this.newReceiptItemText = null;
            this.isAddingReceiptItem = true;
            this.saveAddingReceiptItem(true);
            this.selectedReceiptItem = this.content.receiptItems[0];
        }
        this.selectedReceiptItem.productKey = product.productKey;
        this.selectedReceiptItem.productName = product.name;
        this.selectedReceiptItem = null;
    };
    ReceiptModerateComponent.prototype.removeProductFromReceiptItem = function () {
        this.selectedReceiptItem.productKey = null;
        this.selectedReceiptItem.productName = null;
        this.selectedReceiptItem = null;
    };
    //#endregion
    //#region Store Selection
    ReceiptModerateComponent.prototype.selectStoreForEditing = function () {
        this.isEditingStore = true;
    };
    ReceiptModerateComponent.prototype.cancelStoreForEditing = function () {
        this.isEditingStore = false;
    };
    ReceiptModerateComponent.prototype.removeStoreFromReceipt = function () {
        this.content.receipt.storeKey = null;
        this.content.receipt.storeName = null;
        this.isEditingStore = false;
    };
    ReceiptModerateComponent.prototype.selectStore = function (store) {
        this.content.receipt.storeKey = store.storeKey;
        this.content.receipt.storeName = store.name;
        this.isEditingStore = false;
    };
    //#endregion
    //#region Timer
    ReceiptModerateComponent.prototype.prepareTimer = function () {
        var _this = this;
        this._timer.delTimer(this.timerName);
        this._timer.newTimer(this.timerName, 1);
        this._timer.subscribe(this.timerName, function () { return _this.timerTick(); });
    };
    ReceiptModerateComponent.prototype.getTimeoutInSeconds = function () {
        return this.timerOverrideSeconds || this.content.content.timeoutInMinutes * 60;
    };
    ReceiptModerateComponent.prototype.timerTick = function () {
        var _this = this;
        var assignedOn = new Date(this.content.content.assignedOn);
        var milliseconds = new Date().getTime() - assignedOn.getTime();
        var seconds = milliseconds / 1000;
        var timeoutInSeconds = this.getTimeoutInSeconds();
        this.secondsLeft = timeoutInSeconds - seconds;
        if (this.secondsLeft < 0) {
            this.isTimerWarning = false;
            this.isTimerComplete = true;
            this._timer.delTimer(this.timerName);
            observableInterval(3000).pipe(takeWhile(function () { return !_this.isAbortingModeration; }))
                .subscribe(function () {
                _this.abortModeration();
            });
        }
        else if (this.secondsLeft < this.timerWarningSeconds) {
            this.isTimerWarning = true;
        }
    };
    //#endregion
    ReceiptModerateComponent.prototype.onAuthorized = function () { };
    ReceiptModerateComponent.prototype.onShouldDisplayOwnUnauthorizedMessage = function () {
        return false;
    };
    ReceiptModerateComponent.prototype.abortModeration = function () {
        var _this = this;
        this.isAbortingModeration = true;
        this.isSubmitting = true;
        this._timer.delTimer(this.timerName);
        this.abortSubscription = this.moderationApi.abortModerationOfContent(this.accountKey, this.contentKey).subscribe(function () {
            _this.router.navigate(['/program', _this.programKey]);
        });
    };
    ReceiptModerateComponent.prototype.parkModeration = function (form) {
        var _this = this;
        if (form.valid) {
            this.isAbortingModeration = true;
            this.isSubmitting = true;
            this._timer.delTimer(this.timerName);
            this.parkSubscription = this.moderationApi.parkReceiptContent(this.accountKey, this.contentKey, this.parkReason).subscribe(function () {
                _this.router.navigate(['/program', _this.programKey]);
            });
        }
    };
    ReceiptModerateComponent.prototype.saveModeration = function () {
        if (!this.isValid()) {
            return;
        }
        this.determineSaveWarnings();
        if (this.saveWarnings.length > 0) {
            this.saveWarningModal.show();
            return;
        }
        this.confirmSaveModeration();
    };
    ReceiptModerateComponent.prototype.confirmSaveModeration = function () {
        var _this = this;
        this.saveWarningModal.hide();
        this.isSubmitting = true;
        this._timer.delTimer(this.timerName);
        // Need a clone of content with some fewer properties for comparison
        var clonedContent = JSON.parse(JSON.stringify(this.content));
        delete clonedContent.receipt.fingerprintCollisionGroups;
        delete clonedContent.rejectionReasons;
        delete clonedContent.settings;
        delete clonedContent.stores;
        delete clonedContent.products;
        var thisPayload = JSON.stringify(clonedContent);
        var bypassNewSecondPassCollisions = false;
        if (thisPayload === this.lastSubmittedPayload) {
            bypassNewSecondPassCollisions = true;
        }
        this.lastSubmittedPayload = thisPayload;
        this.saveSubscription = this.moderationApi.saveModerationForReceipt(this.accountKey, this.contentKey, this.content, bypassNewSecondPassCollisions).subscribe(function (result) {
            if (result.didSave) {
                _this.next();
            }
            else {
                _this.displaySecondPassWarning(result);
            }
        });
    };
    ReceiptModerateComponent.prototype.displaySecondPassWarning = function (result) {
        var _this = this;
        result.fingerprintCollisionGroups.forEach(function (group) {
            group.collisions.forEach(function (collision) {
                collision.isSecondPass = true;
                _this.determineOutcomeIndicators(collision);
            });
        });
        this.secondPassCollisionCount = 0;
        // Fold those into the existing groups, but remove the previous ones first
        this.purgeCurrentSecondPassCollisions();
        result.fingerprintCollisionGroups.forEach(function (newGroup) {
            _this.secondPassCollisionCount += newGroup.collisions.length;
            var existingGroup = _this.content.receipt.fingerprintCollisionGroups.filter(function (g) { return g.category === newGroup.category; })[0];
            if (existingGroup) {
                newGroup.collisions.forEach(function (newCollision) {
                    existingGroup.collisions.splice(0, 0, newCollision);
                });
            }
            else {
                _this.content.receipt.fingerprintCollisionGroups.push(newGroup);
            }
        });
        this.isSubmitting = false;
        this.secondPassCollisionsWarningModal.show();
    };
    ReceiptModerateComponent.prototype.purgeCurrentSecondPassCollisions = function () {
        var _this = this;
        this.content.receipt.fingerprintCollisionGroups.forEach(function (group) {
            _this.removeIf(group.collisions, function (x) { return x.isSecondPass; });
        });
        this.removeIf(this.content.receipt.fingerprintCollisionGroups, function (x) { return x.collisions.length === 0; });
    };
    ReceiptModerateComponent.prototype.removeIf = function (items, predicate) {
        var i = items.length;
        while (i--) {
            if (predicate(items[i])) {
                items.splice(i, 1);
            }
        }
    };
    ReceiptModerateComponent.prototype.cancelSaveModeration = function () {
        this.saveWarningModal.hide();
    };
    ReceiptModerateComponent.prototype.cancelSecondPassWarning = function () {
        this.secondPassCollisionsWarningModal.hide();
    };
    ReceiptModerateComponent.prototype.confirmSecondPassWarning = function () {
        this.secondPassCollisionsWarningModal.hide();
        this.confirmSaveModeration();
    };
    ReceiptModerateComponent.prototype.determineSaveWarnings = function () {
        this.saveWarnings.splice(0, this.saveWarnings.length);
        //#region No Products selected warning
        if (!this.content.receiptItems.find(function (ri) { return !!ri.productKey; })) {
            this.saveWarnings.push("No product(s) have been selected");
        }
        //#endregion
        //#region Fingerprint warnings
        if (this.content.receipt.fingerprintCollisionGroups.length > 0) {
            var collisionCount_1 = 0;
            this.content.receipt.fingerprintCollisionGroups.forEach(function (grp) {
                collisionCount_1 += grp.collisions.length;
            });
            this.saveWarnings.push("There are " + collisionCount_1 + " fingerprint collisions");
        }
        //#endregion
        //#region Quantity/Total max limit warnings
        var totalProductQuantity = 0;
        var totalProductTotal = 0;
        this.content.receiptItems.forEach(function (r) {
            if (r.productKey) {
                if (r.quantity) {
                    totalProductQuantity += r.quantity;
                }
                if (r.total) {
                    totalProductTotal += r.total;
                }
            }
        });
        // This will be configurable some day
        var maxQuantity = 20;
        var maxTotal = this.content.settings.receiptTotalWarningThreshold;
        if (this.content.settings.showQuantity && totalProductQuantity > maxQuantity) {
            this.saveWarnings.push("The total Quantity of " + totalProductQuantity + " exceeds the warning limit of " + maxQuantity);
        }
        if (this.content.settings.showTotal) {
            if (totalProductTotal > maxTotal) {
                this.saveWarnings.push("The total Price of " + totalProductTotal.toFixed(2) + " exceeds the warning limit of " + maxTotal.toFixed(2));
            }
            if (this.content.receipt.total > maxTotal) {
                this.saveWarnings.push("The Receipt Total of " + this.content.receipt.total.toFixed(2) + " exceeds the warning limit of " + maxTotal.toFixed(2));
            }
        }
        //#endregion
    };
    ReceiptModerateComponent.prototype.isValid = function () {
        if (this.content.settings.requirePurchasedOn && !this.content.receipt.purchasedOn) {
            this.validatationErrorMessage = "Purchased On Date is required";
            return false;
        }
        if (this.content.settings.requireStore && !this.content.receipt.storeKey) {
            this.validatationErrorMessage = "Retailer is required";
            return false;
        }
        if (this.content.settings.isStoreNumberCaptured && this.content.settings.isStoreNumberRequired && !this.content.receipt.storeNumber) {
            this.validatationErrorMessage = 'Store Number is required for this item';
            return false;
        }
        for (var i = 0; i < this.content.receiptItems.length; i++) {
            var item = this.content.receiptItems[i];
            // Newly added receipt items checks
            if (item.index === null && !item.isConsumer) {
                if (!item.productKey) {
                    this.validatationErrorMessage = "The newly added item \"" + item.text + "\" must be linked to a product.";
                    return false;
                }
            }
            // Global checks
            if (this.content.settings.requireQuantity && item.productKey && item.quantity <= 0) {
                this.validatationErrorMessage = "The item \"" + item.text + "\" for product \"" + item.productName + "\" must have a valid quantity.";
                return false;
            }
            if (this.content.settings.requireTotal && item.productKey && item.total <= 0) {
                this.validatationErrorMessage = "The item \"" + item.text + "\" for product \"" + item.productName + "\" must have a valid price.";
                return false;
            }
        }
        this.validatationErrorMessage = '';
        return true;
    };
    ReceiptModerateComponent.prototype.cannotModerate = function (outcome) {
        var _this = this;
        this.isSubmitting = true;
        this._timer.delTimer(this.timerName);
        this.saveSubscription = this.moderationApi.saveCannotModerate(this.accountKey, this.contentKey, outcome.value).subscribe(function () {
            _this.next();
        });
    };
    ReceiptModerateComponent.prototype.next = function () {
        this.router.navigate(['/content', 'next', 'program', this.programKey]);
    };
    ReceiptModerateComponent.prototype.determineOutcomeIndicators = function (item) {
        switch (item.moderationOutcome) {
            case Constants.moderationOutcomeSuccessful:
                item.outcomeIndicatorText = 'P';
                item.outcomeIndicatorBadgeClass = 'badge-success';
                item.outcomeIndicatorTooltip = 'Processed';
                break;
            case Constants.moderationOutcomeDuplicateImage:
            case Constants.moderationOutcomeDuplicateImageHuman:
                item.outcomeIndicatorText = 'D';
                item.outcomeIndicatorBadgeClass = 'badge-dark';
                item.outcomeIndicatorTooltip = 'Duplicate Receipt';
                break;
            default:
                if (item.moderationOutcome) {
                    item.outcomeIndicatorText = 'R';
                    item.outcomeIndicatorBadgeClass = 'badge-danger';
                    item.outcomeIndicatorTooltip = "Rejected - " + item.moderationOutcome;
                }
                else {
                    // not moderated yet
                    item.outcomeIndicatorText = 'Q';
                    item.outcomeIndicatorBadgeClass = 'badge-secondary';
                    item.outcomeIndicatorTooltip = 'Queued';
                }
                break;
        }
        item.outcomeIndicatorClasses = {
            badge: true,
            'ml-1': true
        };
        item.outcomeIndicatorClasses[item.outcomeIndicatorBadgeClass] = true;
    };
    ReceiptModerateComponent.prototype.performQuickAction = function (action) {
        var _this = this;
        if (action.storeKey) {
            var matchingStore = this.content.stores.filter(function (s) { return s.storeKey === action.storeKey; })[0];
            if (matchingStore) {
                this.content.receipt.storeKey = matchingStore.storeKey;
                this.content.receipt.storeName = matchingStore.name;
            }
        }
        if (action.storeNumber) {
            this.content.receipt.storeNumber = action.storeNumber;
        }
        if (action.purchasedOn) {
            this.content.receipt.purchasedOn = action.purchasedOn;
            this.cleanPurchasedOn();
        }
        if (action.products && action.products.length > 0) {
            action.products.forEach(function (product) {
                var matchingProduct = _this.content.products.filter(function (p) { return p.productKey === product.productKey; })[0];
                if (matchingProduct) {
                    _this.newReceiptItemText = '';
                    _this.saveAddingReceiptItem(true);
                    var newItem = _this.content.receiptItems[0];
                    newItem.quantity = product.quantity;
                    newItem.total = product.total;
                    newItem.productKey = matchingProduct.productKey;
                    newItem.productName = matchingProduct.name;
                }
            });
        }
        if (action.total) {
            this.content.receipt.total = action.total;
        }
        action.performed = true;
    };
    //#region Add Receipt Item
    ReceiptModerateComponent.prototype.startAddingReceiptItem = function () {
        this.newReceiptItemText = '';
        this.isAddingReceiptItem = true;
    };
    ReceiptModerateComponent.prototype.abortAddingReceiptItem = function () {
        this.isAddingReceiptItem = false;
    };
    ReceiptModerateComponent.prototype.saveAddingReceiptItem = function (insertAtTop) {
        if (insertAtTop === void 0) { insertAtTop = false; }
        var newItem = new ReceiptContentForModerationReceiptItemModel();
        newItem.text = this.newReceiptItemText;
        newItem.index = null;
        newItem.quantity = 1;
        newItem.total = null;
        newItem.isManuallyAdded = true;
        if (insertAtTop) {
            this.content.receiptItems.splice(0, 0, newItem);
        }
        else {
            this.content.receiptItems.push(newItem);
        }
        this.newReceiptItemText = '';
        this.isAddingReceiptItem = false;
    };
    ReceiptModerateComponent.prototype.removeAddedReceiptItem = function (item) {
        var index = this.content.receiptItems.indexOf(item);
        this.content.receiptItems.splice(index, 1);
    };
    return ReceiptModerateComponent;
}(SecurePageBase));
export { ReceiptModerateComponent };
