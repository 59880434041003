import { Component, OnInit, HostListener } from '@angular/core';
import { SecurePageBase } from '../secure-page-base';
import { Constants } from '../../constants';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UserGeneratedContentForModerationModel } from '../../models/user-generated-content-for-moderation.model';
import { UserGeneratedContentForModerationReviewItemModel } from '../../models/user-generated-content-for-moderation-review-item.model';
import { Subscription, Observable, interval } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { CurrentContentProviderService } from '../../services/current-content-provider.service';
import { TimerService } from '../../services/timer-service';
import { ModerationApiService } from '../../apis/moderation-api.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-user-generated-moderate-compare',
  templateUrl: './user-generated-moderate-compare.component.html',
  styleUrls: ['./user-generated-moderate-compare.component.css']
})
export class UserGeneratedModerateCompareComponent extends SecurePageBase {
  content: UserGeneratedContentForModerationModel;
  compare: UserGeneratedContentForModerationReviewItemModel;
  compareContentKey: string;
  timerName: string;
  isTimerWarning = false;
  isTimerComplete = false;
  showActionButtons = true;
  secondsLeft: number;
  private timerWarningSeconds = Constants.moderationTimeoutWarningSeconds;
  private abortSubscription: Subscription;
  private isAbortingModeration = false;

  @HostListener('window:keyup', ['$event']) onKeyup(event: KeyboardEvent) {
    if (event.keyCode === Constants.KEY_CODE.ESCAPE) {
      this.router.navigate(['/content', 'user-generated', this.content.content.accountKey, this.content.content.contentKey, 'review']);
    }
  }

  constructor(oidc: OidcSecurityService, router: Router, route: ActivatedRoute, private _currentContent: CurrentContentProviderService, private _timer: TimerService, private _moderationApi: ModerationApiService) {
    super(oidc, router);

    this.compareContentKey = route.snapshot.params['compareContentKey'];
  }

  onAuthorized(): void {
    const model = this._currentContent.getCurrentForUserGenerated();

    const compareUserGenerated = model.review.participantItems.filter(r => r.contentKey === this.compareContentKey)[0];

    this.timerName = model.content.contentKey;
    this.prepareTimer();

    this.content = model;
    this.compare = compareUserGenerated;
  }
  onNgOnDestroy(): void {
    if (this.abortSubscription) {
      this.abortSubscription.unsubscribe();
    }
  }
  onShouldDisplayOwnUnauthorizedMessage(): boolean {
    return false;
  }

  //#region Timer
  private prepareTimer(): void {
    this._timer.delTimer(this.timerName);
    this._timer.newTimer(this.timerName, 1);
    this._timer.subscribe(this.timerName, () => this.timerTick());
  }
  private getTimeoutInSeconds(): number {
    return this.content.content.timeoutInMinutes * 60;
  }
  private timerTick(): void {
    const assignedOn = new Date(this.content.content.assignedOn);
    const milliseconds = new Date().getTime() - assignedOn.getTime();
    const seconds = milliseconds / 1000;
    const timeoutInSeconds = this.getTimeoutInSeconds();
    this.secondsLeft = timeoutInSeconds - seconds;

    if (this.secondsLeft < 0) {
      this.isTimerWarning = false;
      this.isTimerComplete = true;
      this._timer.delTimer(this.timerName);
      interval(3000)
        .pipe(takeWhile(() => !this.isAbortingModeration))
        .subscribe(() => {
          this.abortModeration();
        });
    } else if (this.secondsLeft < this.timerWarningSeconds) {
      this.isTimerWarning = true;
    }
  }
  //#endregion

  abortModeration(): void {
    this.isAbortingModeration = true;
    this.showActionButtons = false;
    this._timer.delTimer(this.timerName);

    this.abortSubscription = this._moderationApi.abortModerationOfContent(this.content.content.accountKey, this.content.content.contentKey).subscribe(() => {
      this.router.navigate(['/program', this.content.content.programKey]);
    });
  }
}
