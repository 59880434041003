import { FileType } from '../models/file-type.enum';
var FileIdentificationModel = /** @class */ (function () {
    function FileIdentificationModel() {
    }
    return FileIdentificationModel;
}());
export { FileIdentificationModel };
var FileIdentificationService = /** @class */ (function () {
    function FileIdentificationService() {
    }
    FileIdentificationService.prototype.identify = function (urlString, mediaType) {
        if (!!mediaType) {
            return this.identifyByMediaType(mediaType);
        }
        else {
            return this.identifyByUrl(urlString);
        }
    };
    FileIdentificationService.prototype.identifyByUrl = function (urlString) {
        // Make sure these are all lowercase
        var pdfTypes = ['.pdf'];
        // Not included these because browsers don't really embed them easily, so they can just fall under the Unknown
        //    .wmv, .mov
        var videoTypes = ['.mp4', '.webm', '.ogm', '.ogv', '.ogg'];
        var imageTypes = ['.jpg', '.jpeg', '.png', '.gif'];
        var result = new FileIdentificationModel();
        var url = new URL(urlString);
        var pathname = url.pathname;
        result.extension = pathname.indexOf('.') > -1 ? pathname.substr(pathname.lastIndexOf('.')) : urlString;
        result.extension = result.extension.toLowerCase();
        result.extensionDisplay = result.extension.replace(/\./, '').toUpperCase();
        if (videoTypes.includes(result.extension)) {
            result.fileType = FileType.Video;
        }
        else if (pdfTypes.includes(result.extension)) {
            result.fileType = FileType.PDF;
        }
        else if (imageTypes.includes(result.extension)) {
            result.fileType = FileType.Image;
        }
        else {
            result.fileType = FileType.Unknown;
        }
        return this.finalizeResult(result);
    };
    FileIdentificationService.prototype.identifyByMediaType = function (mediaType) {
        var result = new FileIdentificationModel();
        if (mediaType.startsWith('image/')) {
            result.fileType = FileType.Image;
        }
        else if (mediaType.startsWith('video/') || mediaType === 'application/ogg') {
            result.fileType = FileType.Video;
        }
        else if (mediaType === 'application/pdf') {
            result.fileType = FileType.PDF;
        }
        else {
            result.fileType = FileType.Unknown;
        }
        return this.finalizeResult(result);
    };
    FileIdentificationService.prototype.finalizeResult = function (result) {
        result.isUnknown = result.fileType === FileType.Unknown;
        result.isPDF = result.fileType === FileType.PDF;
        result.isVideo = result.fileType === FileType.Video;
        result.isImage = result.fileType === FileType.Image;
        return result;
    };
    return FileIdentificationService;
}());
export { FileIdentificationService };
