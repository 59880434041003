import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subscription } from 'rxjs';

import { ModerationApiService } from '../../apis/moderation-api.service';
import { ProgramDashboardModel, AvailableContentModel, AvailableContentItemModel } from '../../models/dashboard-model';
import { SecurePageBase } from '../secure-page-base';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { CurrentContentProviderService } from '../../services/current-content-provider.service';
import { Constants } from '../../constants';
import { RuleSetType } from '../../models/rule-set-type.enum';

@Component({
  selector: 'app-program-dashboard',
  templateUrl: './program-dashboard.component.html',
  styleUrls: ['./program-dashboard.component.css']
})
export class ProgramDashboardComponent extends SecurePageBase {
  constants: typeof Constants;
  programKey: string;
  model: ProgramDashboardModel;
  error: ErrorObservable<any>;
  dashboardSubscription: Subscription;
  availableContent: AvailableContentModel;
  availableContentSubscription: Subscription;
  parkedContent: AvailableContentModel;
  parkedContentSubscription: Subscription;
  myContent: AvailableContentModel;
  myContentSubscription: Subscription;
  showActionButtons: Boolean = true;
  currentList = 'available';
  unparkSubscription: Subscription;
  isItemRequesting: Boolean = false;
  ruleSetTypes: typeof RuleSetType;

  constructor(oidc: OidcSecurityService, router: Router, private _route: ActivatedRoute, private moderationApi: ModerationApiService, currentContent: CurrentContentProviderService) {
    super(oidc, router);
    this.programKey = this._route.snapshot.params['programKey'];
    this.constants = Constants;
    this.ruleSetTypes = RuleSetType;
    currentContent.clear();
  }

  private populateDashboard(): void {
    this.dashboardSubscription = this.moderationApi.getProgramDashboard(this.programKey).subscribe(
      (programDashboardModel: ProgramDashboardModel) => {
        this.model = programDashboardModel;
      },
      (error: ErrorObservable<any>) => {
        this.error = error;
      }
    );
  }
  private populateAvailableContentList(): void {
    this.showActionButtons = false;

    this.myContentSubscription = this.moderationApi.getMyStartedContent(this.programKey).subscribe((model: AvailableContentModel) => {
      this.myContent = model;
      if (this.myContent.items.length > 0) {
        this.currentList = 'myWork';
      } else {
        this.currentList = 'available';
      }

      this.availableContentSubscription = this.moderationApi.getAvailableContent(this.programKey).subscribe((availableModel: AvailableContentModel) => {
        this.availableContent = availableModel;
        this.showActionButtons = true;
      });

      this.parkedContentSubscription = this.moderationApi.getParkedContent(this.programKey).subscribe((parkedModel: AvailableContentModel) => {
        this.parkedContent = parkedModel;
      });
    });
  }

  onNgOnDestroy(): void {
    if (this.dashboardSubscription) {
      this.dashboardSubscription.unsubscribe();
    }
    if (this.availableContentSubscription) {
      this.availableContentSubscription.unsubscribe();
    }
    if (this.myContentSubscription) {
      this.myContentSubscription.unsubscribe();
    }
    if (this.parkedContentSubscription) {
      this.parkedContentSubscription.unsubscribe();
    }
    if (this.unparkSubscription) {
      this.unparkSubscription.unsubscribe();
    }
  }

  onAuthorized(): void {
    this.populateDashboard();
    this.populateAvailableContentList();
  }

  onShouldDisplayOwnUnauthorizedMessage(): boolean {
    return true;
  }

  refreshAvailable(): void {
    this.populateDashboard();
    this.populateAvailableContentList();
  }

  activateList(list: string): void {
    this.currentList = list;
  }

  requestItem(content: AvailableContentItemModel): void {
    this.isItemRequesting = true;
    const typeRoute = content.contentModerationType === RuleSetType.UserGenerated ? 'user-generated' : 'receipt';
    this.router.navigate(['/content', typeRoute, content.accountKey, content.contentKey]);
  }

  unpark(content: AvailableContentItemModel): void {
    this.showActionButtons = false;
    this.isItemRequesting = true;
    if (content.contentModerationType === RuleSetType.UserGenerated) {
      this.unparkSubscription = this.moderationApi.unparkAndGetUserGeneratedContentForModeration(content.accountKey, content.contentKey).subscribe(() => {
        this.router.navigate(['/content', 'user-generated', content.accountKey, content.contentKey]);
      });
    } else {
      this.unparkSubscription = this.moderationApi.unparkAndGetReceiptContentForModeration(content.accountKey, content.contentKey).subscribe(() => {
        this.router.navigate(['/content', 'receipt', content.accountKey, content.contentKey]);
      });
    }
  }
}
