import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-resubmit-indicator',
  templateUrl: './resubmit-indicator.component.html',
  styleUrls: ['./resubmit-indicator.component.css']
})
export class ResubmitIndicatorComponent implements OnInit {

  @Input() public accountKey: string;
  @Input() public contentKey: string;
  @Input() public type: string;

  constructor() { }

  ngOnInit() {
  }

}
