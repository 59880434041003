import { Component, OnInit } from '@angular/core';
import { SecurePageBase } from '../secure-page-base';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { ProgramDashboardModel, ProgramAnalyticsReportResultsModel, AnalyticsExtractModel } from '../../models/dashboard-model';
import { ModerationApiService } from '../../apis/moderation-api.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-program-data-export',
  templateUrl: './program-data-export.component.html',
  styleUrls: ['./program-data-export.component.css']
})
export class ProgramDataExportComponent extends SecurePageBase {
  programKey: string;
  error: ErrorObservable<any>;
  dashboardSubscription: Subscription;
  dashboardData: ProgramDashboardModel;
  isItemRequesting: Boolean = false;
  analyticsReports: ProgramAnalyticsReportResultsModel;
  dropdownSettings: { singleSelection: boolean; idField: string; textField: string; selectAllText: string; unSelectAllText: string; itemsShowLimit: number; allowSearchFilter: boolean; };
  extractRequest: AnalyticsExtractModel;
  isExtracting: boolean = false;

  constructor(oidc: OidcSecurityService, router: Router, private _route: ActivatedRoute, private moderationApi: ModerationApiService) {
    super(oidc, router);
    this.programKey = this._route.snapshot.params['programKey'];

    this.extractRequest = new AnalyticsExtractModel();
  }

  onNgOnDestroy(): void {}

  onAuthorized(): void {
    this.populateDashboard();
    this.populateReportsAvailable();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'report',
      textField: 'report',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 99,
      allowSearchFilter: true
    };
  }

  private populateReportsAvailable(): any {
    this.moderationApi.getProgramAnalyticsReportDefinitions(this.programKey).subscribe((data: ProgramAnalyticsReportResultsModel) => {
      this.analyticsReports = data;
    });
  }

  private populateDashboard(): void {
    this.dashboardSubscription = this.moderationApi.getProgramDashboard(this.programKey).subscribe(
      (programDashboardModel: ProgramDashboardModel) => {
        this.dashboardData = programDashboardModel;
      },
      (error: ErrorObservable<any>) => {
        this.error = error;
      }
    );
  }

  onShouldDisplayOwnUnauthorizedMessage(): boolean {
    return false;
  }

  performExtract(): void {
    this.isExtracting = true;
    this.moderationApi.getProgramAnalyticsExtract(this.programKey, this.extractRequest).subscribe(data => {
      const blob = new Blob([data], { type: 'text/csv'});
      saveAs(blob, 'ModerationExtract.csv');
      this.isExtracting = false;
    });
  }

}
