<nav class="navbar navbar-expand-md bg-dark fixed-top" [ngClass]="environmentIndicator" [class.navbar-dark]="environmentIndicator === 'prd'" [class.navbar-light]="environmentIndicator !== 'prd'">
  <span class="version">v{{ version }} (Api: v{{ apiVersion }})</span>
  <span class="badge environment">{{ environmentIndicator }}</span>
  <a class="navbar-brand" [routerLink]="['/home']">Moderation Station</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarsExampleDefault">
    <ul class="navbar-nav mr-auto">
      <li *ngIf="isAuthorized" class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/home']">Home</a>
      </li>
      <li *ngIf="isAuthorized" class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/content', 'search']">Search</a>
      </li>
    </ul>
    <span *ngIf="isAuthorized" class="navbar-text">
      {{ userData.name }}
      <a class="link" (click)="logoff()">Log Off</a>
    </span>
    <span *ngIf="!isAuthorized" class="navbar-text">
      <a class="link" (click)="logon()">Log On</a>
    </span>
  </div>
</nav>
