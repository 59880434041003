import * as tslib_1 from "tslib";
import { SecurePageBase } from '../secure-page-base';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router, ActivatedRoute } from '@angular/router';
import { AnalyticsExtractModel } from '../../models/dashboard-model';
import { ModerationApiService } from '../../apis/moderation-api.service';
import { saveAs } from 'file-saver';
var ProgramDataExportComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ProgramDataExportComponent, _super);
    function ProgramDataExportComponent(oidc, router, _route, moderationApi) {
        var _this = _super.call(this, oidc, router) || this;
        _this._route = _route;
        _this.moderationApi = moderationApi;
        _this.isItemRequesting = false;
        _this.isExtracting = false;
        _this.programKey = _this._route.snapshot.params['programKey'];
        _this.extractRequest = new AnalyticsExtractModel();
        return _this;
    }
    ProgramDataExportComponent.prototype.onNgOnDestroy = function () { };
    ProgramDataExportComponent.prototype.onAuthorized = function () {
        this.populateDashboard();
        this.populateReportsAvailable();
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'report',
            textField: 'report',
            selectAllText: 'Select All',
            unSelectAllText: 'Unselect All',
            itemsShowLimit: 99,
            allowSearchFilter: true
        };
    };
    ProgramDataExportComponent.prototype.populateReportsAvailable = function () {
        var _this = this;
        this.moderationApi.getProgramAnalyticsReportDefinitions(this.programKey).subscribe(function (data) {
            _this.analyticsReports = data;
        });
    };
    ProgramDataExportComponent.prototype.populateDashboard = function () {
        var _this = this;
        this.dashboardSubscription = this.moderationApi.getProgramDashboard(this.programKey).subscribe(function (programDashboardModel) {
            _this.dashboardData = programDashboardModel;
        }, function (error) {
            _this.error = error;
        });
    };
    ProgramDataExportComponent.prototype.onShouldDisplayOwnUnauthorizedMessage = function () {
        return false;
    };
    ProgramDataExportComponent.prototype.performExtract = function () {
        var _this = this;
        this.isExtracting = true;
        this.moderationApi.getProgramAnalyticsExtract(this.programKey, this.extractRequest).subscribe(function (data) {
            var blob = new Blob([data], { type: 'text/csv' });
            saveAs(blob, 'ModerationExtract.csv');
            _this.isExtracting = false;
        });
    };
    return ProgramDataExportComponent;
}(SecurePageBase));
export { ProgramDataExportComponent };
