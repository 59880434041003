
import {interval as observableInterval,  Observable ,  Subscription } from 'rxjs';

import {takeWhile} from 'rxjs/operators';
import { TimerService } from '../../services/timer-service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ModerationApiService } from '../../apis/moderation-api.service';
import { SecurePageBase } from '../secure-page-base';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchFilterPipe } from '../../pipes/search-filter.pipe';
import {
  ReceiptContentForModerationModel,
  ReceiptContentForModerationReceiptItemModel,
  ReceiptContentForModerationQuickActionModel,
  ProductSelectionModel,
  ModerationOutcomeModel,
  StoreSelectionModel,
  ReceiptContentForModerationConsumerModel,
  ReceiptContentForModerationConsumerReceiptItemModel,
} from '../../models/moderation-models';
import { NgForm } from '@angular/forms';
import { CurrentContentProviderService } from '../../services/current-content-provider.service';
import { Constants } from '../../constants';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SaveModeratedReceiptResults } from '../../models/save-moderated-receipt-results.model';
import { Moment } from 'moment';
import { toDate } from '../../../i18n/date_util';
import * as moment from 'moment';
import { FingerprintCollisionModel } from '../../models/fingerprint-collision.model';
import { FingerprintCollisionGroupModel } from '../../models/fingerprint-collisions-group.model';

declare var jQuery: any;

@Component({
  selector: 'app-receipt-moderate',
  templateUrl: './receipt-moderate.component.html',
  styleUrls: ['./receipt-moderate.component.css']
})
export class ReceiptModerateComponent extends SecurePageBase {
  constants: typeof Constants;
  // Setting the date picker's dataType property to "string" (from "date") so that it serializes as a string and the TimeZone is ignored.
  // Leaving it at "date" sends local time to server and server adjusts to UTC
  datePickerDataType = 'string';

  abortSubscription: Subscription;
  parkSubscription: Subscription;
  saveSubscription: Subscription;
  content: ReceiptContentForModerationModel;
  contentSubscription: Subscription;
  selectedReceiptItem: ReceiptContentForModerationReceiptItemModel;
  isEditingStore = false;
  isAddingReceiptItem = false;
  newReceiptItemText = '';
  validatationErrorMessage = '';
  contentKey: string;
  accountKey: string;
  programKey: string;
  timerName: string;
  parkReason: string;
  isSubmitting = false;
  isTimerWarning = false;
  isTimerComplete = false;
  secondsLeft: number;
  private timerWarningSeconds = Constants.moderationTimeoutWarningSeconds;
  private timerOverrideSeconds: number | null = null;
  private zoomIsEnabled = false;
  private isAbortingModeration = false;

  @ViewChild('saveWarningModal')
  saveWarningModal: ModalDirective;
  saveWarnings: Array<string> = new Array<string>();
  secondPassCollisionCount = 0;
  @ViewChild('secondPassCollisionsWarningModal')
  secondPassCollisionsWarningModal: ModalDirective;
  private lastSubmittedPayload: string;

  constructor(oidc: OidcSecurityService, router: Router, private moderationApi: ModerationApiService, private _route: ActivatedRoute, private _timer: TimerService, private _currentContent: CurrentContentProviderService) {
    super(oidc, router);
    this.constants = Constants;
    this.accountKey = this._route.snapshot.params['accountKey'];
    this.contentKey = this._route.snapshot.params['contentKey'];
    if (_route.snapshot.queryParams['timeout']) {
      this.timerOverrideSeconds = +_route.snapshot.queryParams['timeout'];
    }

    this._route.data.subscribe((data: { content: ReceiptContentForModerationModel }) => {
      const model: ReceiptContentForModerationModel = _currentContent.getCurrentForReceipt();

      model.receipt.fingerprintCollisionGroups.forEach(group => {
        group.collisions.forEach(collision => {
          this.determineOutcomeIndicators(collision);
        });
      });

      this.mergeConsumerData(model);

      this.cleanPurchasedOn();
      this.programKey = model.content.programKey;
      this.content = model;
      this.timerName = model.content.contentKey;
      this.prepareTimer();

      this.content = model;
    });
  }

  private mergeConsumerData(model: ReceiptContentForModerationModel): void {
    if (!model.consumer) {
      return;
    }

    const consumer: ReceiptContentForModerationConsumerModel = model.consumer;

    if (consumer.purchasedOn) {
      model.receipt.purchasedOn = consumer.purchasedOn;
    }

    if (consumer.storeKey) {
      model.receipt.storeKey = consumer.storeKey;
      model.receipt.storeName = consumer.storeName;
    }

    consumer.receiptItems.forEach((item: ReceiptContentForModerationConsumerReceiptItemModel) => {
      const newItem = new ReceiptContentForModerationReceiptItemModel();
      newItem.productKey = item.productKey;
      newItem.productName = item.productName;
      newItem.quantity = item.quantity;
      newItem.total = item.total;
      newItem.index = null;
      newItem.isConsumer = true;
      model.receiptItems.splice(0, 0, newItem);
    });
  }

  onAfterPurchasedOnPickerClosed(): void {
    this.cleanPurchasedOn();
  }

  private cleanPurchasedOn(): void {
    // We do not want to include seconds/ms on the purchased date.

    if (this.content && this.content.receipt && this.content.receipt.purchasedOn) {
      // If it is a string, date it and then wrap it in a moment
      if (typeof this.content.receipt.purchasedOn === 'string') {
        this.content.receipt.purchasedOn = moment(toDate(this.content.receipt.purchasedOn));
      }
      // If it is already a date, wrap it in a moment
      if (this.content.receipt.purchasedOn instanceof Date) {
        this.content.receipt.purchasedOn = moment(this.content.receipt.purchasedOn);
      }
      // Otherwise, it is already a moment

      // @ts-ignore Not sure why this line is complaining about assigning a type, so ignoring it on this line.
      const mom: Moment = this.content.receipt.purchasedOn;
      mom.seconds(0);
      mom.milliseconds(0);
    }
  }

  onNgOnDestroy(): void {
    if (this.contentSubscription) {
      this.contentSubscription.unsubscribe();
    }
    if (this.abortSubscription) {
      this.abortSubscription.unsubscribe();
    }
    if (this.saveSubscription) {
      this.saveSubscription.unsubscribe();
    }
    if (this.parkSubscription) {
      this.parkSubscription.unsubscribe();
    }

    this._timer.delTimer(this.timerName);
  }

  //#region Link Product to Receipt Item
  public selectReceiptItemForEditing(item: ReceiptContentForModerationReceiptItemModel): void {
    this.selectedReceiptItem = item;
  }
  public cancelReceiptItemForEditing(): void {
    this.selectedReceiptItem = null;
  }
  public selectProductForReceiptItem(product: ProductSelectionModel): void {
    if (this.selectedReceiptItem == null) {
      this.newReceiptItemText = null;
      this.isAddingReceiptItem = true;
      this.saveAddingReceiptItem(true);
      this.selectedReceiptItem = this.content.receiptItems[0];
    }
    this.selectedReceiptItem.productKey = product.productKey;
    this.selectedReceiptItem.productName = product.name;
    this.selectedReceiptItem = null;
  }
  public removeProductFromReceiptItem(): void {
    this.selectedReceiptItem.productKey = null;
    this.selectedReceiptItem.productName = null;
    this.selectedReceiptItem = null;
  }
  //#endregion

  //#region Store Selection
  public selectStoreForEditing(): void {
    this.isEditingStore = true;
  }
  public cancelStoreForEditing(): void {
    this.isEditingStore = false;
  }
  public removeStoreFromReceipt(): void {
    this.content.receipt.storeKey = null;
    this.content.receipt.storeName = null;
    this.isEditingStore = false;
  }
  public selectStore(store: StoreSelectionModel) {
    this.content.receipt.storeKey = store.storeKey;
    this.content.receipt.storeName = store.name;
    this.isEditingStore = false;
  }
  //#endregion

  //#region Timer
  private prepareTimer(): void {
    this._timer.delTimer(this.timerName);
    this._timer.newTimer(this.timerName, 1);
    this._timer.subscribe(this.timerName, () => this.timerTick());
  }
  private getTimeoutInSeconds(): number {
    return this.timerOverrideSeconds || this.content.content.timeoutInMinutes * 60;
  }
  private timerTick(): void {
    const assignedOn = new Date(this.content.content.assignedOn);
    const milliseconds = new Date().getTime() - assignedOn.getTime();
    const seconds = milliseconds / 1000;
    const timeoutInSeconds = this.getTimeoutInSeconds();
    this.secondsLeft = timeoutInSeconds - seconds;

    if (this.secondsLeft < 0) {
      this.isTimerWarning = false;
      this.isTimerComplete = true;
      this._timer.delTimer(this.timerName);
      observableInterval(3000).pipe(
        takeWhile(() => !this.isAbortingModeration))
        .subscribe(() => {
          this.abortModeration();
        });
    } else if (this.secondsLeft < this.timerWarningSeconds) {
      this.isTimerWarning = true;
    }
  }
  //#endregion

  onAuthorized(): void {}

  onShouldDisplayOwnUnauthorizedMessage(): boolean {
    return false;
  }

  abortModeration(): void {
    this.isAbortingModeration = true;
    this.isSubmitting = true;
    this._timer.delTimer(this.timerName);

    this.abortSubscription = this.moderationApi.abortModerationOfContent(this.accountKey, this.contentKey).subscribe(() => {
      this.router.navigate(['/program', this.programKey]);
    });
  }

  parkModeration(form: NgForm): void {
    if (form.valid) {
      this.isAbortingModeration = true;
      this.isSubmitting = true;
      this._timer.delTimer(this.timerName);

      this.parkSubscription = this.moderationApi.parkReceiptContent(this.accountKey, this.contentKey, this.parkReason).subscribe(() => {
        this.router.navigate(['/program', this.programKey]);
      });
    }
  }

  saveModeration(): void {
    if (!this.isValid()) {
      return;
    }

    this.determineSaveWarnings();
    if (this.saveWarnings.length > 0) {
      this.saveWarningModal.show();
      return;
    }

    this.confirmSaveModeration();
  }

  confirmSaveModeration() {
    this.saveWarningModal.hide();
    this.isSubmitting = true;
    this._timer.delTimer(this.timerName);

    // Need a clone of content with some fewer properties for comparison
    const clonedContent: ReceiptContentForModerationModel = JSON.parse(JSON.stringify(this.content));
    delete clonedContent.receipt.fingerprintCollisionGroups;
    delete clonedContent.rejectionReasons;
    delete clonedContent.settings;
    delete clonedContent.stores;
    delete clonedContent.products;
    const thisPayload = JSON.stringify(clonedContent);
    let bypassNewSecondPassCollisions = false;
    if (thisPayload === this.lastSubmittedPayload) {
      bypassNewSecondPassCollisions = true;
    }
    this.lastSubmittedPayload = thisPayload;

    this.saveSubscription = this.moderationApi.saveModerationForReceipt(this.accountKey, this.contentKey, this.content, bypassNewSecondPassCollisions).subscribe((result: SaveModeratedReceiptResults) => {
      if (result.didSave) {
        this.next();
      } else {
        this.displaySecondPassWarning(result);
      }
    });
  }

  private displaySecondPassWarning(result: SaveModeratedReceiptResults): any {
    result.fingerprintCollisionGroups.forEach(group => {
      group.collisions.forEach(collision => {
        collision.isSecondPass = true;
        this.determineOutcomeIndicators(collision);
      });
    });

    this.secondPassCollisionCount = 0;
    // Fold those into the existing groups, but remove the previous ones first
    this.purgeCurrentSecondPassCollisions();
    result.fingerprintCollisionGroups.forEach(newGroup => {
      this.secondPassCollisionCount += newGroup.collisions.length;
      const existingGroup = this.content.receipt.fingerprintCollisionGroups.filter(g => g.category === newGroup.category)[0];
      if (existingGroup) {
        newGroup.collisions.forEach(newCollision => {
          existingGroup.collisions.splice(0, 0, newCollision);
        });
      } else {
        this.content.receipt.fingerprintCollisionGroups.push(newGroup);
      }
    });

    this.isSubmitting = false;
    this.secondPassCollisionsWarningModal.show();
  }

  private purgeCurrentSecondPassCollisions(): void {
    this.content.receipt.fingerprintCollisionGroups.forEach(group => {
      this.removeIf(group.collisions, (x: FingerprintCollisionModel) => x.isSecondPass);
    });

    this.removeIf(this.content.receipt.fingerprintCollisionGroups, (x: FingerprintCollisionGroupModel) => x.collisions.length === 0);
  }

  private removeIf(items: any[], predicate: any) {
    let i = items.length;
    while (i--) {
      if (predicate(items[i])) {
        items.splice(i, 1);
      }
    }
  }

  cancelSaveModeration() {
    this.saveWarningModal.hide();
  }
  cancelSecondPassWarning() {
    this.secondPassCollisionsWarningModal.hide();
  }

  confirmSecondPassWarning() {
    this.secondPassCollisionsWarningModal.hide();
    this.confirmSaveModeration();
  }

  private determineSaveWarnings() {
    this.saveWarnings.splice(0, this.saveWarnings.length);

    //#region No Products selected warning
    if (!this.content.receiptItems.find(ri => !!ri.productKey)) {
      this.saveWarnings.push(`No product(s) have been selected`);
    }
    //#endregion

    //#region Fingerprint warnings
    if (this.content.receipt.fingerprintCollisionGroups.length > 0) {
      let collisionCount = 0;

      this.content.receipt.fingerprintCollisionGroups.forEach(grp => {
        collisionCount += grp.collisions.length;
      });

      this.saveWarnings.push(`There are ${collisionCount} fingerprint collisions`);
    }
    //#endregion

    //#region Quantity/Total max limit warnings
    let totalProductQuantity = 0;
    let totalProductTotal = 0;

    this.content.receiptItems.forEach(r => {
      if (r.productKey) {
        if (r.quantity) {
          totalProductQuantity += r.quantity;
        }

        if (r.total) {
          totalProductTotal += r.total;
        }
      }
    });

    // This will be configurable some day
    const maxQuantity = 20;
    const maxTotal = this.content.settings.receiptTotalWarningThreshold;

    if (this.content.settings.showQuantity && totalProductQuantity > maxQuantity) {
      this.saveWarnings.push(`The total Quantity of ${totalProductQuantity} exceeds the warning limit of ${maxQuantity}`);
    }

    if (this.content.settings.showTotal) {
      if (totalProductTotal > maxTotal) {
        this.saveWarnings.push(`The total Price of ${totalProductTotal.toFixed(2)} exceeds the warning limit of ${maxTotal.toFixed(2)}`);
      }

      if (this.content.receipt.total > maxTotal) {
        this.saveWarnings.push(`The Receipt Total of ${this.content.receipt.total.toFixed(2)} exceeds the warning limit of ${maxTotal.toFixed(2)}`);
      }
    }

    //#endregion
  }

  private isValid(): Boolean {
    if (this.content.settings.requirePurchasedOn && !this.content.receipt.purchasedOn) {
      this.validatationErrorMessage = `Purchased On Date is required`;
      return false;
    }

    if (this.content.settings.requireStore && !this.content.receipt.storeKey) {
      this.validatationErrorMessage = `Retailer is required`;
      return false;
    }

    if (this.content.settings.isStoreNumberCaptured && this.content.settings.isStoreNumberRequired && !this.content.receipt.storeNumber) {
      this.validatationErrorMessage = 'Store Number is required for this item';
      return false;
    }

    for (let i = 0; i < this.content.receiptItems.length; i++) {
      const item = this.content.receiptItems[i];

      // Newly added receipt items checks
      if (item.index === null && !item.isConsumer) {
        if (!item.productKey) {
          this.validatationErrorMessage = `The newly added item "${item.text}" must be linked to a product.`;
          return false;
        }
      }

      // Global checks
      if (this.content.settings.requireQuantity && item.productKey && item.quantity <= 0) {
        this.validatationErrorMessage = `The item "${item.text}" for product "${item.productName}" must have a valid quantity.`;
        return false;
      }
      if (this.content.settings.requireTotal && item.productKey && item.total <= 0) {
        this.validatationErrorMessage = `The item "${item.text}" for product "${item.productName}" must have a valid price.`;
        return false;
      }
    }

    this.validatationErrorMessage = '';
    return true;
  }

  cannotModerate(outcome: ModerationOutcomeModel): void {
    this.isSubmitting = true;
    this._timer.delTimer(this.timerName);

    this.saveSubscription = this.moderationApi.saveCannotModerate(this.accountKey, this.contentKey, outcome.value).subscribe(() => {
      this.next();
    });
  }

  private next(): void {
    this.router.navigate(['/content', 'next', 'program', this.programKey]);
  }

  private determineOutcomeIndicators(item: FingerprintCollisionModel): void {
    switch (item.moderationOutcome) {
      case Constants.moderationOutcomeSuccessful:
        item.outcomeIndicatorText = 'P';
        item.outcomeIndicatorBadgeClass = 'badge-success';
        item.outcomeIndicatorTooltip = 'Processed';
        break;
      case Constants.moderationOutcomeDuplicateImage:
      case Constants.moderationOutcomeDuplicateImageHuman:
        item.outcomeIndicatorText = 'D';
        item.outcomeIndicatorBadgeClass = 'badge-dark';
        item.outcomeIndicatorTooltip = 'Duplicate Receipt';
        break;
      default:
        if (item.moderationOutcome) {
          item.outcomeIndicatorText = 'R';
          item.outcomeIndicatorBadgeClass = 'badge-danger';
          item.outcomeIndicatorTooltip = `Rejected - ${item.moderationOutcome}`;
        } else {
          // not moderated yet
          item.outcomeIndicatorText = 'Q';
          item.outcomeIndicatorBadgeClass = 'badge-secondary';
          item.outcomeIndicatorTooltip = 'Queued';
        }
        break;
    }

    item.outcomeIndicatorClasses = {
      badge: true,
      'ml-1': true
    };

    item.outcomeIndicatorClasses[item.outcomeIndicatorBadgeClass] = true;
  }

  public performQuickAction(action: ReceiptContentForModerationQuickActionModel): void {
    if (action.storeKey) {
      const matchingStore = this.content.stores.filter(s => s.storeKey === action.storeKey)[0];
      if (matchingStore) {
        this.content.receipt.storeKey = matchingStore.storeKey;
        this.content.receipt.storeName = matchingStore.name;
      }
    }

    if (action.storeNumber) {
      this.content.receipt.storeNumber = action.storeNumber;
    }

    if (action.purchasedOn) {
      this.content.receipt.purchasedOn = action.purchasedOn;
      this.cleanPurchasedOn();
    }

    if (action.products && action.products.length > 0) {
      action.products.forEach(product => {
        const matchingProduct = this.content.products.filter(p => p.productKey === product.productKey)[0];
        if (matchingProduct) {
          this.newReceiptItemText = '';
          this.saveAddingReceiptItem(true);
          const newItem = this.content.receiptItems[0];
          newItem.quantity = product.quantity;
          newItem.total = product.total;
          newItem.productKey = matchingProduct.productKey;
          newItem.productName = matchingProduct.name;
        }
      });
    }

    if (action.total) {
      this.content.receipt.total = action.total;
    }

    action.performed = true;
  }

  //#region Add Receipt Item
  startAddingReceiptItem(): void {
    this.newReceiptItemText = '';
    this.isAddingReceiptItem = true;
  }
  abortAddingReceiptItem(): void {
    this.isAddingReceiptItem = false;
  }
  saveAddingReceiptItem(insertAtTop = false): void {
    const newItem = new ReceiptContentForModerationReceiptItemModel();
    newItem.text = this.newReceiptItemText;
    newItem.index = null;
    newItem.quantity = 1;
    newItem.total = null;
    newItem.isManuallyAdded = true;

    if (insertAtTop) {
      this.content.receiptItems.splice(0, 0, newItem);
    } else {
      this.content.receiptItems.push(newItem);
    }

    this.newReceiptItemText = '';

    this.isAddingReceiptItem = false;
  }
  removeAddedReceiptItem(item: ReceiptContentForModerationReceiptItemModel): void {
    const index = this.content.receiptItems.indexOf(item);
    this.content.receiptItems.splice(index, 1);
  }
  //#endregion
}
