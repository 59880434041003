import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { SecurePageBase } from '../secure-page-base';
import { ModerationApiService } from '../../apis/moderation-api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Component, OnInit } from '@angular/core';
import { NextContentForModerationModel } from '../../models/moderation-models';
import { RuleSetType } from '../../models/rule-set-type.enum';

@Component({
  selector: 'app-next-available',
  templateUrl: './next-available.component.html',
  styleUrls: ['./next-available.component.css']
})
export class NextAvailableComponent extends SecurePageBase {
  nextSubscription: Subscription;
  noItemsAvailable: Boolean = false;
  programKey: string;

  constructor(oidc: OidcSecurityService, router: Router, private moderationApi: ModerationApiService, private _route: ActivatedRoute) {
    super(oidc, router);
    this.programKey = _route.snapshot.params['programKey'];
  }

  onNgOnDestroy(): void {
    if (this.nextSubscription) {
      this.nextSubscription.unsubscribe();
    }
  }

  private getNextAvailableContent(): void {
    this.nextSubscription = this.moderationApi.getNextAvailableContent(this.programKey).subscribe(
      (data: NextContentForModerationModel) => {
        this.sendTo(data);
      },
      (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.noItemsAvailable = true;
        }
      }
    );
  }

  onAuthorized(): void {
    this.getNextAvailableContent();
  }

  onShouldDisplayOwnUnauthorizedMessage(): boolean {
    return false;
  }

  sendTo(next: NextContentForModerationModel): void {
    const typeRoute = next.contentModerationType === RuleSetType.UserGenerated ? 'user-generated' : 'receipt';
    this.router.navigate(['/content', typeRoute, next.accountKey, next.contentKey]);
  }
}
