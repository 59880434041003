import { Component, OnInit } from '@angular/core';
import { SecurePageBase } from '../secure-page-base';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router, ActivatedRoute } from '@angular/router';
import { ModerationApiService } from '../../apis/moderation-api.service';
import { Subscription } from 'rxjs';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { UserGeneratedContentForExamineModel } from '../../models/user-generated-content-for-examine.model';
import { ContentForExamineRuleSetModel } from '../../models/content-for-examine-rule-set.model';

@Component({
  selector: 'app-user-generated-examine-detail',
  templateUrl: './user-generated-examine-detail.component.html',
  styleUrls: ['./user-generated-examine-detail.component.css']
})
export class UserGeneratedExamineDetailComponent extends SecurePageBase {
  contentKey: string;
  accountKey: string;
  content: UserGeneratedContentForExamineModel;
  contentSubscription: Subscription;
  showPassingRuleSets = true;
  showFailingRuleSets = true;
  private zoomIsEnabled = false;
  error: ErrorObservable<any>;

  constructor(oidc: OidcSecurityService, router: Router, private moderationApi: ModerationApiService, private _route: ActivatedRoute) {
    super(oidc, router);
    this.accountKey = this._route.snapshot.params['accountKey'];
    this.contentKey = this._route.snapshot.params['contentKey'];
  }

  onAuthorized(): void {
    this.populateContent();
  }

  onNgOnDestroy(): void {
  }

  onShouldDisplayOwnUnauthorizedMessage(): boolean {
    return false;
  }

  toggleRules(ruleSetItem: ContentForExamineRuleSetModel): void {
    ruleSetItem.showRules = !ruleSetItem.showRules;
  }

  private populateContent(): void {
    this.contentSubscription = this.moderationApi
      .getUserGeneratedContentForExamine(this.accountKey, this.contentKey)
      .subscribe((model: UserGeneratedContentForExamineModel) => {
        this.content = model;

        // For User Generated Content, there is likely far fewer rules, so will expect rule sets by default
        this.content.ruleSets.forEach(r => r.showRules = true);

        this.contentSubscription.unsubscribe();
      },
      (error: ErrorObservable<any>) => {
        this.error = error;
      });
  }


}
