import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router';
import { ReceiptContentForExamineModel } from '../models/moderation-models';
import { ModerationApiService } from '../apis/moderation-api.service';
import { CurrentContentProviderService } from '../services/current-content-provider.service';
import { Observable, timer } from 'rxjs';
import { take, map, catchError } from 'rxjs/operators';
import { FingerprintCollisionModel } from '../models/fingerprint-collision.model';
import { Constants } from '../constants';

@Injectable()
export class ReceiptExamineContentResolver implements Resolve<ReceiptContentForExamineModel> {
  constructor(private _api: ModerationApiService, private _router: Router, private _currentContent: CurrentContentProviderService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ReceiptContentForExamineModel> {
    const accountKey = route.paramMap.get('accountKey') || route.parent.paramMap.get('accountKey');
    const contentKey = route.paramMap.get('contentKey') || route.parent.paramMap.get('contentKey');

    if (this._currentContent.isCurrentExamine(accountKey, contentKey)) {
      return timer(0).pipe(
        map(() => {
          return null;
        })
      );
    }

    return this._api.getReceiptContentForExamine(accountKey, contentKey).pipe(
      take(1),
      catchError((err: any, caught: Observable<ReceiptContentForExamineModel>) => {
        if (err.status === 401) {
          this._router.navigate(['/sessionexpired']);
          return null;
        } else {
          this._router.navigate(['/content', 'examine', 'receipt', accountKey, contentKey, 'not-found']);
          return null;
        }
      }),
      map((model: ReceiptContentForExamineModel) => {
        if (model) {
          // prepare the model as a view model with some defaults
          model.receiptItems.forEach(ri => (ri.quantity = ri.quantity || 1));
          if (model.receipt.fingerprintCollisionGroups && model.receipt.fingerprintCollisionGroups.length > 0) {
            let groups = model.receipt.fingerprintCollisionGroups;
            const shaGroup = groups.find(g => g.category === 'Binary');
            if (shaGroup) {
              groups = groups.splice(groups.indexOf(shaGroup), 1);
            }
          }

          if (model.receipt && model.receipt.fingerprintCollisionGroups) {
            model.receipt.fingerprintCollisionGroups.forEach(group => {
              group.collisions.forEach(collision => {
                this.determineOutcomeIndicators(collision);
              });
            });
          }

          this._currentContent.setCurrentExamine(model);

          return null;
        } else {
          this._currentContent.clearExamine();

          this._router.navigate(['/content', 'receipt', accountKey, contentKey, 'not-found']);
          return null;
        }
      })
    );
  }

  private determineOutcomeIndicators(item: FingerprintCollisionModel): void {
    switch (item.moderationOutcome) {
      case Constants.moderationOutcomeSuccessful:
        item.outcomeIndicatorText = 'P';
        item.outcomeIndicatorBadgeClass = 'badge-success';
        item.outcomeIndicatorTooltip = 'Processed';
        break;
      case Constants.moderationOutcomeDuplicateImage:
      case Constants.moderationOutcomeDuplicateImageHuman:
        item.outcomeIndicatorText = 'D';
        item.outcomeIndicatorBadgeClass = 'badge-dark';
        item.outcomeIndicatorTooltip = 'Duplicate Receipt';
        break;
      default:
        if (item.moderationOutcome) {
          item.outcomeIndicatorText = 'R';
          item.outcomeIndicatorBadgeClass = 'badge-danger';
          item.outcomeIndicatorTooltip = `Rejected - ${item.moderationOutcome}`;
        } else {
          // not moderated yet
          item.outcomeIndicatorText = 'Q';
          item.outcomeIndicatorBadgeClass = 'badge-secondary';
          item.outcomeIndicatorTooltip = 'Queued';
        }
        break;
    }

    item.outcomeIndicatorClasses = {
      badge: true
    };
    item.outcomeIndicatorClasses[item.outcomeIndicatorBadgeClass] = true;
  }
}
