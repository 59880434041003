import { environment } from '../../environments/environment';
var BaseApi = /** @class */ (function () {
    function BaseApi() {
        this.urlRoot = environment.urls.moderationApi;
        while (this.urlRoot.endsWith('/')) {
            this.urlRoot = this.urlRoot.substr(0, this.urlRoot.length - 1);
        }
    }
    BaseApi.prototype.urlFor = function (path) {
        return "" + this.urlRoot + path;
    };
    return BaseApi;
}());
export { BaseApi };
