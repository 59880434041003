import { Injectable, Injector, EventEmitter, Output } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import * as HttpStatus from 'http-status-codes';
import { UnauthorizedWatcherService } from './unauthorized-watcher.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private oidcSecurityService: OidcSecurityService;

  constructor(
    private injector: Injector,
    private router: Router,
    private unauthorizedWatcherService: UnauthorizedWatcherService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log(req);
    // Force all requests through https
    if (req.url.startsWith('http:')) {
      req = req.clone({ url: req.url.replace('http:', 'https:') });
    }
    let requestToForward = req;

    if (this.oidcSecurityService === undefined) {
      this.oidcSecurityService = this.injector.get(OidcSecurityService);
    }
    if (this.oidcSecurityService !== undefined) {
      const token = this.oidcSecurityService.getToken();
      if (token !== '') {
        const tokenValue = 'Bearer ' + token;
        requestToForward = req.clone({ setHeaders: { 'Authorization': tokenValue } });
      }
    } else {
      console.debug('OidcSecurityService undefined: NO auth header!');
    }

    return next.handle(requestToForward)
      .pipe(
        tap(
          () => { }, // Do nothing on success
          error => {
            if (error instanceof HttpErrorResponse && error.status === HttpStatus.UNAUTHORIZED) {
              console.debug('Response UNAUTHORIZED, redirecting to logged off page.');
              this.unauthorizedWatcherService.emitUnauthorizedEvent();
              this.router.navigateByUrl('/sessionexpired');
            }
          }
        )
      );
  }
}
