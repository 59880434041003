import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sessionexpired',
  templateUrl: './sessionexpired.component.html',
  styleUrls: ['./sessionexpired.component.css']
})
export class SessionExpiredComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
