var VersionModel = /** @class */ (function () {
    function VersionModel() {
    }
    return VersionModel;
}());
export { VersionModel };
var ModerationOutcomeModel = /** @class */ (function () {
    function ModerationOutcomeModel() {
    }
    return ModerationOutcomeModel;
}());
export { ModerationOutcomeModel };
var RejectionReasonModel = /** @class */ (function () {
    function RejectionReasonModel() {
    }
    return RejectionReasonModel;
}());
export { RejectionReasonModel };
var NextContentForModerationModel = /** @class */ (function () {
    function NextContentForModerationModel() {
    }
    return NextContentForModerationModel;
}());
export { NextContentForModerationModel };
//#region ReceiptContentForModeration
var ReceiptContentForModerationQuickActionModel = /** @class */ (function () {
    function ReceiptContentForModerationQuickActionModel() {
    }
    return ReceiptContentForModerationQuickActionModel;
}());
export { ReceiptContentForModerationQuickActionModel };
var ReceiptContentForModerationQuickActionProductModel = /** @class */ (function () {
    function ReceiptContentForModerationQuickActionProductModel() {
    }
    return ReceiptContentForModerationQuickActionProductModel;
}());
export { ReceiptContentForModerationQuickActionProductModel };
var ReceiptContentForModerationSettingsModel = /** @class */ (function () {
    function ReceiptContentForModerationSettingsModel() {
    }
    return ReceiptContentForModerationSettingsModel;
}());
export { ReceiptContentForModerationSettingsModel };
var ReceiptContentForModerationContentModel = /** @class */ (function () {
    function ReceiptContentForModerationContentModel() {
    }
    return ReceiptContentForModerationContentModel;
}());
export { ReceiptContentForModerationContentModel };
var ReceiptContentForModerationReceiptItemModel = /** @class */ (function () {
    function ReceiptContentForModerationReceiptItemModel() {
        // View Model
        this.isConsumer = false;
        this.isManuallyAdded = false;
    }
    return ReceiptContentForModerationReceiptItemModel;
}());
export { ReceiptContentForModerationReceiptItemModel };
var ReceiptContentForModerationFingerprintModel = /** @class */ (function () {
    function ReceiptContentForModerationFingerprintModel() {
    }
    return ReceiptContentForModerationFingerprintModel;
}());
export { ReceiptContentForModerationFingerprintModel };
var ReceiptContentForModerationReceiptModel = /** @class */ (function () {
    function ReceiptContentForModerationReceiptModel() {
    }
    return ReceiptContentForModerationReceiptModel;
}());
export { ReceiptContentForModerationReceiptModel };
var ReceiptContentForModerationReviewItemModel = /** @class */ (function () {
    function ReceiptContentForModerationReviewItemModel() {
    }
    return ReceiptContentForModerationReviewItemModel;
}());
export { ReceiptContentForModerationReviewItemModel };
var ReceiptContentForModerationReviewModel = /** @class */ (function () {
    function ReceiptContentForModerationReviewModel() {
    }
    return ReceiptContentForModerationReviewModel;
}());
export { ReceiptContentForModerationReviewModel };
var ReceiptContentForModerationConsumerModel = /** @class */ (function () {
    function ReceiptContentForModerationConsumerModel() {
    }
    return ReceiptContentForModerationConsumerModel;
}());
export { ReceiptContentForModerationConsumerModel };
var ReceiptContentForModerationConsumerReceiptItemModel = /** @class */ (function () {
    function ReceiptContentForModerationConsumerReceiptItemModel() {
    }
    return ReceiptContentForModerationConsumerReceiptItemModel;
}());
export { ReceiptContentForModerationConsumerReceiptItemModel };
var ReceiptContentForModerationModel = /** @class */ (function () {
    function ReceiptContentForModerationModel() {
    }
    return ReceiptContentForModerationModel;
}());
export { ReceiptContentForModerationModel };
//#endregion
//#region ReceiptContentForReview
var ReceiptContentForExamineReceiptItemModel = /** @class */ (function () {
    function ReceiptContentForExamineReceiptItemModel() {
    }
    return ReceiptContentForExamineReceiptItemModel;
}());
export { ReceiptContentForExamineReceiptItemModel };
var ReceiptContentForExamineReceiptModel = /** @class */ (function () {
    function ReceiptContentForExamineReceiptModel() {
    }
    return ReceiptContentForExamineReceiptModel;
}());
export { ReceiptContentForExamineReceiptModel };
var ReceiptContentForExamineModel = /** @class */ (function () {
    function ReceiptContentForExamineModel() {
    }
    return ReceiptContentForExamineModel;
}());
export { ReceiptContentForExamineModel };
//#endregion
var ProductSelectionModel = /** @class */ (function () {
    function ProductSelectionModel() {
    }
    return ProductSelectionModel;
}());
export { ProductSelectionModel };
var StoreSelectionModel = /** @class */ (function () {
    function StoreSelectionModel() {
    }
    return StoreSelectionModel;
}());
export { StoreSelectionModel };
