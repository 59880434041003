<div class="container-full-width">
  <div *ngIf="isTimerWarning" class="alert alert-danger">
    <strong>Time is almost up.</strong> You have approximately 1 minute left to finish this item. It will be placed back into the
    queue after this.  <span>{{ secondsLeft | number:'1.0-0' }} seconds</span>
  </div>
  <div *ngIf="isTimerComplete" class="alert alert-danger">
    <strong>Time is up.</strong> This item is being placed back into the queue.
  </div>
  <div class="alert" role="alert">
    <a [routerLink]="['/content', 'receipt', content.content.accountKey, content.content.contentKey, 'review']" class="btn btn-info">Close (Esc)</a>
  </div>
  <div class="row">
    <div class="col-6">
      <app-resource-viewer [url]="content.content.resourceUrl" [mediaType]="content.content.resourceMediaType"></app-resource-viewer>
    </div>
    <div class="col-6">
      <app-resource-viewer [url]="compare.resourceUrl" [mediaType]="compare.resourceMediaType"></app-resource-viewer>
    </div>
  </div>
</div>
