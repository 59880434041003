<div class="container-full-width">
  <div *ngIf="isTimerWarning" class="alert alert-danger">
    <strong>Time is almost up.</strong> You have approximately 1 minute left to finish this item. It will be placed back into the queue after this. <span>{{ secondsLeft | number: '1.0-0' }} seconds</span>
  </div>
  <div *ngIf="isTimerComplete" class="alert alert-danger"><strong>Time is up.</strong> This item is being placed back into the queue.</div>
  <div class="alert alert-info" role="alert">
    <h4>Duplicates for "{{ categoryDescription }}"</h4>
    <hr />
    <a [routerLink]="['/content', 'receipt', content.content.accountKey, content.content.contentKey]" class="btn btn-outline-info">Continue moderating this receipt</a>
  </div>
  <div class="row">
    <div class="col-4">
      <h5>Uploaded: {{ content.content.createdOn | date: 'medium' }}</h5>
      <div style="width:440px;height:600px;">
        <app-resource-viewer [url]="content.content.resourceUrl" [mediaType]="content.content.resourceMediaType"></app-resource-viewer>
      </div>
    </div>
    <div class="col-8" *ngIf="!!collisions">
      <div class="card w-25 float-left" *ngFor="let collision of collisions">
        <h5 class="card-title" [title]="collision.contentKey">Uploaded: {{ collision.createdOn | date: 'medium' }}</h5>
        <div style="width:320px;height:480px;">
          <app-resource-viewer [url]="collision.resourceUrl" [mediaType]="collision.resourceMediaType"></app-resource-viewer>
        </div>
        <div class="card-body">
          <span class="badge-lg" [ngClass]="collision.outcomeIndicatorClasses">{{ collision.outcomeIndicatorText }}</span>
          <i *ngIf="content.content.participantKey === collision.participantKey" class="fa fa-2x fa-user ml-2" title="Same Participant"></i>
          <i *ngIf="content.content.participantKey !== collision.participantKey" class="spacer ml-2"></i>
        </div>
      </div>
    </div>
    <div class="col-8" *ngIf="!collisions">
      <div class="alert alert-danger">No duplicates found for this category. This should not happen, which means you probably landed on this page by mistake.</div>
    </div>
  </div>
</div>
