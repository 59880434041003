import { Component, OnInit, Input } from '@angular/core';
import { FileIdentificationService, FileIdentificationModel } from '../../services/file-identification.service';

@Component({
  selector: 'app-resource-thumbnail-viewer',
  templateUrl: './resource-thumbnail-viewer.component.html'
})
export class ResourceThumbnailViewerComponent implements OnInit {
  @Input()
  public url: string;
  @Input()
  public mediaType: string;

  public file: FileIdentificationModel;

  public ngxImages = new Array<string>();
  public ngxImageConfig: any = {
    allowFullscreen: true
  };

  constructor(private fileIdentifier: FileIdentificationService) {}

  ngOnInit() {
    this.file = this.fileIdentifier.identify(this.url, this.mediaType);

    if (this.file.isImage) {
      this.ngxImages.push(this.url);
    }
  }
}
