var CurrentContentProviderService = /** @class */ (function () {
    function CurrentContentProviderService() {
    }
    CurrentContentProviderService.prototype.setCurrent = function (model) {
        this._model = model;
    };
    CurrentContentProviderService.prototype.setCurrentExamine = function (model) {
        this._examineModel = model;
    };
    CurrentContentProviderService.prototype.getCurrentForReceipt = function () {
        return this._model;
    };
    CurrentContentProviderService.prototype.getCurrentForUserGenerated = function () {
        return this._model;
    };
    CurrentContentProviderService.prototype.getCurrentExamineForReceipt = function () {
        return this._examineModel;
    };
    CurrentContentProviderService.prototype.getCurrentExamineForUserGenerated = function () {
        return this._examineModel;
    };
    CurrentContentProviderService.prototype.isCurrent = function (accountKey, contentKey) {
        if (this._model && this._model.content.accountKey === accountKey && this._model.content.contentKey === contentKey) {
            return true;
        }
        return false;
    };
    CurrentContentProviderService.prototype.isCurrentExamine = function (accountKey, contentKey) {
        if (this._examineModel && this._examineModel.content.accountKey === accountKey && this._examineModel.content.contentKey === contentKey) {
            return true;
        }
        return false;
    };
    CurrentContentProviderService.prototype.clear = function () {
        this._model = null;
    };
    CurrentContentProviderService.prototype.clearExamine = function () {
        this._examineModel = null;
    };
    return CurrentContentProviderService;
}());
export { CurrentContentProviderService };
