import * as tslib_1 from "tslib";
import { Constants } from '../../constants';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { SecurePageBase } from '../secure-page-base';
import { Router, ActivatedRoute } from '@angular/router';
import { ModerationApiService } from '../../apis/moderation-api.service';
import { TimerService } from '../../services/timer-service';
import { CurrentContentProviderService } from '../../services/current-content-provider.service';
import { Subscription, interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
var UserGeneratedModerateComponent = /** @class */ (function (_super) {
    tslib_1.__extends(UserGeneratedModerateComponent, _super);
    function UserGeneratedModerateComponent(oidc, router, moderationApi, _route, _timer, _currentContent) {
        var _this = _super.call(this, oidc, router) || this;
        _this.moderationApi = moderationApi;
        _this._route = _route;
        _this._timer = _timer;
        _this._currentContent = _currentContent;
        _this.isAbortingModeration = false;
        _this.isSubmitting = false;
        _this.isTimerWarning = false;
        _this.isTimerComplete = false;
        _this.timerWarningSeconds = Constants.moderationTimeoutWarningSeconds;
        _this.timerOverrideSeconds = null;
        _this.validatationErrorMessage = '';
        _this.savingErrorMessage = '';
        _this.constants = Constants;
        _this.accountKey = _this._route.snapshot.params['accountKey'];
        _this.contentKey = _this._route.snapshot.params['contentKey'];
        if (_route.snapshot.queryParams['timeout']) {
            _this.timerOverrideSeconds = +_route.snapshot.queryParams['timeout'];
        }
        _this._route.data.subscribe(function (data) {
            var model = _currentContent.getCurrentForUserGenerated();
            _this.programKey = model.content.programKey;
            _this.content = model;
            _this.timerName = model.content.contentKey;
            _this.prepareTimer();
        });
        return _this;
    }
    UserGeneratedModerateComponent.prototype.onNgOnDestroy = function () {
        if (this.contentSubscription) {
            this.contentSubscription.unsubscribe();
        }
        if (this.abortSubscription) {
            this.abortSubscription.unsubscribe();
        }
        if (this.saveSubscription) {
            this.saveSubscription.unsubscribe();
        }
        if (this.parkSubscription) {
            this.parkSubscription.unsubscribe();
        }
        this._timer.delTimer(this.timerName);
    };
    //#region Timer
    UserGeneratedModerateComponent.prototype.prepareTimer = function () {
        var _this = this;
        this._timer.delTimer(this.timerName);
        this._timer.newTimer(this.timerName, 1);
        this._timer.subscribe(this.timerName, function () { return _this.timerTick(); });
    };
    UserGeneratedModerateComponent.prototype.getTimeoutInSeconds = function () {
        return this.timerOverrideSeconds || this.content.content.timeoutInMinutes * 60;
    };
    UserGeneratedModerateComponent.prototype.timerTick = function () {
        var _this = this;
        var assignedOn = new Date(this.content.content.assignedOn);
        var milliseconds = new Date().getTime() - assignedOn.getTime();
        var seconds = milliseconds / 1000;
        var timeoutInSeconds = this.getTimeoutInSeconds();
        this.secondsLeft = timeoutInSeconds - seconds;
        if (this.secondsLeft < 0) {
            this.isTimerWarning = false;
            this.isTimerComplete = true;
            this._timer.delTimer(this.timerName);
            interval(3000)
                .pipe(takeWhile(function () { return !_this.isAbortingModeration; }))
                .subscribe(function () {
                _this.abortModeration();
            });
        }
        else if (this.secondsLeft < this.timerWarningSeconds) {
            this.isTimerWarning = true;
        }
    };
    //#endregion
    UserGeneratedModerateComponent.prototype.onAuthorized = function () { };
    UserGeneratedModerateComponent.prototype.onShouldDisplayOwnUnauthorizedMessage = function () {
        return false;
    };
    UserGeneratedModerateComponent.prototype.abortModeration = function () {
        var _this = this;
        this.isAbortingModeration = true;
        this.isSubmitting = true;
        this._timer.delTimer(this.timerName);
        this.abortSubscription = this.moderationApi.abortModerationOfContent(this.accountKey, this.contentKey).subscribe(function () {
            _this.router.navigate(['/program', _this.programKey]);
        });
    };
    UserGeneratedModerateComponent.prototype.parkModeration = function (form) {
        var _this = this;
        if (form.valid) {
            this.isAbortingModeration = true;
            this.isSubmitting = true;
            this._timer.delTimer(this.timerName);
            this.parkSubscription = this.moderationApi.parkReceiptContent(this.accountKey, this.contentKey, this.parkReason).subscribe(function () {
                _this.router.navigate(['/program', _this.programKey]);
            });
        }
    };
    UserGeneratedModerateComponent.prototype.saveModeration = function () {
        if (!this.isValid()) {
            return;
        }
        this.confirmSaveModeration();
    };
    UserGeneratedModerateComponent.prototype.confirmSaveModeration = function () {
        var _this = this;
        this.isSubmitting = true;
        this._timer.delTimer(this.timerName);
        this.saveSubscription = this.moderationApi.saveModerationForUserGenerated(this.accountKey, this.contentKey, this.content).subscribe(function (result) {
            if (result.didSave) {
                _this.next();
            }
            else {
                // This should never happen, but just in case...
                _this.savingErrorMessage = 'An unknown error occurred saving this data. Please refresh and try again.';
            }
        });
    };
    UserGeneratedModerateComponent.prototype.cannotModerate = function (outcome) {
        var _this = this;
        this.isSubmitting = true;
        this._timer.delTimer(this.timerName);
        this.saveSubscription = this.moderationApi.saveCannotModerate(this.accountKey, this.contentKey, outcome.value).subscribe(function () {
            _this.next();
        });
    };
    UserGeneratedModerateComponent.prototype.isValid = function () {
        if (this.content.rules.filter(function (rule) { return rule.booleanValue === undefined || rule.booleanValue === null; }).length > 0) {
            this.validatationErrorMessage = 'All questions need to be answered.';
            return false;
        }
        this.validatationErrorMessage = '';
        return true;
    };
    UserGeneratedModerateComponent.prototype.next = function () {
        this.router.navigate(['/content', 'next', 'program', this.programKey]);
    };
    return UserGeneratedModerateComponent;
}(SecurePageBase));
export { UserGeneratedModerateComponent };
