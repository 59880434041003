import { Component, OnInit } from '@angular/core';
import { ReceiptContentForExamineModel } from '../../models/moderation-models';
import { SecurePageBase } from '../secure-page-base';
import { Constants } from '../../constants';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router, ActivatedRoute } from '@angular/router';
import { CurrentContentProviderService } from '../../services/current-content-provider.service';
import { FingerprintCollisionModel } from '../../models/fingerprint-collision.model';
import { toDate } from '../../../i18n/date_util';

@Component({
  selector: 'app-receipt-examine-duplicates',
  templateUrl: './receipt-examine-duplicates.component.html',
  styleUrls: ['./receipt-examine-duplicates.component.css']
})
export class ReceiptExamineDuplicatesComponent extends SecurePageBase {
  constants: typeof Constants;
  content: ReceiptContentForExamineModel;
  category: string;
  categoryDescription: string;
  collisions: Array<FingerprintCollisionModel>;

  constructor(oidc: OidcSecurityService, router: Router, route: ActivatedRoute, currentContent: CurrentContentProviderService) {
    super(oidc, router);
    this.constants = Constants;
    this.category = route.snapshot.params['category'];
    this.categoryDescription = route.snapshot.queryParams['categoryDescription'];

    route.data.subscribe((data: { content: ReceiptContentForExamineModel }) => {
      const model = currentContent.getCurrentExamineForReceipt();

      const fingerprintGroup = model.receipt.fingerprintCollisionGroups.filter(x => x.category === this.category)[0];
      if (fingerprintGroup) {
        // Show them latest on top like the Review screen does
        this.collisions = fingerprintGroup.collisions.sort((a, b) => toDate(b.createdOn).getDate() - toDate(a.createdOn).getDate());
      }

      this.content = model;
    });
  }

  onAuthorized(): void {}

  onNgOnDestroy(): void {}

  onShouldDisplayOwnUnauthorizedMessage(): boolean {
    return false;
  }
}
