<span *ngIf="file.isPDF" class="pdf-thumbnail">
  <a [href]="url" target="_blank">PDF</a>
</span>
<span *ngIf="file.isVideo" class="pdf-thumbnail">
  <a [href]="url" target="_blank">Video</a>
</span>
<img *ngIf="file.isImage" [src]="url" height="64" width="64" />
<span *ngIf="file.isUnknown" class="pdf-thumbnail">
  <a [href]="url" target="_blank">{{ file.extensionDisplay }}</a>
</span>
