/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sessionexpired.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./sessionexpired.component";
var styles_SessionExpiredComponent = [i0.styles];
var RenderType_SessionExpiredComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SessionExpiredComponent, data: {} });
export { RenderType_SessionExpiredComponent as RenderType_SessionExpiredComponent };
export function View_SessionExpiredComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "center-stage"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Session Expired"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "lead"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your session has expired. Log on again to re-enter the Station."]))], null, null); }
export function View_SessionExpiredComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sessionexpired", [], null, null, null, View_SessionExpiredComponent_0, RenderType_SessionExpiredComponent)), i1.ɵdid(1, 114688, null, 0, i2.SessionExpiredComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SessionExpiredComponentNgFactory = i1.ɵccf("app-sessionexpired", i2.SessionExpiredComponent, View_SessionExpiredComponent_Host_0, {}, {}, []);
export { SessionExpiredComponentNgFactory as SessionExpiredComponentNgFactory };
