var DashboardModelItem = /** @class */ (function () {
    function DashboardModelItem() {
    }
    return DashboardModelItem;
}());
export { DashboardModelItem };
var DashboardModel = /** @class */ (function () {
    function DashboardModel() {
    }
    return DashboardModel;
}());
export { DashboardModel };
var DashboardSearchModel = /** @class */ (function () {
    function DashboardSearchModel() {
    }
    return DashboardSearchModel;
}());
export { DashboardSearchModel };
var ProgramDashboardModel = /** @class */ (function () {
    function ProgramDashboardModel() {
    }
    return ProgramDashboardModel;
}());
export { ProgramDashboardModel };
var ProgramAnalyticsReportResultsModel = /** @class */ (function () {
    function ProgramAnalyticsReportResultsModel() {
    }
    return ProgramAnalyticsReportResultsModel;
}());
export { ProgramAnalyticsReportResultsModel };
var AnalyticsReportDefinition = /** @class */ (function () {
    function AnalyticsReportDefinition() {
    }
    return AnalyticsReportDefinition;
}());
export { AnalyticsReportDefinition };
var AnalyticsExtractModel = /** @class */ (function () {
    function AnalyticsExtractModel() {
    }
    return AnalyticsExtractModel;
}());
export { AnalyticsExtractModel };
var AvailableContentItemModel = /** @class */ (function () {
    function AvailableContentItemModel() {
    }
    return AvailableContentItemModel;
}());
export { AvailableContentItemModel };
var AvailableContentModel = /** @class */ (function () {
    function AvailableContentModel() {
    }
    return AvailableContentModel;
}());
export { AvailableContentModel };
var SearchResultsItemModel = /** @class */ (function () {
    function SearchResultsItemModel() {
    }
    return SearchResultsItemModel;
}());
export { SearchResultsItemModel };
var SearchResultsModel = /** @class */ (function () {
    function SearchResultsModel() {
    }
    return SearchResultsModel;
}());
export { SearchResultsModel };
