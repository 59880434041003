import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-generated-moderate-not-found',
  templateUrl: './user-generated-moderate-not-found.component.html',
  styleUrls: ['./user-generated-moderate-not-found.component.css']
})
export class UserGeneratedModerateNotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
