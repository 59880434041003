import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router';
import { Observable, timer } from 'rxjs';
import { take, map, catchError } from 'rxjs/operators';
import { ModerationApiService } from '../apis/moderation-api.service';
import { CurrentContentProviderService } from '../services/current-content-provider.service';
import { UserGeneratedContentForModerationModel } from '../models/user-generated-content-for-moderation.model';

@Injectable()
export class UserGeneratedContentResolver implements Resolve<UserGeneratedContentForModerationModel> {
  constructor(private _api: ModerationApiService, private _router: Router, private _currentContent: CurrentContentProviderService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserGeneratedContentForModerationModel> {
    const accountKey = route.paramMap.get('accountKey') || route.parent.paramMap.get('accountKey');
    const contentKey = route.paramMap.get('contentKey') || route.parent.paramMap.get('contentKey');

    if (this._currentContent.isCurrent(accountKey, contentKey)) {
      return timer(0).pipe(
        map(() => {
          return null;
        })
      );
    }

    return this._api.assignAndGetUserGeneratedContentForModeration(accountKey, contentKey).pipe(
      take(1),
      catchError((err: any, caught: Observable<UserGeneratedContentForModerationModel>) => {
        if (err.status === 401) {
          this._router.navigate(['/sessionexpired']);
          return null;
        } else {
          this._router.navigate(['/content', 'user-generated', accountKey, contentKey, 'not-found']);
          return null;
        }
      }),
      map((model: UserGeneratedContentForModerationModel) => {
        if (model) {
          // prepare the model as a view model with some defaults

          // So far, nothing to do on User Generated Content, but leaving this hook in here

          this._currentContent.setCurrent(model);

          return null;
        } else {
          this._currentContent.clear();

          this._router.navigate(['/content', 'user-generated', accountKey, contentKey, 'not-found']);
          return null;
        }
      })
    );
  }
}
