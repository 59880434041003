import { Inject, Optional } from '@angular/core';
import { DateTimeAdapter, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import * as moment from 'moment';
import 'moment-timezone';

/**
 * This file is mostly lifed directly from
 *  https://github.com/DanielYKPan/date-time-picker/blob/master/src/date-time/adapter/native-date-time-adapter.class.ts
 * the source code for the NativeDateTimeAdapter class.
 *
 * I've re-created it mostly here so we can customize it as see fit,
 *  namely to display dates in the EASTERN time zone as opposed to local.
 */


/** The default month names to use if Intl API is not available. */
const DEFAULT_MONTH_NAMES = {
    'long': [
        'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
        'October', 'November', 'December'
    ],
    'short': ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    'narrow': ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']
};

/** The default day of the week names to use if Intl API is not available. */
const DEFAULT_DAY_OF_WEEK_NAMES = {
    'long': ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    'short': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    'narrow': ['S', 'M', 'T', 'W', 'T', 'F', 'S']
};

const TARGET_TZ_NAME = 'UTC';
/**
 * Matches strings that have the form of a valid RFC 3339 string
 * (https://tools.ietf.org/html/rfc3339). Note that the string may not actually be a valid date
 * because the regex will match strings an with out of bounds month, date, etc.
 */
const ISO_8601_REGEX =
    /^\d{4}-\d{2}-\d{2}(?:T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|(?:(?:\+|-)\d{2}:\d{2}))?)?$/;

/** Creates an array and fills it with values. */
function range<T>(length: number, valueFunction: (index: number) => T): T[] {
    const valuesArray = Array(length);
    for (let i = 0; i < length; i++) {
        valuesArray[i] = valueFunction(i);
    }
    return valuesArray;
}

export class UtcDateTimeAdapter extends DateTimeAdapter<moment.Moment> {
    /**
       * Whether to use `timeZone: 'utc'` with `Intl.DateTimeFormat` when formatting dates.
       * Without this `Intl.DateTimeFormat` sometimes chooses the wrong timeZone, which can throw off
       * the result. (e.g. in the en-US locale `new Date(1800, 7, 14).toLocaleDateString()`
       * will produce `'8/13/1800'`.
       */
    useUtcForDisplay: boolean;

    getDateNames(): string[] {
        throw new Error('Method not implemented.');
    }

    constructor(@Optional() @Inject(OWL_DATE_TIME_LOCALE) private owlDateTimeLocale: string) {
        super();
        super.setLocale(owlDateTimeLocale);

        this.useUtcForDisplay = !(typeof document === 'object' && !!document &&
            /(msie|trident)/i.test(navigator.userAgent));
    }

    public getYear(date: moment.Moment): number {
        return date.year();
    }

    public getMonth(date: moment.Moment): number {
        return date.month();
    }

    public getDay(date: moment.Moment): number {
        return date.day();
    }

    public getDate(date: moment.Moment): number {
        return date.date();
    }

    public getHours(date: moment.Moment): number {
        return date.hours();
    }

    public getMinutes(date: moment.Moment): number {
        return date.minutes();
    }

    public getSeconds(date: moment.Moment): number {
        return date.seconds();
    }

    public getTime(date: moment.Moment): number {
        return date.valueOf();
    }

    public getNumDaysInMonth(date: moment.Moment): number {
        return date.daysInMonth();
    }

    public differenceInCalendarDays(dateLeft: moment.Moment, dateRight: moment.Moment): number {
        if (this.isValid(dateLeft) && this.isValid(dateRight)) {
            const dateLeftStartOfDay = this.createDate(
                this.getYear(dateLeft),
                this.getMonth(dateLeft),
                this.getDate(dateLeft)
            );
            const dateRightStartOfDay = this.createDate(
                this.getYear(dateRight),
                this.getMonth(dateRight),
                this.getDate(dateRight)
            );

            const timeStampLeft = this.getTime(dateLeftStartOfDay) + dateLeftStartOfDay.utcOffset() * this.milliseondsInMinute;
            const timeStampRight = this.getTime(dateRightStartOfDay) + dateRightStartOfDay.utcOffset() * this.milliseondsInMinute;
            return Math.round((timeStampLeft - timeStampRight) / this.millisecondsInDay);
        } else {
            return null;
        }
    }

    public getYearName(date: moment.Moment): string {
        return String(moment.tz(TARGET_TZ_NAME).year());
    }

    public getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
        return DEFAULT_MONTH_NAMES[style];
    }

    public getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
        return DEFAULT_DAY_OF_WEEK_NAMES[style];
    }

    public toIso8601(date: moment.Moment): string {
        return date.toISOString();
    }

    public isEqual(dateLeft: moment.Moment, dateRight: moment.Moment): boolean {
        if (this.isValid(dateLeft) && this.isValid(dateRight)) {
            return dateLeft.valueOf() === dateRight.valueOf();
        } else {
            return false;
        }
    }

    public isSameDay(dateLeft: moment.Moment, dateRight: moment.Moment): boolean {
        if (this.isValid(dateLeft) && this.isValid(dateRight)) {
            const dateLeftStartOfDay = this.clone(dateLeft);
            const dateRightStartOfDay = this.clone(dateRight);
            dateLeftStartOfDay.hours(0).minutes(0).seconds(0).milliseconds(0);
            dateRightStartOfDay.hours(0).minutes(0).seconds(0).milliseconds(0);
            return dateLeftStartOfDay.valueOf() === dateRightStartOfDay.valueOf();
        } else {
            return false;
        }
    }

    public isValid(date: moment.Moment): boolean {
        return date && !isNaN(date.valueOf());
    }

    public invalid(): moment.Moment {
        return moment.tz(NaN, TARGET_TZ_NAME);
    }

    public isDateInstance(obj: any): boolean {
        return moment.isMoment(obj);
    }

    public addCalendarYears(date: moment.Moment, amount: number): moment.Moment {
        return this.addCalendarMonths(date, amount * 12);
    }

    public addCalendarMonths(date: moment.Moment, amount: number): moment.Moment {
        const result = this.clone(date);
        amount = Number(amount);

        const desiredMonth = result.month() + amount;
        const dateWithDesiredMonth = moment.tz([result.year(), desiredMonth, 1, 0, 0, 0, 0], TARGET_TZ_NAME);

        const daysInMonth = this.getNumDaysInMonth(dateWithDesiredMonth);
        // Set the last day of the new month
        // if the original date was the last day of the longer month
        result.month(desiredMonth).day(Math.min(daysInMonth, result.day()));
        return result;
    }

    public addCalendarDays(date: moment.Moment, amount: number): moment.Moment {
        const result = this.clone(date);
        amount = Number(amount);
        result.day(result.day() + amount);
        return result;
    }

    public setHours(date: moment.Moment, amount: number): moment.Moment {
        const result = this.clone(date);
        result.hours(amount);
        return result;
    }

    public setMinutes(date: moment.Moment, amount: number): moment.Moment {
        const result = this.clone(date);
        result.minutes(amount);
        return result;
    }

    public setSeconds(date: moment.Moment, amount: number): moment.Moment {
        const result = this.clone(date);
        result.seconds(amount);
        return result;
    }

    public createDate(
        year: number,
        month: number,
        date: number,
        hours: number = 0,
        minutes: number = 0,
        seconds: number = 0
    ): moment.Moment {
        if (month < 0 || month > 11) {
            throw Error(`Invalid month index "${month}". Month index has to be between 0 and 11.`);
        }

        if (date < 1) {
            throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
        }

        if (hours < 0 || hours > 23) {
            throw Error(`Invalid hours "${hours}". Hours has to be between 0 and 23.`);
        }

        if (minutes < 0 || minutes > 59) {
            throw Error(`Invalid minutes "${minutes}". Minutes has to between 0 and 59.`);
        }

        if (seconds < 0 || seconds > 59) {
            throw Error(`Invalid seconds "${seconds}". Seconds has to be between 0 and 59.`);
        }

        const result = this.createDateWithOverflow(year, month, date, hours, minutes, seconds);

        // Check that the date wasn't above the upper bound for the month, causing the month to overflow
        // For example, createDate(2017, 1, 31) would try to create a date 2017/02/31 which is invalid
        if (result.month() !== month) {
            throw Error(`Invalid date "${date}" for month with index "${month}".`);
        }

        return result;
    }

    public clone(date: moment.Moment): moment.Moment {
        return moment.tz(date.valueOf(), date.tz());
    }

    public now(): moment.Moment {
        return moment.tz(TARGET_TZ_NAME);
    }

    public format(date: moment.Moment, displayFormat: any): string {
        if (!this.isValid(date)) {
            throw Error('MomentJS: Cannot format invalid date.');
        }

        return this.stripDirectionalityCharacters(date.tz(TARGET_TZ_NAME).format('M/D/YY, h:mm A'));
    }

    public parse(value: any, parseFormat: any): moment.Moment | null {
        return value ? moment.tz(value, TARGET_TZ_NAME) : null;
    }

    /**
     * Returns the given value if given a valid Date or null. Deserializes valid ISO 8601 strings
     * (https://www.ietf.org/rfc/rfc3339.txt) into valid Dates and empty string into null. Returns an
     * invalid date for all other values.
     */
    public deserialize(value: any): moment.Moment | null {
        if (typeof value === 'string') {
            if (!value) {
                return null;
            }
            // The `Date` constructor accepts formats other than ISO 8601, so we need to make sure the
            // string is the right format first.
            if (ISO_8601_REGEX.test(value)) {
                const date = moment.tz(value, TARGET_TZ_NAME);
                if (this.isValid(date)) {
                    return date;
                }
            }
        }
        return super.deserialize(value);
    }

    /**
     * Creates a date but allows the month and date to overflow.
     * @param {number} year
     * @param {number} month
     * @param {number} date
     * @param {number} hours -- default 0
     * @param {number} minutes -- default 0
     * @param {number} seconds -- default 0
     * @returns The new date, or null if invalid.
     * */
    private createDateWithOverflow(year: number, month: number, date: number,
        hours: number = 0, minutes: number = 0, seconds: number = 0): moment.Moment {
        const result = moment.tz([year, month, date, hours, minutes, seconds], TARGET_TZ_NAME);

        return result;
    }

    /**
     * Strip out unicode LTR and RTL characters. Edge and IE insert these into formatted dates while
     * other browsers do not. We remove them to make output consistent and because they interfere with
     * date parsing.
     * @param str The string to strip direction characters from.
     * @returns The stripped string.
     */
    private stripDirectionalityCharacters(str: string) {
        return str.replace(/[\u200e\u200f]/g, '');
    }
}
