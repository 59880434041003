<div *ngIf="!isAuthorized" class="center-stage">
  <h1>Please Log On</h1>
  <p class="lead">Please log on using the menu above to get the fun started.</p>
</div>
<div *ngIf="isAuthorized" class="container-almost-full-width dashboard">
  <app-loading-spinner *ngIf="!model && !error"></app-loading-spinner>
  <div *ngIf="model || error">
    <div *ngIf="error">
      <h1 class="error">Error!</h1>
      <p class="lead">{{ error }}</p>
    </div>
    <div class="row">
      <div class="col-7">
        <span class="h3">Programs Listing</span>
      </div>
      <div class="col-5">
        <a class="btn btn-outline-info pull-right" [routerLink]="['/reports']"><span class="fa fa-bar-chart"></span> Reports</a>
      </div>
    </div>
    <div class="mt-4">
      <form class="form-inline">
        <div class="form-group">
          <label for="searchKeyword">Program Name</label>
          <input type="text" name="searchKeyword" class="form-control ml-2" [(ngModel)]="searchPayload.keyword" />
        </div>
        <div class="form-check ml-4">
          <input type="checkbox" name="showNonLivePrograms" id="showNonLivePrograms" [(ngModel)]="searchPayload.showNonLivePrograms" />
          <label class="form-check-label ml-2" for="showNonLivePrograms">
            Include Completed
          </label>
        </div>
        <button class="btn btn-primary ml-4" (click)="populateDashboard()">Submit</button>
      </form>
    </div>
    <table class="table table-sm mt-4">
      <thead>
        <tr>
          <th></th>
          <th>Account</th>
          <th>Program</th>
          <th class="text-right">Starts</th>
          <th class="text-right">Ends</th>
          <th class="text-right">Total Content</th>
          <th class="text-right">Available Content</th>
          <th class="text-right">Oldest Available</th>
          <th class="text-right">Keyword Threshold</th>
          <th class="text-right">Receipt Total Warning Threshold</th>
          <th class="text-center">SHA Reject</th>
          <th class="text-center">ARF</th>
          <th class="text-center">Capture Store Number</th>
          <th class="text-center">Receipt / UGC Review</th>
          <th>OCR</th>
        </tr>
      </thead>
      <tbody *ngIf="model.items.length > 0">
        <tr *ngFor="let program of model.items">
          <td class="actions">
            <a [routerLink]="['/program', program.programKey, 'data']" title="Download Standard v1.5 Reports Data"><i class="fa fa-download"></i></a>
          </td>
          <td>{{ program.accountName }}</td>
          <td>
            <a [routerLink]="['/program', program.programKey]">{{ program.programName }}</a>
          </td>
          <td class="text-right">{{ program.startsOn | date: 'mediumDate' }}</td>
          <td class="text-right">{{ program.endsOn | date: 'mediumDate' }}</td>
          <td class="text-right">{{ program.totalContent }}</td>
          <td class="text-right">{{ program.availableContent }}</td>
          <td class="text-right">{{ program.earliestAvailableDate | date: 'mediumDate' }}</td>
          <td class="text-right">{{ program.keywordAdditionThreshold | number }}</td>
          <td class="text-right">{{ program.receiptTotalWarningThreshold | currency }}</td>
          <td class="text-center">
            <i *ngIf="program.rejectOnSHACollision" class="fa fa-check text-success"></i>
            <i *ngIf="!program.rejectOnSHACollision" class="fa fa-times text-danger"></i>
          </td>
          <td class="text-center">
            <i *ngIf="program.isActionRequiredFlowEnabled" class="fa fa-check text-success"></i>
            <i *ngIf="!program.isActionRequiredFlowEnabled" class="fa fa-times text-danger"></i>
          </td>
          <td class="text-center">
            <ng-container *ngIf="program.isStoreNumberCaptured"
              ><i *ngIf="program.isStoreNumberCaptured" class="fa fa-check text-success"></i><br />
              ({{ program.isStoreNumberRequired ? 'required' : 'optional' }})</ng-container
            >
            <i *ngIf="!program.isStoreNumberCaptured" class="fa fa-times text-danger"></i>
          </td>
          <td class="text-center">
            <i *ngIf="program.isReceiptReviewEnabled" class="fa fa-check text-success"></i>
            <i *ngIf="!program.isReceiptReviewEnabled" class="fa fa-times text-danger"></i>
          </td>
          <td>{{ program.ocrType }}</td>
        </tr>
      </tbody>
      <tbody *ngIf="model.items.length === 0">
        <tr>
          <td colspan="100"><i>No programs found</i></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
