<div class="container-full-width">
  <h4>Content Key: {{ contentKey }}</h4>
  <app-loading-spinner *ngIf="!content && !error"></app-loading-spinner>
  <div *ngIf="content || error">
    <div *ngIf="error">
      <h1 class="error">Error!</h1>
      <p class="lead">{{ error }}</p>
    </div>
    <div *ngIf="content">
      <div *ngIf="content.consumer" class="alert alert-warning-orange"><strong>Consumer Information Warning</strong> The data for this receipt was modified by the consumer.</div>
      <div class="row">
        <div class="col-3">
          <div style="width:440px;height:600px;">
            <app-resource-viewer [url]="content.content.resourceUrl" [mediaType]="content.content.resourceMediaType"></app-resource-viewer>
          </div>
          <h5>Upload Date: {{ content.content.createdOn | date: 'medium' }}</h5>
          <span *ngIf="content.content.moderatedOn; else unmoderated"
            ><b>Moderated By:</b><span *ngIf="content.content.moderatedBy; else moderatedBySystem"> {{ content.content.moderatedBy }}</span> <b>On:</b> {{ content.content.moderatedOn | date: 'medium' }}</span
          >
          <ng-template #moderatedBySystem> System</ng-template>
          <ng-template #unmoderated><i>Pending Moderation</i></ng-template>
          <div *ngIf="content.receipt.isDuplicate" class="alert alert-danger"><strong>Fingerprint Alert! - Potential Duplicate Image</strong> This image has attributes that identically match other images submitted to this program.</div>
          <dl>
            <dd *ngFor="let fingerprintGroup of content.receipt.fingerprintCollisionGroups">
              <div class="alert" [class.alert-danger]="fingerprintGroup.category === constants.threeFactorCategory" [class.alert-warning]="fingerprintGroup.category === constants.twoFactorCategory">
                <i
                  class="fa mr-3 pointer"
                  [class.fa-minus-square-o]="fingerprintGroup.showCollisions"
                  [class.fa-plus-square-o]="!fingerprintGroup.showCollisions"
                  (click)="fingerprintGroup.showCollisions = !fingerprintGroup.showCollisions"
                ></i>
                <span class="badge badge-light mr-3">{{ fingerprintGroup.collisions.length }}</span>
                <span class="pointer" (click)="fingerprintGroup.showCollisions = !fingerprintGroup.showCollisions">{{ fingerprintGroup.categoryDescription }}</span>
                <span class="view-duplicates"
                  ><a [routerLink]="['/content', 'examine', 'receipt', content.content.accountKey, content.content.contentKey, 'duplicates', fingerprintGroup.category]" [queryParams]="{ categoryDescription: fingerprintGroup.categoryDescription }"
                    >View All</a
                  ></span
                >
                <dl *ngIf="fingerprintGroup.showCollisions">
                  <dd *ngFor="let fingerprint of fingerprintGroup.collisions">
                    <i *ngIf="fingerprint.isSecondPass" class="fa fa-exclamation-triangle mr-2" title="New Collision"></i><span>{{ fingerprint.createdOn | date: 'medium' }}</span>
                    <a [routerLink]="['/content', 'examine', 'receipt', fingerprint.accountKey, fingerprint.contentKey]" target="_blank">[View]</a>
                    <a [routerLink]="['/content', 'search', fingerprint.participantKey]" target="_blank">[View Participant]</a>
                    <i *ngIf="content.content.participantKey === fingerprint.participantKey" class="fa fa-user ml-2" title="Same Participant"></i>
                    <span class="badge-sm" [ngClass]="fingerprint.outcomeIndicatorClasses" [title]="fingerprint.outcomeIndicatorTooltip">{{ fingerprint.outcomeIndicatorText }}</span>
                  </dd>
                </dl>
              </div>
            </dd>
          </dl>
        </div>
        <div class="col-6">
          <table class="table table-sm receipt-date">
            <thead>
              <tr>
                <th class="data-2-cols">Date Text</th>
                <th class="data-2-cols">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ content.receipt.purchasedOnText }}</td>
                <td>{{ content.receipt.purchasedOn }}</td>
              </tr>
            </tbody>
          </table>
          <table class="table table-sm receipt-store-list">
            <thead>
              <tr>
                <th class="data-2-cols">Retailer Text</th>
                <th class="data-2-cols">Retailer</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ content.receipt.storeText }}</td>
                <td>{{ content.receipt?.storeName }}</td>
              </tr>
            </tbody>
          </table>
          <table class="table table-sm receipt-store-number">
            <thead>
              <tr>
                <th class="data-2-cols"></th>
                <th class="data-2-cols">Store Number</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td>{{ content.receipt?.storeNumber }}</td>
              </tr>
            </tbody>
          </table>
          <table class="table table-sm receipt-total">
            <thead>
              <tr>
                <th class="data-2-cols">Receipt Total Text</th>
                <th class="data-2-cols">Receipt Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ content.receipt.totalText }}</td>
                <td>{{ content.receipt.total | currency }}</td>
              </tr>
            </tbody>
          </table>
          <table class="table table-sm receipt-item-list">
            <thead>
              <tr>
                <th>Text</th>
                <th>Product</th>
                <th class="text-right">Quantity</th>
                <th class="text-right">Total Price</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let receiptItem of content.receiptItems" [class.receipt-has-product]="receiptItem.productKey">
                <td>{{ receiptItem.text }}</td>
                <td>{{ receiptItem.productName }}</td>
                <td class="text-right">{{ receiptItem.quantity }}</td>
                <td class="text-right">{{ receiptItem.total }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-3">
          <table class="table table-sm product-list">
            <thead>
              <tr>
                <th>Rule Sets</th>
              </tr>
              <tr>
                <td>
                  <label class="mr-3"> <input type="checkbox" [(ngModel)]="showPassingRuleSets" />Pass</label>
                  <label class="mr-3"> <input type="checkbox" [(ngModel)]="showFailingRuleSets" />Fail</label>
                </td>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let ruleSetItem of content.ruleSets">
                <tr *ngIf="(showPassingRuleSets && ruleSetItem.isPassed) || (showFailingRuleSets && !ruleSetItem.isPassed)">
                  <td (click)="toggleRules(ruleSetItem)" [class.table-success]="ruleSetItem.isPassed" [class.table-danger]="!ruleSetItem.isPassed">
                    <label class="pointer"><i class="fa md mr-2 mt-1" [class.fa-caret-right]="!ruleSetItem.showRules" [class.fa-caret-down]="ruleSetItem.showRules" aria-hidden="true"></i>{{ ruleSetItem.text }}</label>
                    <ul *ngIf="ruleSetItem.showRules">
                      <li *ngFor="let ruleItem of ruleSetItem.rules" [class.table-success]="ruleItem.isPassed" [class.table-danger]="!ruleItem.isPassed">
                        {{ ruleItem.text }}
                      </li>
                    </ul>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row justify-content-md-center" *ngIf="content.content.rejectionReason">
        <div class="col-6">
          <div class="card-group">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Rejection Criteria</h4>
                <button class="btn btn-danger mr-3 mt-3">{{ content.content.rejectionReason }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
