import { Component, OnInit, OnDestroy } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

export abstract class SecurePageBase implements OnInit, OnDestroy {
  userDataSubscription: Subscription;
  authorizedSubscription: Subscription;
  isAuthorized: boolean;
  displayName: string;
  profileKey: string;

  constructor(protected oidc: OidcSecurityService, protected router: Router) {}

  abstract onAuthorized(): void;
  abstract onNgOnDestroy(): void;
  abstract onShouldDisplayOwnUnauthorizedMessage(): boolean;

  ngOnInit() {
    this.authorizedSubscription = this.oidc
      .getIsAuthorized()
      .subscribe((isAuthorized: boolean) => {
        this.isAuthorized = isAuthorized;

        if (this.isAuthorized) {
          this.userDataSubscription = this.oidc
            .getUserData()
            .subscribe(data => {
              this.displayName = data.name;
              this.profileKey = data.sub;
              this.onAuthorized();
            });
        } else {
          if (this.onShouldDisplayOwnUnauthorizedMessage()) {
            // do nothing, the page will display its own message
          } else {
            this.router.navigateByUrl('/');
          }
        }
      });
  }

  ngOnDestroy() {
    if (this.authorizedSubscription) {
      this.authorizedSubscription.unsubscribe();
    }
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
    this.onNgOnDestroy();
  }
}
