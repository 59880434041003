import { Injectable } from '@angular/core';
import { ReceiptContentForModerationModel, ReceiptContentForExamineModel } from '../models/moderation-models';
import { UserGeneratedContentForModerationModel } from '../models/user-generated-content-for-moderation.model';
import { UserGeneratedContentForExamineModel } from '../models/user-generated-content-for-examine.model';

@Injectable()
export class CurrentContentProviderService {
  private _model: ReceiptContentForModerationModel | UserGeneratedContentForModerationModel;
  private _examineModel: ReceiptContentForExamineModel | UserGeneratedContentForExamineModel;

  constructor() {}

  public setCurrent(model: ReceiptContentForModerationModel | UserGeneratedContentForModerationModel): void {
    this._model = model;
  }

  public setCurrentExamine(model: ReceiptContentForExamineModel | UserGeneratedContentForExamineModel): void {
    this._examineModel = model;
  }

  public getCurrentForReceipt(): ReceiptContentForModerationModel {
    return <ReceiptContentForModerationModel>this._model;
  }

  public getCurrentForUserGenerated(): UserGeneratedContentForModerationModel {
    return <UserGeneratedContentForModerationModel>this._model;
  }

  public getCurrentExamineForReceipt(): ReceiptContentForExamineModel {
    return <ReceiptContentForExamineModel>this._examineModel;
  }

  public getCurrentExamineForUserGenerated(): UserGeneratedContentForExamineModel {
    return <UserGeneratedContentForExamineModel>this._examineModel;
  }

  public isCurrent(accountKey: string, contentKey: string): boolean {
    if (this._model && this._model.content.accountKey === accountKey && this._model.content.contentKey === contentKey) {
      return true;
    }
    return false;
  }

  public isCurrentExamine(accountKey: string, contentKey: string): boolean {
    if (this._examineModel && this._examineModel.content.accountKey === accountKey && this._examineModel.content.contentKey === contentKey) {
      return true;
    }
    return false;
  }

  public clear(): void {
    this._model = null;
  }

  public clearExamine(): void {
    this._examineModel = null;
  }
}
