import { OnInit, OnDestroy } from '@angular/core';
import { OidcSecurityService, AuthorizationResult } from 'angular-auth-oidc-client';
import { UnauthorizedWatcherService } from '../../services/unauthorized-watcher.service';
import { ApplicationVersion } from '../../../version';
import { ModerationApiService } from '../../apis/moderation-api.service';
import { environment } from '../../../environments/environment';
var TopnavComponent = /** @class */ (function () {
    function TopnavComponent(oidc, moderationApi, unauthorizedWatcherService) {
        var _this = this;
        this.oidc = oidc;
        this.moderationApi = moderationApi;
        this.unauthorizedWatcherService = unauthorizedWatcherService;
        this.apiVersion = '...'; // retrieved later
        this.environmentIndicator = 'prd';
        if (this.oidc.moduleSetup) {
            this.doCallbackLogicIfRequired();
        }
        else {
            this.oidc.onModuleSetup.subscribe(function () {
                _this.doCallbackLogicIfRequired();
            });
        }
        this.version = ApplicationVersion.version;
        unauthorizedWatcherService.unauthorizedRecieved.subscribe(function () {
            _this.isAuthorized = false;
        });
        oidc.onAuthorizationResult.subscribe(function (result) {
            _this.isAuthorized = result === AuthorizationResult.authorized;
            history.replaceState('', document.title, "" + window.location.pathname + window.location.search);
        });
        this.environmentIndicator = this.getEnvironmentIndicator();
    }
    TopnavComponent.prototype.getEnvironmentIndicator = function () {
        // HACK: Using an existing piece of data that is already in the environment files so they do not have to be updated when upgrading to this version
        var url = environment.oidc.redirectUrl;
        if (url.match(/localhost/i)) {
            return 'loc';
        }
        else if (url.match(/\.dev\./i)) {
            return 'dev';
        }
        else if (url.match(/\.tst\./i)) {
            return 'tst';
        }
        else if (url.match(/\.staging\./i)) {
            return 'stg';
        }
        return 'prd';
    };
    TopnavComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.oidc.getIsAuthorized().subscribe(function (isAuthorized) {
            _this.isAuthorized = isAuthorized;
        });
        this.oidc.getUserData().subscribe(function (userData) {
            _this.userData = userData;
        });
        this.moderationApi.getVersion().subscribe(function (data) {
            _this.apiVersion = data.version;
        });
    };
    TopnavComponent.prototype.ngOnDestroy = function () {
        this.oidc.onModuleSetup.unsubscribe();
    };
    TopnavComponent.prototype.logon = function () {
        this.oidc.authorize();
    };
    TopnavComponent.prototype.logoff = function () {
        this.oidc.logoff();
    };
    TopnavComponent.prototype.doCallbackLogicIfRequired = function () {
        if (window.location.hash) {
            this.oidc.authorizedCallback();
        }
    };
    return TopnavComponent;
}());
export { TopnavComponent };
