
import {timer as observableTimer,  Observable ,  Subscription } from 'rxjs';

import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';

interface TimerList {
  [name: string]: {
    second: number;
    observable: Observable<any>;
  };
}

interface TimerSubscriptionList {
  [id: string]: {
    name: string;
    subscription: Subscription;
  };
}

@Injectable()
export class TimerService {
  private timer: TimerList = {};
  private subscription: TimerSubscriptionList = {};

  getTimer(): string[] {
    return Object.keys(this.timer);
  }
  getSubscription(): string[] {
    return Object.keys(this.subscription);
  }
  newTimer(name: string, sec: number): boolean {
    if (name === undefined || sec === undefined || this.timer[name]) {
      return false;
    }
    const o = observableTimer(0, sec * 1000);
    this.timer[name] = { second: sec, observable: o };
    return true;
  }
  delTimer(name: string): boolean {
    if (name === undefined || !this.timer[name]) {
      return false;
    }
    const s = this.getSubscription();
    // unsubscribe all subscription for queue 'name'
    s.forEach(i => {
      if (this.subscription[i].name === name) {
        this.unsubscribe(i);
      }
    });
    // delete queue 'name' subject and observable
    delete this.timer[name].observable;
    delete this.timer[name];
  }
  /**
   *
   * @param name
   * @param callback
   */
  subscribe(name: string, callback: () => void): string {
    if (!this.timer[name]) {
      return '';
    }
    const id = name + '-' + UUID.UUID();
    this.subscription[id] = {
      name: name,
      subscription: this.timer[name].observable.subscribe(callback)
    };
    return id;
  }
  /**
   *
   * @param id
   */
  unsubscribe(id: string): boolean {
    if (!id || !this.subscription[id]) {
      return false;
    }
    this.subscription[id].subscription.unsubscribe();
    delete this.subscription[id];
  }
}
