import { Component, OnInit } from '@angular/core';
import { Constants } from '../../constants';
import { SecurePageBase } from '../secure-page-base';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router, ActivatedRoute } from '@angular/router';
import { CurrentContentProviderService } from '../../services/current-content-provider.service';
import { TimerService } from '../../services/timer-service';
import { ModerationApiService } from '../../apis/moderation-api.service';
import { ReceiptContentForModerationModel } from '../../models/moderation-models';
import { Subscription, Observable, interval } from 'rxjs';
import { toDate } from '../../../i18n/date_util';
import { FingerprintCollisionModel } from '../../models/fingerprint-collision.model';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-receipt-moderate-duplicates',
  templateUrl: './receipt-moderate-duplicates.component.html',
  styleUrls: ['./receipt-moderate-duplicates.component.css']
})
export class ReceiptModerateDuplicatesComponent extends SecurePageBase {
  constants: typeof Constants;
  content: ReceiptContentForModerationModel;
  category: string;
  categoryDescription: string;
  timerName: string;
  isTimerWarning = false;
  isTimerComplete = false;
  showActionButtons = true;
  secondsLeft: number;
  collisions: Array<FingerprintCollisionModel>;
  private abortSubscription: Subscription;
  private timerWarningSeconds = Constants.moderationTimeoutWarningSeconds;
  private isAbortingModeration = false;

  constructor(oidc: OidcSecurityService, router: Router, route: ActivatedRoute, private _currentContent: CurrentContentProviderService, private _timer: TimerService, private _moderationApi: ModerationApiService) {
    super(oidc, router);
    this.constants = Constants;
    this.category = route.snapshot.params['category'];
    this.categoryDescription = route.snapshot.queryParams['categoryDescription'];
  }

  onAuthorized(): void {
    const model = this._currentContent.getCurrentForReceipt();

    this.timerName = model.content.contentKey;
    this.prepareTimer();

    const fingerprintGroup = model.receipt.fingerprintCollisionGroups.filter(x => x.category === this.category)[0];
    if (fingerprintGroup) {
      // Show them latest on top like the Review screen does
      this.collisions = fingerprintGroup.collisions.sort((a, b) => toDate(b.createdOn).getDate() - toDate(a.createdOn).getDate());
    }

    this.content = model;
  }

  onNgOnDestroy(): void {
    if (this.abortSubscription) {
      this.abortSubscription.unsubscribe();
    }
  }

  onShouldDisplayOwnUnauthorizedMessage(): boolean {
    return false;
  }

  //#region Timer
  private prepareTimer(): void {
    this._timer.delTimer(this.timerName);
    this._timer.newTimer(this.timerName, 1);
    this._timer.subscribe(this.timerName, () => this.timerTick());
  }
  private getTimeoutInSeconds(): number {
    return this.content.content.timeoutInMinutes * 60;
  }
  private timerTick(): void {
    const assignedOn = new Date(this.content.content.assignedOn);
    const milliseconds = new Date().getTime() - assignedOn.getTime();
    const seconds = milliseconds / 1000;
    const timeoutInSeconds = this.getTimeoutInSeconds();
    this.secondsLeft = timeoutInSeconds - seconds;

    if (this.secondsLeft < 0) {
      this.isTimerWarning = false;
      this.isTimerComplete = true;
      this._timer.delTimer(this.timerName);
      interval(3000)
        .pipe(takeWhile(() => !this.isAbortingModeration))
        .subscribe(() => {
          this.abortModeration();
        });
    } else if (this.secondsLeft < this.timerWarningSeconds) {
      this.isTimerWarning = true;
    }
  }
  //#endregion

  abortModeration(): void {
    this.isAbortingModeration = true;
    this.showActionButtons = false;
    this._timer.delTimer(this.timerName);

    this.abortSubscription = this._moderationApi.abortModerationOfContent(this.content.content.accountKey, this.content.content.contentKey).subscribe(() => {
      this.router.navigate(['/program', this.content.content.programKey]);
    });
  }
}
