import { RuleSetType } from './rule-set-type.enum';

export class DashboardModelItem {
  public accountKey: string;
  public programKey: string;
  public accountName: string;
  public programName: string;
  public totalContent: number;
  public availableContent: number;
  public keywordAdditionThreshold: number;
  public receiptTotalWarningThreshold: number;
  public rejectOnSHACollision: boolean;
  public isActionRequiredFlowEnabled: boolean;
  public isStoreNumberCaptured: boolean;
  public isStoreNumberRequired: boolean;
  public isReceiptReviewEnabled: boolean;
  public startsOn: Date | null;
  public endsOn: Date | null;
  public earliestAvailableDate: Date | null;
  public earliestNotCompletedDate: Date | null;
  public ocrType: string;
}

export class DashboardModel {
  public items: Array<DashboardModelItem>;
}

export class DashboardSearchModel {
  public showNonLivePrograms: boolean | null;
  public keyword: string;
}

export class ProgramDashboardModel {
  public programName: string;
  public totalContent: number;
  public parkedContent: number;
  public availableContent: number;
}

export class ProgramAnalyticsReportResultsModel {
  public reports: Array<AnalyticsReportDefinition>;
}

export class AnalyticsReportDefinition {
  public report: string;
}

export class AnalyticsExtractModel {
  public reports: Array<AnalyticsReportDefinition>;
  public startOn: Date | null | string;
  public endOn: Date | null | string;
  public programKey: string;
}

export class AvailableContentItemModel {
  public accountKey: string;
  public contentKey: string;
  public receiptKey: string;
  public resourceUrl: string;
  public resourceMediaType: string;
  public createdOn: Date;
  public parkedReason: string;
  public parkedBy: string;
  public parkedOn: Date;
  public contentModerationType: RuleSetType;
}
export class AvailableContentModel {
  public items: Array<AvailableContentItemModel>;
}

export class SearchResultsItemModel {
  public accountKey: string;
  public contentKey: string;
  public receiptKey: string;
  public resourceUrl: string;
  public resourceMediaType: string;
  public createdOn: Date;
  public parkedReason: string;
  public parkedBy: string;
  public parkedOn: Date;
  public moderatedBy: string;
  public moderatedOn: Date;
  public moderationOutcome: string;
  public canBeAssigned: boolean;
  public contentModerationType: RuleSetType;

  // View Model properties
  public moderationOutcomeText: string;
  public moderationOutcomeSuccessful: boolean;
  public moderationOutcomeRejected: boolean;
}
export class SearchResultsModel {
  public items: Array<SearchResultsItemModel>;
}
