import { EventEmitter } from '@angular/core';
var UnauthorizedWatcherService = /** @class */ (function () {
    function UnauthorizedWatcherService() {
        this.unauthorizedRecieved = new EventEmitter();
    }
    UnauthorizedWatcherService.prototype.emitUnauthorizedEvent = function () {
        this.unauthorizedRecieved.emit(null);
    };
    return UnauthorizedWatcherService;
}());
export { UnauthorizedWatcherService };
