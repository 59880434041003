import { OnInit } from '@angular/core';
var FraudScoreIndicatorComponent = /** @class */ (function () {
    function FraudScoreIndicatorComponent() {
        this.showIndicator = false;
    }
    FraudScoreIndicatorComponent.prototype.ngOnInit = function () {
        this.showIndicator = this.fraudScore !== null && this.fraudScore > this.fraudThreshold;
    };
    return FraudScoreIndicatorComponent;
}());
export { FraudScoreIndicatorComponent };
