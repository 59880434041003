import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-container',
  templateUrl: './receipt-moderate-parent.component.html',
  styleUrls: [ ]
})
export class ReceiptModerateParentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
