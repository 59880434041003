import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subscription } from 'rxjs';

import { ModerationApiService } from '../../apis/moderation-api.service';
import { DashboardModel, AvailableContentModel, AvailableContentItemModel, DashboardSearchModel } from '../../models/dashboard-model';
import { SecurePageBase } from '../secure-page-base';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { CurrentContentProviderService } from '../../services/current-content-provider.service';
import { Constants } from '../../constants';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends SecurePageBase {
  constants: typeof Constants;
  model: DashboardModel;
  error: ErrorObservable<any>;
  dashboardSubscription: Subscription;
  showActionButtons: Boolean = true;
  currentList = 'available';
  searchPayload: DashboardSearchModel;

  constructor(oidc: OidcSecurityService, router: Router, private moderationApi: ModerationApiService, currentContent: CurrentContentProviderService) {
    super(oidc, router);
    this.constants = Constants;
    this.searchPayload = new DashboardSearchModel();
    this.searchPayload.keyword = '';
    this.searchPayload.showNonLivePrograms = null;
    currentContent.clear();
  }

  private populateDashboard(): void {
    this.model = null;
    this.showActionButtons = false;
    this.dashboardSubscription = this.moderationApi.getDashboard(this.searchPayload).subscribe(
      (dashboardModel: DashboardModel) => {
        this.model = dashboardModel;
        this.showActionButtons = true;
      },
      (error: ErrorObservable<any>) => {
        this.error = error;
      }
    );
  }
  onNgOnDestroy(): void {
    if (this.dashboardSubscription) {
      this.dashboardSubscription.unsubscribe();
    }
  }

  onAuthorized(): void {
    this.populateDashboard();
  }

  onShouldDisplayOwnUnauthorizedMessage(): boolean {
    return true;
  }

  refreshGrid(): void {
    this.populateDashboard();
  }


}
