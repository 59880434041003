import { Injectable } from '@angular/core';
import { FileType } from '../models/file-type.enum';

export class FileIdentificationModel {
  public fileType: FileType;
  public url: string;
  public extension: string;
  public extensionDisplay: string;

  // Helper properties to make UI bindings easier
  public isUnknown: boolean;
  public isPDF: boolean;
  public isVideo: boolean;
  public isImage: boolean;
}

@Injectable()
export class FileIdentificationService {
  constructor() {}

  identify(urlString: string, mediaType: string): FileIdentificationModel {
    if (!!mediaType) {
      return this.identifyByMediaType(mediaType);
    } else {
      return this.identifyByUrl(urlString);
    }
  }

  private identifyByUrl(urlString: string): FileIdentificationModel {
    // Make sure these are all lowercase
    const pdfTypes = ['.pdf'];
    // Not included these because browsers don't really embed them easily, so they can just fall under the Unknown
    //    .wmv, .mov
    const videoTypes = ['.mp4', '.webm', '.ogm', '.ogv', '.ogg'];
    const imageTypes = ['.jpg', '.jpeg', '.png', '.gif'];

    const result = new FileIdentificationModel();

    const url = new URL(urlString);
    const pathname = url.pathname;

    result.extension = pathname.indexOf('.') > -1 ? pathname.substr(pathname.lastIndexOf('.')) : urlString;
    result.extension = result.extension.toLowerCase();
    result.extensionDisplay = result.extension.replace(/\./, '').toUpperCase();

    if (videoTypes.includes(result.extension)) {
      result.fileType = FileType.Video;
    } else if (pdfTypes.includes(result.extension)) {
      result.fileType = FileType.PDF;
    } else if (imageTypes.includes(result.extension)) {
      result.fileType = FileType.Image;
    } else {
      result.fileType = FileType.Unknown;
    }

    return this.finalizeResult(result);
  }

  private identifyByMediaType(mediaType: string): FileIdentificationModel {
    const result = new FileIdentificationModel();

    if (mediaType.startsWith('image/')) {
      result.fileType = FileType.Image;
    } else if (mediaType.startsWith('video/') || mediaType === 'application/ogg') {
      result.fileType = FileType.Video;
    } else if (mediaType === 'application/pdf') {
      result.fileType = FileType.PDF;
    } else {
      result.fileType = FileType.Unknown;
    }

    return this.finalizeResult(result);
  }

  private finalizeResult(result: FileIdentificationModel): FileIdentificationModel {
    result.isUnknown = result.fileType === FileType.Unknown;
    result.isPDF = result.fileType === FileType.PDF;
    result.isVideo = result.fileType === FileType.Video;
    result.isImage = result.fileType === FileType.Image;

    return result;
  }
}
