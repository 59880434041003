import { Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import * as HttpStatus from 'http-status-codes';
import { UnauthorizedWatcherService } from './unauthorized-watcher.service';
var AuthInterceptor = /** @class */ (function () {
    function AuthInterceptor(injector, router, unauthorizedWatcherService) {
        this.injector = injector;
        this.router = router;
        this.unauthorizedWatcherService = unauthorizedWatcherService;
    }
    AuthInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        // console.log(req);
        // Force all requests through https
        if (req.url.startsWith('http:')) {
            req = req.clone({ url: req.url.replace('http:', 'https:') });
        }
        var requestToForward = req;
        if (this.oidcSecurityService === undefined) {
            this.oidcSecurityService = this.injector.get(OidcSecurityService);
        }
        if (this.oidcSecurityService !== undefined) {
            var token = this.oidcSecurityService.getToken();
            if (token !== '') {
                var tokenValue = 'Bearer ' + token;
                requestToForward = req.clone({ setHeaders: { 'Authorization': tokenValue } });
            }
        }
        else {
            console.debug('OidcSecurityService undefined: NO auth header!');
        }
        return next.handle(requestToForward)
            .pipe(tap(function () { }, // Do nothing on success
        function (// Do nothing on success
        error) {
            if (error instanceof HttpErrorResponse && error.status === HttpStatus.UNAUTHORIZED) {
                console.debug('Response UNAUTHORIZED, redirecting to logged off page.');
                _this.unauthorizedWatcherService.emitUnauthorizedEvent();
                _this.router.navigateByUrl('/sessionexpired');
            }
        }));
    };
    return AuthInterceptor;
}());
export { AuthInterceptor };
