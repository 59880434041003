<div *ngIf="!isAuthorized" class="center-stage">
  <h1>Please Log On</h1>
  <p class="lead">Please log on using the menu above to get the fun started.</p>
</div>
<div *ngIf="isAuthorized" class="container dashboard">
  <div *ngIf="isItemRequesting">
    <app-loading-spinner></app-loading-spinner>
  </div>
  <div *ngIf="!isItemRequesting">
    <app-loading-spinner *ngIf="!model && !error"></app-loading-spinner>
    <div *ngIf="model || error">
      <div *ngIf="error">
        <h1 class="error">Error!</h1>
        <p class="lead">{{ error }}</p>
      </div>
      <h1 *ngIf="model">{{ model.programName }}</h1>
      <div *ngIf="model" class="card w-25 float-left">
        <h4 class="card-header">
          Total Content
        </h4>
        <div class="card-body stat">
          <div class="outer">
            <svg viewBox="0 0 140 140" preserveAspectRatio="xMinYMin meet">
              <g>
                <circle r="50%" cx="50%" cy="50%" class="circle-back" />
                <text x="50%" y="50%" text-anchor="middle" dy="0.3em">{{ model?.totalContent | number }}</text>
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div *ngIf="model" class="card w-25 float-left">
        <h4 class="card-header">
          Parked Content
        </h4>
        <div class="card-body stat">
          <div class="outer">
            <svg viewBox="0 0 140 140" preserveAspectRatio="xMinYMin meet">
              <g>
                <circle r="50%" cx="50%" cy="50%" class="circle-back" [class.warning]="model?.parkedContent > 0" />
                <text x="50%" y="50%" text-anchor="middle" dy="0.3em">{{ model?.parkedContent | number }}</text>
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div class="card w-25 float-left">
        <h4 class="card-header">
          Available
        </h4>
        <div class="card-body stat">
          <div class="outer">
            <svg viewBox="0 0 140 140" preserveAspectRatio="xMinYMin meet">
              <g>
                <circle r="50%" cx="50%" cy="50%" class="circle-back" />
                <text x="50%" y="50%" text-anchor="middle" dy="0.3em">{{ model?.availableContent | number }}</text>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix">&nbsp;</div>
    <div [style.visibility]="showActionButtons ? 'visible' : 'hidden'" class="mt-5">
      <button (click)="refreshAvailable()" class="btn btn-info btn-lg">
        <i class="fa fa-refresh" aria-hidden="true"></i>
      </button>
      <a [routerLink]="['/content', 'next', 'program', programKey]" class="btn btn-primary btn-lg ml-4">Next Available Item</a>
    </div>
    <ng-container *ngIf="availableContent">
      <ul class="nav nav-tabs mt-4">
        <li class="nav-item" *ngIf="myContent && myContent.items.length > 0">
          <a class="nav-link active" [class.active]="currentList == 'myWork'" (click)="activateList('myWork')">
            <h2>My Work</h2>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" [class.active]="currentList == 'available'" (click)="activateList('available')">
            <h2>Available</h2>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [class.active]="currentList == 'parked'" (click)="activateList('parked')">
            <h2>Parked</h2>
          </a>
        </li>
      </ul>
      <table class="table" *ngIf="currentList == 'available'">
        <thead class="thead-dark">
          <tr>
            <th scope="col"></th>
            <th scope="col">Content Key</th>
            <th scope="col">Receipt Key</th>
            <th scope="col">Created On</th>
          </tr>
        </thead>
        <tbody *ngIf="availableContent.items.length > 0">
          <tr *ngFor="let content of availableContent.items">
            <td>
              <app-resource-thumbnail-viewer [url]="content.resourceUrl" [mediaType]="content.resourceMediaType"></app-resource-thumbnail-viewer>
            </td>
            <td>
              <a *ngIf="content.contentModerationType !== ruleSetTypes.UserGenerated" [routerLink]="['/content', 'receipt', content.accountKey, content.contentKey]" (click)="requestItem(content)">{{ content.contentKey }}</a>
              <a *ngIf="content.contentModerationType === ruleSetTypes.UserGenerated" [routerLink]="['/content', 'user-generated', content.accountKey, content.contentKey]" (click)="requestItem(content)">{{ content.contentKey }}</a>
            </td>
            <td>{{ content.receiptKey }}</td>
            <td>{{ content.createdOn | date: 'medium' }}</td>
          </tr>
          <tr *ngIf="availableContent.items.length >= constants.homepageAvailableMaxRecords">
            <td colspan="4">This is only showing the top {{ constants.homepageAvailableMaxRecords }} records.</td>
          </tr>
        </tbody>
        <tbody *ngIf="availableContent.items.length === 0">
          <tr>
            <td colspan="4">
              No items pending review
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table" *ngIf="currentList == 'parked'">
        <thead class="thead-dark">
          <tr>
            <th scope="col"></th>
            <th scope="col">Content Key</th>
            <th scope="col">Created On</th>
            <th scope="col">Parked By</th>
            <th scope="col">Parked On</th>
            <th scope="col">Reason</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody *ngIf="parkedContent.items.length > 0">
          <tr *ngFor="let content of parkedContent.items">
            <td>
              <app-resource-thumbnail-viewer [url]="content.resourceUrl" [mediaType]="content.resourceMediaType"></app-resource-thumbnail-viewer>
            </td>
            <td>
              {{ content.contentKey }}
            </td>
            <td>{{ content.createdOn | date: 'medium' }}</td>
            <td>{{ content.parkedBy }}</td>
            <td>{{ content.parkedOn | date: 'medium' }}</td>
            <td>{{ content.parkedReason }}</td>
            <td>
              <button class="btn btn-link" *ngIf="showActionButtons" (click)="unpark(content)">Unpark</button>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="parkedContent.items.length === 0">
          <tr>
            <td colspan="4">
              No items are parked at this time
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table" *ngIf="currentList == 'myWork'">
        <thead class="thead-dark">
          <tr>
            <th scope="col"></th>
            <th scope="col">Content Key</th>
            <th scope="col">Receipt Key</th>
            <th scope="col">Created On</th>
          </tr>
        </thead>
        <tbody *ngIf="myContent.items.length > 0">
          <tr *ngFor="let content of myContent.items" class="table-primary">
            <td>
              <app-resource-thumbnail-viewer [url]="content.resourceUrl" [mediaType]="content.resourceMediaType"></app-resource-thumbnail-viewer>
            </td>
            <td>
              <a [routerLink]="['/content', 'receipt', content.accountKey, content.contentKey]" (click)="requestItem(content)">{{ content.contentKey }}</a>
            </td>
            <td>{{ content.receiptKey }}</td>
            <td>{{ content.createdOn | date: 'medium' }}</td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </div>
</div>
