import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { catchError, retry } from 'rxjs/operators';
import { ProgramDashboardModel, AvailableContentModel, SearchResultsModel, DashboardModel, DashboardSearchModel, ProgramAnalyticsReportResultsModel, AnalyticsExtractModel } from '../models/dashboard-model';
import { BaseApi } from './base-api';
import { HttpClient } from '@angular/common/http';
import { NextContentForModerationModel, ReceiptContentForModerationModel, ReceiptContentForExamineModel, VersionModel } from '../models/moderation-models';
import { environment } from '../../environments/environment';
import { Constants } from '../constants';
import { SaveModeratedReceiptResults } from '../models/save-moderated-receipt-results.model';
import { UserGeneratedContentForModerationModel } from '../models/user-generated-content-for-moderation.model';
import { SaveModeratedUserGeneratedResults } from '../models/save-moderated-user-generated-results.model';
import { UserGeneratedContentForExamineModel } from '../models/user-generated-content-for-examine.model';

@Injectable()
export class ModerationApiService extends BaseApi {
  constructor(private http: HttpClient) {
    super();
  }

  public getVersion(): Observable<VersionModel> {
    return this.http
      .get<VersionModel>(super.urlFor('/.well-check/version'))
      .pipe(retry(environment.retryAttempts), catchError(this.handleError));
  }

  public getDashboard(search: DashboardSearchModel): Observable<DashboardModel> {
    const showNonLivePrograms = search.showNonLivePrograms == null ? '' : search.showNonLivePrograms.toString();
    return this.http
      .get<DashboardModel>(super.urlFor(`/moderation/dashboard?keyword=${encodeURI(search.keyword)}&showNonLivePrograms=${encodeURI(showNonLivePrograms)}`))
      .pipe(retry(environment.retryAttempts), catchError(this.handleError));
  }

  public getProgramDashboard(programKey: string): Observable<ProgramDashboardModel> {
    return this.http
      .get<ProgramDashboardModel>(super.urlFor(`/moderation/dashboard/${programKey}`))
      .pipe(retry(environment.retryAttempts), catchError(this.handleError));
  }

  public getProgramAnalyticsReportDefinitions(programKey: string): Observable<ProgramAnalyticsReportResultsModel> {
    return this.http
      .get<ProgramAnalyticsReportResultsModel>(super.urlFor(`/analytics/analytics/reports/${programKey}`))
      .pipe(retry(environment.retryAttempts), catchError(this.handleError));
  }

  public getProgramAnalyticsExtract(programKey: string, extract: AnalyticsExtractModel): Observable<any> {
    return this.http
      .get(super.urlFor(`/analytics/analytics/reports/${programKey}/extract?extract=${JSON.stringify(extract)}`), { responseType: 'arraybuffer'})
      .pipe(retry(environment.retryAttempts), catchError(this.handleError));
  }

  public getReportMain(extract: AnalyticsExtractModel): Observable<any> {
    return this.http
      .get(super.urlFor(`/analytics/analytics/reports/main?extract=${JSON.stringify(extract)}`), { responseType: 'arraybuffer'})
      .pipe(retry(environment.retryAttempts), catchError(this.handleError));
  }

  public getAvailableContent(programKey: string): Observable<AvailableContentModel> {
    return this.http
      .get<AvailableContentModel>(super.urlFor(`/moderation/content/available/${programKey}?maxRecords=${Constants.homepageAvailableMaxRecords}`))
      .pipe(retry(environment.retryAttempts), catchError(this.handleError));
  }

  public getParkedContent(programKey: string): Observable<AvailableContentModel> {
    return this.http
      .get<AvailableContentModel>(super.urlFor(`/moderation/content/parked/${programKey}`))
      .pipe(retry(environment.retryAttempts), catchError(this.handleError));
  }

  public searchContent(keyword): Observable<SearchResultsModel> {
    return this.http
      .post<SearchResultsModel>(super.urlFor('/moderation/content/search'), { keyword: keyword })
      .pipe(retry(environment.retryAttempts), catchError(this.handleError));
  }

  public getNextAvailableContent(programKey: string): Observable<NextContentForModerationModel> {
    // Don't retry POSTs.
    // Don't catchError() as the caller handles it explicitly.
    return this.http.post<NextContentForModerationModel>(super.urlFor('/moderation/content/next'), { programKey: programKey});
  }

  public getMyStartedContent(programKey: string): Observable<AvailableContentModel> {
    return this.http
      .get<AvailableContentModel>(super.urlFor(`/moderation/content/available/my/${programKey}`))
      .pipe(retry(environment.retryAttempts), catchError(this.handleError));
  }

  public assignAndGetReceiptContentForModeration(accountKey: string, contentKey: string): Observable<ReceiptContentForModerationModel> {
    // Don't retry POSTs
    // Don't catchError() as the caller handles it explicitly.
    return this.http
      .post<ReceiptContentForModerationModel>(super.urlFor(`/moderation/content/receipt/${accountKey}/${contentKey}/assign`), {});
  }

  public assignAndGetUserGeneratedContentForModeration(accountKey: string, contentKey: string): Observable<UserGeneratedContentForModerationModel> {
    // Don't retry POSTs
    // Don't catchError() as the caller handles it explicitly.
    return this.http
      .post<UserGeneratedContentForModerationModel>(super.urlFor(`/moderation/content/user-generated/${accountKey}/${contentKey}/assign`), {});
  }

  public parkReceiptContent(accountKey: string, contentKey: string, reason: string): Observable<Object> {
    // Don't retry POSTs
    return this.http
      .post(super.urlFor(`/moderation/content/receipt/${accountKey}/${contentKey}/park`), {reason: reason})
      .pipe(catchError(this.handleError));
  }

  public unparkAndGetReceiptContentForModeration(accountKey: string, contentKey: string): Observable<ReceiptContentForModerationModel> {
    // Don't retry POSTs
    return this.http
      .post<ReceiptContentForModerationModel>(super.urlFor(`/moderation/content/receipt/${accountKey}/${contentKey}/unpark`), {})
      .pipe(catchError(this.handleError));
  }

  public unparkAndGetUserGeneratedContentForModeration(accountKey: string, contentKey: string): Observable<UserGeneratedContentForModerationModel> {
    // Don't retry POSTs
    return this.http
      .post<UserGeneratedContentForModerationModel>(super.urlFor(`/moderation/content/user-generated/${accountKey}/${contentKey}/unpark`), {})
      .pipe(catchError(this.handleError));
  }

  public getReceiptContentForExamine(accountKey: string, contentKey: string): Observable<ReceiptContentForExamineModel> {
    return this.http
      .get<ReceiptContentForExamineModel>(super.urlFor(`/moderation/content/receipt/${accountKey}/${contentKey}/review`))
      .pipe(retry(environment.retryAttempts), catchError(this.handleError));
  }

  public getUserGeneratedContentForExamine(accountKey: string, contentKey: string): Observable<UserGeneratedContentForExamineModel> {
    return this.http
      .get<UserGeneratedContentForExamineModel>(super.urlFor(`/moderation/content/user-generated/${accountKey}/${contentKey}/review`))
      .pipe(retry(environment.retryAttempts), catchError(this.handleError));
  }

  public abortModerationOfContent(accountKey: string, contentKey: string): Observable<Object> {
    // Don't retry DELETEs
    return this.http.delete(super.urlFor(`/moderation/content/${accountKey}/${contentKey}/assign`)).pipe(catchError(this.handleError));
  }

  public saveModerationForReceipt(accountKey: string, contentKey: string, data: ReceiptContentForModerationModel, bypassNewSecondPassCollisions: boolean): Observable<SaveModeratedReceiptResults> {
    // Don't retry POSTs
    return this.http
      .post(super.urlFor(`/moderation/content/receipt/${accountKey}/${contentKey}/save?bypassNewSecondPassCollisions=${bypassNewSecondPassCollisions}`), data)
      .pipe(catchError(this.handleError));
  }

  public saveModerationForUserGenerated(accountKey: string, contentKey: string, data: UserGeneratedContentForModerationModel): Observable<SaveModeratedUserGeneratedResults> {
    // Don't retry POSTs
    return this.http
      .post(super.urlFor(`/moderation/content/user-generated/${accountKey}/${contentKey}/save`), data)
      .pipe(catchError(this.handleError));
  }

  public saveCannotModerate(accountKey: string, contentKey: string, reason: string): Observable<Object> {
    // Don't retry POSTs
    return this.http
      .post(super.urlFor(`/moderation/content/${accountKey}/${contentKey}/cannot-moderate`), {
        reason: reason
      })
      .pipe(catchError(this.handleError));
  }

  /**
   * Handles errors for http calls. Should not handle 401 responses,
   *  as those are handled by the {@link AuthInterceptor}.
   *
   * @param {any} err the error that was caused.
   * @param {Observable} caught the source observable that was caught.
   * @return {ErrorObservable} An observable that simply contains the error message.
   * @name handleError
   */
  private handleError<T>(err: any, caught: Observable<T>): ErrorObservable<any> {
    let message = 'An error has occurred. Please try again.';

    if (err.error instanceof ErrorEvent) {
      // client-side
      console.error('An error occurred:', err.error.message);
    } else {
      // backend response
      console.error(`Backend return code ${err.status}, body was: ${err.error}`);
      // We don't catch 401 here as that is handled in auth-interceptor.service.ts
      switch (err.status) {
        // TODO: Add more HTTP response code messages here.
        case 500:
          message = 'A problem occurred on the server. Advise a developer to investigate.';
          break;
        case 404:
        case 0:
        default:
          message = 'Unable to connect to server. Please try again.';
          break;
      }
    }

    return ErrorObservable.create(message);
  }
}
