import { ModerationOutcomeModel } from './models/moderation-models';

export class Constants {
  public static homepageAvailableMaxRecords = 20;
  public static moderationTimeoutWarningSeconds = 60;
  public static threeFactorCategory = 'ThreeFactor';
  public static twoFactorCategory = 'TwoFactor';
  public static moderationOutcomeSuccessful = 'Successful';
  public static moderationOutcomeDuplicateImage = 'DuplicateImage';
  public static moderationOutcomeDuplicateImageHuman = 'DuplicateImageHuman';

  public static fingerprintFirstPassDateStore = 'FirstPassDateStore';
  public static fingerprintFirstPassDateStoreFuzzy = 'FirstPassDateStoreFuzzy';
  public static fingerprintFirstPassDateStoreTotal = 'FirstPassDateStoreTotal';
  public static fingerprintFirstPassDateStoreTotalFuzzy = 'FirstPassDateStoreTotalFuzzy';

  public static KEY_CODE = {
    ESCAPE: 27
  };
}
