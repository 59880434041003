import { PipeTransform } from '@angular/core';
var SearchFilterPipe = /** @class */ (function () {
    function SearchFilterPipe() {
    }
    SearchFilterPipe.prototype.transform = function (items, field, value) {
        if (value === undefined || value === '') {
            return items;
        }
        return items.filter(function (it) { return it[field].toLowerCase().indexOf(value.toLowerCase()) >= 0; });
    };
    return SearchFilterPipe;
}());
export { SearchFilterPipe };
