<div *ngIf="isAuthorized" class="container dashboard">
  <div *ngIf="isSubmitting">
    <app-loading-spinner></app-loading-spinner>
  </div>
  <div *ngIf="!isSubmitting">
    <div *ngIf="dashboard || error">
      <div *ngIf="error">
        <h1 class="error">Error!</h1>
        <p class="lead">{{ error }}</p>
      </div>
      <h1>Moderation Report</h1>
      <form>
        <div class="row">
          <div class="col-8">
            <div class="form-group">
              <label>Program</label>
              <select class="form-control" name="program" [(ngModel)]="extractRequest.programKey">
                <option [value]="''">Select Program (optional)</option>
                <option *ngFor="let program of dashboard.items" [value]="program.programKey">{{ program.programName }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <label>Start Date</label>
            <div class="input-group">
              <input [owlDateTime]="startOn" #startOnInput="ngModel" [(ngModel)]="extractRequest.startOn" type="text" class="form-control" name="startOnInput" />
              <owl-date-time #startOn pickerMode="dialog" pickerType="calendar" [hour12Timer]="true"></owl-date-time>
              <div class="input-group-append" [owlDateTimeTrigger]="startOn">
                <span class="input-group-text"> <span class="fa fa-calendar cursor-pointer"></span> </span>
              </div>
            </div>
          </div>
          <div class="col-4">
            <label>End Date</label>
            <div class="input-group">
              <input [owlDateTime]="endOn" (dateTimeChange)="setEndOnToEndOfDay()" #endOnInput="ngModel" [(ngModel)]="extractRequest.endOn" type="text" class="form-control" name="endOnInput" />
              <owl-date-time #endOn pickerMode="dialog" pickerType="calendar" [hour12Timer]="true"></owl-date-time>
              <div class="input-group-append" [owlDateTimeTrigger]="endOn">
                <span class="input-group-text"> <span class="fa fa-calendar cursor-pointer"></span> </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-2">
            <span class="pull-right" style="padding: 3px;">Quick Pick Ranges:</span>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-link btn-sm mr-1" (click)="setDateRange('day', -1)">Yesterday</button>
            <button type="button" class="btn btn-link btn-sm mr-1" (click)="setDateRange('week', -1)">Last Week</button>
            <button type="button" class="btn btn-link btn-sm mr-1" (click)="setDateRange('week', 0)">This Week</button>
            <button type="button" class="btn btn-link btn-sm mr-1" (click)="setDateRange('month', -1)">Last Month</button>
            <button type="button" class="btn btn-link btn-sm mr-1" (click)="setDateRange('month', 0)">This Month</button>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-8">
            <button class="btn btn-outline-info" (click)="performDownload()" [disabled]="isDownloading">
              <span class="fa fa-download" *ngIf="!isDownloading"></span><span *ngIf="isDownloading" class="fa fa-spin fa-spinner"></span> Download
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
