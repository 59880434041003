import * as tslib_1 from "tslib";
import { SecurePageBase } from '../secure-page-base';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router, ActivatedRoute } from '@angular/router';
import { ModerationApiService } from '../../apis/moderation-api.service';
var UserGeneratedExamineDetailComponent = /** @class */ (function (_super) {
    tslib_1.__extends(UserGeneratedExamineDetailComponent, _super);
    function UserGeneratedExamineDetailComponent(oidc, router, moderationApi, _route) {
        var _this = _super.call(this, oidc, router) || this;
        _this.moderationApi = moderationApi;
        _this._route = _route;
        _this.showPassingRuleSets = true;
        _this.showFailingRuleSets = true;
        _this.zoomIsEnabled = false;
        _this.accountKey = _this._route.snapshot.params['accountKey'];
        _this.contentKey = _this._route.snapshot.params['contentKey'];
        return _this;
    }
    UserGeneratedExamineDetailComponent.prototype.onAuthorized = function () {
        this.populateContent();
    };
    UserGeneratedExamineDetailComponent.prototype.onNgOnDestroy = function () {
    };
    UserGeneratedExamineDetailComponent.prototype.onShouldDisplayOwnUnauthorizedMessage = function () {
        return false;
    };
    UserGeneratedExamineDetailComponent.prototype.toggleRules = function (ruleSetItem) {
        ruleSetItem.showRules = !ruleSetItem.showRules;
    };
    UserGeneratedExamineDetailComponent.prototype.populateContent = function () {
        var _this = this;
        this.contentSubscription = this.moderationApi
            .getUserGeneratedContentForExamine(this.accountKey, this.contentKey)
            .subscribe(function (model) {
            _this.content = model;
            // For User Generated Content, there is likely far fewer rules, so will expect rule sets by default
            _this.content.ruleSets.forEach(function (r) { return r.showRules = true; });
            _this.contentSubscription.unsubscribe();
        }, function (error) {
            _this.error = error;
        });
    };
    return UserGeneratedExamineDetailComponent;
}(SecurePageBase));
export { UserGeneratedExamineDetailComponent };
