import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router';
import { Observable, timer } from 'rxjs';
import { take, map, catchError } from 'rxjs/operators';
import { ReceiptContentForModerationModel } from '../models/moderation-models';
import { ModerationApiService } from '../apis/moderation-api.service';
import { CurrentContentProviderService } from '../services/current-content-provider.service';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { Constants } from '../constants';
import { FingerprintCollisionModel } from '../models/fingerprint-collision.model';

@Injectable()
export class ContentResolver implements Resolve<ReceiptContentForModerationModel> {
  constructor(private _api: ModerationApiService, private _router: Router, private _currentContent: CurrentContentProviderService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ReceiptContentForModerationModel> {
    const accountKey = route.paramMap.get('accountKey') || route.parent.paramMap.get('accountKey');
    const contentKey = route.paramMap.get('contentKey') || route.parent.paramMap.get('contentKey');

    if (this._currentContent.isCurrent(accountKey, contentKey)) {
      return timer(0).pipe(
        map(() => {
          return null;
        })
      );
    }

    return this._api.assignAndGetReceiptContentForModeration(accountKey, contentKey).pipe(
      take(1),
      catchError((err: any, caught: Observable<ReceiptContentForModerationModel>) => {
        if (err.status === 401) {
          this._router.navigate(['/sessionexpired']);
          return null;
        } else {
          this._router.navigate(['/content', 'receipt', accountKey, contentKey, 'not-found']);
          return null;
        }
      }),
      map((model: ReceiptContentForModerationModel) => {
        if (model) {
          // prepare the model as a view model with some defaults
          model.receiptItems.forEach(ri => (ri.quantity = ri.quantity || 1));
          if (model.receipt.fingerprintCollisionGroups && model.receipt.fingerprintCollisionGroups.length > 0) {
            let groups = model.receipt.fingerprintCollisionGroups;
            const shaGroup = groups.find(g => g.category === 'Binary');
            if (shaGroup) {
              groups = groups.splice(groups.indexOf(shaGroup), 1);
            }
          }

          /* Since PurchasedOn is not timezone aware (it is a DateTime representing the date/time that is printed on the receipt),
            we want to treat it as UTC.
            Therefore, force the incoming value to be UTC by adding a Z at the end
          */
          if (model.receipt.purchasedOn && !model.receipt.purchasedOn.toString().endsWith('Z')) {
            model.receipt.purchasedOn = model.receipt.purchasedOn + 'Z';
          }

          if (model.receipt && model.receipt.fingerprintCollisionGroups) {
            model.receipt.fingerprintCollisionGroups.forEach(group => {
              group.collisions.forEach(collision => {
                this.determineOutcomeIndicators(collision);
              });
            });
          }

          this._currentContent.setCurrent(model);

          // Since a resubmitted content will not be OCRd, it will not get fingerprints, so this would show all the time. Instead, if it is a resubmit, skip the Review screen
          if (model.review != null && !model.content.parentContentKey) {
            this._router.navigate(['/content', 'receipt', accountKey, contentKey, 'review']);
            return null;
          }

          return null;
        } else {
          this._currentContent.clear();

          this._router.navigate(['/content', 'receipt', accountKey, contentKey, 'not-found']);
          return null;
        }
      })
    );
  }

  private determineOutcomeIndicators(item: FingerprintCollisionModel): void {
    switch (item.moderationOutcome) {
      case Constants.moderationOutcomeSuccessful:
        item.outcomeIndicatorText = 'P';
        item.outcomeIndicatorBadgeClass = 'badge-success';
        item.outcomeIndicatorTooltip = 'Processed';
        break;
      case Constants.moderationOutcomeDuplicateImage:
      case Constants.moderationOutcomeDuplicateImageHuman:
        item.outcomeIndicatorText = 'D';
        item.outcomeIndicatorBadgeClass = 'badge-dark';
        item.outcomeIndicatorTooltip = 'Duplicate Receipt';
        break;
      default:
        if (item.moderationOutcome) {
          item.outcomeIndicatorText = 'R';
          item.outcomeIndicatorBadgeClass = 'badge-danger';
          item.outcomeIndicatorTooltip = `Rejected - ${item.moderationOutcome}`;
        } else {
          // not moderated yet
          item.outcomeIndicatorText = 'Q';
          item.outcomeIndicatorBadgeClass = 'badge-secondary';
          item.outcomeIndicatorTooltip = 'Queued';
        }
        break;
    }

    item.outcomeIndicatorClasses = {
      badge: true,
    };
    item.outcomeIndicatorClasses[item.outcomeIndicatorBadgeClass] = true;

  }
}
