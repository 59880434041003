import * as tslib_1 from "tslib";
import { Constants } from '../../constants';
import { SecurePageBase } from '../secure-page-base';
import { ModerationApiService } from '../../apis/moderation-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { RuleSetType } from '../../models/rule-set-type.enum';
var ContentSearchComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ContentSearchComponent, _super);
    function ContentSearchComponent(oidc, router, moderationApi, _route) {
        var _this = _super.call(this, oidc, router) || this;
        _this.moderationApi = moderationApi;
        _this._route = _route;
        _this.isItemRequesting = false;
        _this.searchCritieria = _this._route.snapshot.params['criteria'];
        _this.constants = Constants;
        _this.ruleSetTypes = RuleSetType;
        _route.params.subscribe(function (val) { return _this.performSearch(); });
        return _this;
    }
    ContentSearchComponent.prototype.onAuthorized = function () {
        if (this.searchCritieria) {
            this.performSearch();
        }
    };
    ContentSearchComponent.prototype.performSearch = function () {
        var _this = this;
        if (!this.searchCritieria) {
            return;
        }
        this.searchCritieriaAsSearched = this.searchCritieria;
        this.isSearching = true;
        this.results = null;
        this.resultsSubscription = this.moderationApi.searchContent(this.searchCritieria).subscribe(function (model) {
            model.items.forEach(function (item) {
                if (item.moderationOutcome === Constants.moderationOutcomeSuccessful) {
                    item.moderationOutcomeSuccessful = true;
                    item.moderationOutcomeText = item.moderationOutcome;
                }
                else if (item.moderationOutcome) {
                    item.moderationOutcomeRejected = true;
                    item.moderationOutcomeText = item.moderationOutcome;
                }
                else {
                    item.moderationOutcomeText = '';
                    item.moderationOutcomeSuccessful = false;
                    item.moderationOutcomeRejected = false;
                }
            });
            _this.results = model;
            _this.isSearching = false;
            // We no long need this subscription. If the criteria changes, we resubscribe anyway
            _this.resultsSubscription.unsubscribe();
        }, function (error) {
            _this.error = error;
        });
    };
    ContentSearchComponent.prototype.onNgOnDestroy = function () {
    };
    ContentSearchComponent.prototype.onShouldDisplayOwnUnauthorizedMessage = function () {
        return false;
    };
    ContentSearchComponent.prototype.search = function () {
        this.router.navigate(['/content', 'search', this.searchCritieria]);
    };
    ContentSearchComponent.prototype.requestItem = function (item) {
        this.isItemRequesting = true;
        var typeRoute = item.contentModerationType === RuleSetType.UserGenerated ? 'user-generated' : 'receipt';
        this.router.navigate(['/content', typeRoute, item.accountKey, item.contentKey]);
    };
    return ContentSearchComponent;
}(SecurePageBase));
export { ContentSearchComponent };
