import * as tslib_1 from "tslib";
import { SecurePageBase } from '../secure-page-base';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';
import { DashboardSearchModel, AnalyticsExtractModel } from '../../models/dashboard-model';
import { ModerationApiService } from '../../apis/moderation-api.service';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
var ReportsHomeComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ReportsHomeComponent, _super);
    function ReportsHomeComponent(oidc, router, moderationApi) {
        var _this = _super.call(this, oidc, router) || this;
        _this.moderationApi = moderationApi;
        _this.isSubmitting = false;
        _this.isDownloading = false;
        _this.searchPayload = new DashboardSearchModel();
        _this.searchPayload.keyword = '';
        _this.searchPayload.showNonLivePrograms = null;
        _this.extractRequest = new AnalyticsExtractModel();
        _this.extractRequest.programKey = '';
        return _this;
    }
    ReportsHomeComponent.prototype.onNgOnDestroy = function () {
        if (this.dashboardSubscription) {
            this.dashboardSubscription.unsubscribe();
        }
    };
    ReportsHomeComponent.prototype.onAuthorized = function () {
        this.loadPrograms();
    };
    ReportsHomeComponent.prototype.onShouldDisplayOwnUnauthorizedMessage = function () {
        return false;
    };
    ReportsHomeComponent.prototype.loadPrograms = function () {
        var _this = this;
        this.isSubmitting = true;
        this.dashboardSubscription = this.moderationApi.getDashboard(this.searchPayload).subscribe(function (dashboardModel) {
            _this.dashboard = dashboardModel;
            _this.isSubmitting = false;
        }, function (error) {
            _this.error = error;
        });
    };
    ReportsHomeComponent.prototype.performDownload = function () {
        var _this = this;
        this.isDownloading = true;
        this.moderationApi.getReportMain(this.extractRequest).subscribe(function (data) {
            var blob = new Blob([data], { type: 'text/csv' });
            saveAs(blob, 'ModerationReport.xlsx');
            _this.isDownloading = false;
        });
    };
    ReportsHomeComponent.prototype.setEndOnToEndOfDay = function () {
        this.extractRequest.endOn = moment(this.extractRequest.endOn)
            .endOf('day')
            .toDate()
            .toISOString();
    };
    ReportsHomeComponent.prototype.setDateRange = function (unit, amount) {
        var first = moment()
            .utc()
            .startOf(unit)
            .add(amount, unit)
            .toDate();
        var last = moment()
            .utc()
            .endOf(unit)
            .add(amount, unit)
            .toDate();
        this.extractRequest.startOn = first.toISOString();
        this.extractRequest.endOn = last.toISOString();
    };
    return ReportsHomeComponent;
}(SecurePageBase));
export { ReportsHomeComponent };
