import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-digital-duplicate-indicator',
  templateUrl: './digital-duplicate-indicator.component.html',
  styleUrls: ['./digital-duplicate-indicator.component.css']
})
export class DigitalDuplicateIndicatorComponent implements OnInit {

  @Input() public parentContentKey: string;
  @Input() public isDuplicate: boolean;

  constructor() { }

  ngOnInit() {
  }

}
