import { OnInit } from '@angular/core';
import { FileIdentificationService } from '../../services/file-identification.service';
var ResourceViewerComponent = /** @class */ (function () {
    function ResourceViewerComponent(fileIdentifier) {
        this.fileIdentifier = fileIdentifier;
        this.ngxImages = new Array();
        this.ngxImageConfig = {
            allowFullscreen: true,
            wheelZoom: true
        };
    }
    Object.defineProperty(ResourceViewerComponent.prototype, "wheelZoom", {
        set: function (value) {
            this.ngxImageConfig.wheelZoom = value;
        },
        enumerable: true,
        configurable: true
    });
    ResourceViewerComponent.prototype.ngOnInit = function () {
        this.file = this.fileIdentifier.identify(this.url, this.mediaType);
        if (this.file.isImage) {
            this.ngxImages.push(this.url);
        }
    };
    return ResourceViewerComponent;
}());
export { ResourceViewerComponent };
