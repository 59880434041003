/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./resubmit-indicator.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./resubmit-indicator.component";
var styles_ResubmitIndicatorComponent = [i0.styles];
var RenderType_ResubmitIndicatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ResubmitIndicatorComponent, data: {} });
export { RenderType_ResubmitIndicatorComponent as RenderType_ResubmitIndicatorComponent };
function View_ResubmitIndicatorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "alert alert-warning-blue"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Resubmittal"])), (_l()(), i1.ɵted(-1, null, [" This is a resubmittal of original content. "])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "a", [["class", "ml-4"], ["target", "_blank"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { target: [0, "target"], routerLink: [1, "routerLink"] }, null), i1.ɵpad(6, 5), (_l()(), i1.ɵted(-1, null, ["View Original"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "_blank"; var currVal_3 = _ck(_v, 6, 0, "/content", "examine", _co.type, _co.accountKey, _co.contentKey); _ck(_v, 5, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).target; var currVal_1 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_0, currVal_1); }); }
export function View_ResubmitIndicatorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResubmitIndicatorComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contentKey; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ResubmitIndicatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-resubmit-indicator", [], null, null, null, View_ResubmitIndicatorComponent_0, RenderType_ResubmitIndicatorComponent)), i1.ɵdid(1, 114688, null, 0, i4.ResubmitIndicatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResubmitIndicatorComponentNgFactory = i1.ɵccf("app-resubmit-indicator", i4.ResubmitIndicatorComponent, View_ResubmitIndicatorComponent_Host_0, { accountKey: "accountKey", contentKey: "contentKey", type: "type" }, {}, []);
export { ResubmitIndicatorComponentNgFactory as ResubmitIndicatorComponentNgFactory };
