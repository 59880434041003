import { environment } from '../../environments/environment';

export class BaseApi {
  private urlRoot: string;
  constructor() {
    this.urlRoot = environment.urls.moderationApi;
    while (this.urlRoot.endsWith('/')) {
      this.urlRoot = this.urlRoot.substr(0, this.urlRoot.length - 1);
    }
  }

  protected urlFor(path: string): string {
    return `${this.urlRoot}${path}`;
  }
}
