/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./next-available.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./next-available.component";
import * as i5 from "angular-auth-oidc-client";
import * as i6 from "../../apis/moderation-api.service";
var styles_NextAvailableComponent = [i0.styles];
var RenderType_NextAvailableComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NextAvailableComponent, data: {} });
export { RenderType_NextAvailableComponent as RenderType_NextAvailableComponent };
function View_NextAvailableComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "display-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" There are no items available at this time. "])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 2), (_l()(), i1.ɵted(-1, null, ["Go Home"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 5, 0, "/program", _co.programKey); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
function View_NextAvailableComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "alert alert-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Finding next available item. This should take but a second, so if you see this for too long, something went wrong. "]))], null, null); }
export function View_NextAvailableComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NextAvailableComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NextAvailableComponent_2)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.noItemsAvailable; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.noItemsAvailable; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_NextAvailableComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-next-available", [], null, null, null, View_NextAvailableComponent_0, RenderType_NextAvailableComponent)), i1.ɵdid(1, 245760, null, 0, i4.NextAvailableComponent, [i5.OidcSecurityService, i2.Router, i6.ModerationApiService, i2.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NextAvailableComponentNgFactory = i1.ɵccf("app-next-available", i4.NextAvailableComponent, View_NextAvailableComponent_Host_0, {}, {}, []);
export { NextAvailableComponentNgFactory as NextAvailableComponentNgFactory };
