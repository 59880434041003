import * as tslib_1 from "tslib";
import { SecurePageBase } from '../secure-page-base';
import { Constants } from '../../constants';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router, ActivatedRoute } from '@angular/router';
import { CurrentContentProviderService } from '../../services/current-content-provider.service';
import { toDate } from '../../../i18n/date_util';
var ReceiptExamineDuplicatesComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ReceiptExamineDuplicatesComponent, _super);
    function ReceiptExamineDuplicatesComponent(oidc, router, route, currentContent) {
        var _this = _super.call(this, oidc, router) || this;
        _this.constants = Constants;
        _this.category = route.snapshot.params['category'];
        _this.categoryDescription = route.snapshot.queryParams['categoryDescription'];
        route.data.subscribe(function (data) {
            var model = currentContent.getCurrentExamineForReceipt();
            var fingerprintGroup = model.receipt.fingerprintCollisionGroups.filter(function (x) { return x.category === _this.category; })[0];
            if (fingerprintGroup) {
                // Show them latest on top like the Review screen does
                _this.collisions = fingerprintGroup.collisions.sort(function (a, b) { return toDate(b.createdOn).getDate() - toDate(a.createdOn).getDate(); });
            }
            _this.content = model;
        });
        return _this;
    }
    ReceiptExamineDuplicatesComponent.prototype.onAuthorized = function () { };
    ReceiptExamineDuplicatesComponent.prototype.onNgOnDestroy = function () { };
    ReceiptExamineDuplicatesComponent.prototype.onShouldDisplayOwnUnauthorizedMessage = function () {
        return false;
    };
    return ReceiptExamineDuplicatesComponent;
}(SecurePageBase));
export { ReceiptExamineDuplicatesComponent };
