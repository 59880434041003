<div *ngIf="isAuthorized" class="container dashboard">
    <div *ngIf="isItemRequesting">
      <app-loading-spinner></app-loading-spinner>
    </div>
    <div *ngIf="error">
      <h1 class="error">Error!</h1>
      <p class="lead">{{ error }}</p>
    </div>
    <div *ngIf="!error && !isItemRequesting">
      <h1>Search</h1>
      <summary>Searching by: Participant Key, Content Key</summary>
      <div class="row mt-4">
        <div class="col-6">
          <form #searchForm="ngForm" class="form-inline">
            <input type="text" name="searchCriteria" class="form-control" placeholder="Search by Participant" [(ngModel)]="searchCritieria"
            />
            <button class="btn btn-primary ml-3" [disabled]="isSearching" (click)="search()">Go <span *ngIf="isSearching" class="fa-spin fa-spinner fa"></span></button>
          </form>
        </div>
      </div>
      <ng-container *ngIf="results">
        <table class="table mt-3">
          <thead class="thead-dark">
            <tr>
              <th></th>
              <th scope="col">Submission Date</th>
              <th scope="col">Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody *ngIf="results.items.length > 0">
            <tr *ngFor="let content of results.items">
              <td>
                <app-resource-thumbnail-viewer [url]="content.resourceUrl" [mediaType]="content.resourceMediaType"></app-resource-thumbnail-viewer>
                <br/>
                <span *ngIf="content.moderatedOn; else unmoderated"><b>Moderated By: </b><span *ngIf="content.moderatedBy; else moderationSystem">{{ content.moderatedBy }}</span></span>
                <ng-template #moderationSystem>System</ng-template>
                <ng-template #unmoderated><i>Pending Moderation</i></ng-template>
              </td>
              <td>{{ content.createdOn | date: 'medium' }}</td>
              <td>
                <span class="badge" [class.badge-success]="content.moderationOutcomeSuccessful" [class.badge-danger]="content.moderationOutcomeRejected">{{ content.moderationOutcomeText }}</span>
                <span *ngIf="!content.moderationOutcome && !content.canBeAssigned" class="badge badge-info">Processing</span>
                <span *ngIf="!content.moderationOutcome && content.parkedBy" class="badge badge-warning">Parked</span>
              </td>
              <td>
                <a *ngIf="content.moderationOutcome" [routerLink]="['/content', 'examine', content.contentModerationType === ruleSetTypes.UserGenerated ? 'user-generated' : 'receipt', content.accountKey, content.contentKey]">View Details</a>
                <a *ngIf="content.canBeAssigned" [routerLink]="['/content', content.contentModerationType === ruleSetTypes.UserGenerated ? 'user-generated' : 'receipt', content.accountKey, content.contentKey]" (click)="requestItem(content)">Assign to Me</a>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="results.items.length === 0">
            <tr>
              <td colspan="4">
                No items found
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </div>
  </div>
