import * as tslib_1 from "tslib";
import { SecurePageBase } from '../secure-page-base';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router, ActivatedRoute } from '@angular/router';
import { ModerationApiService } from '../../apis/moderation-api.service';
import { Constants } from '../../constants';
import { CurrentContentProviderService } from '../../services/current-content-provider.service';
var ReceiptExamineDetailComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ReceiptExamineDetailComponent, _super);
    function ReceiptExamineDetailComponent(oidc, router, moderationApi, _route, currentContent) {
        var _this = _super.call(this, oidc, router) || this;
        _this.moderationApi = moderationApi;
        _this._route = _route;
        _this.showPassingRuleSets = true;
        _this.showFailingRuleSets = true;
        _this.constants = Constants;
        _this.accountKey = _this._route.snapshot.params['accountKey'];
        _this.contentKey = _this._route.snapshot.params['contentKey'];
        _this._route.data.subscribe(function (data) {
            _this.content = currentContent.getCurrentExamineForReceipt();
        });
        return _this;
    }
    ReceiptExamineDetailComponent.prototype.onAuthorized = function () {
        // this.populateContent();
    };
    ReceiptExamineDetailComponent.prototype.onNgOnDestroy = function () {
    };
    ReceiptExamineDetailComponent.prototype.onShouldDisplayOwnUnauthorizedMessage = function () {
        return false;
    };
    ReceiptExamineDetailComponent.prototype.toggleRules = function (ruleSetItem) {
        ruleSetItem.showRules = !ruleSetItem.showRules;
    };
    return ReceiptExamineDetailComponent;
}(SecurePageBase));
export { ReceiptExamineDetailComponent };
