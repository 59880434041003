import { timer as observableTimer } from 'rxjs';
import { UUID } from 'angular2-uuid';
var TimerService = /** @class */ (function () {
    function TimerService() {
        this.timer = {};
        this.subscription = {};
    }
    TimerService.prototype.getTimer = function () {
        return Object.keys(this.timer);
    };
    TimerService.prototype.getSubscription = function () {
        return Object.keys(this.subscription);
    };
    TimerService.prototype.newTimer = function (name, sec) {
        if (name === undefined || sec === undefined || this.timer[name]) {
            return false;
        }
        var o = observableTimer(0, sec * 1000);
        this.timer[name] = { second: sec, observable: o };
        return true;
    };
    TimerService.prototype.delTimer = function (name) {
        var _this = this;
        if (name === undefined || !this.timer[name]) {
            return false;
        }
        var s = this.getSubscription();
        // unsubscribe all subscription for queue 'name'
        s.forEach(function (i) {
            if (_this.subscription[i].name === name) {
                _this.unsubscribe(i);
            }
        });
        // delete queue 'name' subject and observable
        delete this.timer[name].observable;
        delete this.timer[name];
    };
    /**
     *
     * @param name
     * @param callback
     */
    TimerService.prototype.subscribe = function (name, callback) {
        if (!this.timer[name]) {
            return '';
        }
        var id = name + '-' + UUID.UUID();
        this.subscription[id] = {
            name: name,
            subscription: this.timer[name].observable.subscribe(callback)
        };
        return id;
    };
    /**
     *
     * @param id
     */
    TimerService.prototype.unsubscribe = function (id) {
        if (!id || !this.subscription[id]) {
            return false;
        }
        this.subscription[id].subscription.unsubscribe();
        delete this.subscription[id];
    };
    return TimerService;
}());
export { TimerService };
