import { Router } from '@angular/router';
import { ModerationApiService } from '../apis/moderation-api.service';
import { CurrentContentProviderService } from '../services/current-content-provider.service';
import { timer } from 'rxjs';
import { take, map, catchError } from 'rxjs/operators';
import { Constants } from '../constants';
var ReceiptExamineContentResolver = /** @class */ (function () {
    function ReceiptExamineContentResolver(_api, _router, _currentContent) {
        this._api = _api;
        this._router = _router;
        this._currentContent = _currentContent;
    }
    ReceiptExamineContentResolver.prototype.resolve = function (route, state) {
        var _this = this;
        var accountKey = route.paramMap.get('accountKey') || route.parent.paramMap.get('accountKey');
        var contentKey = route.paramMap.get('contentKey') || route.parent.paramMap.get('contentKey');
        if (this._currentContent.isCurrentExamine(accountKey, contentKey)) {
            return timer(0).pipe(map(function () {
                return null;
            }));
        }
        return this._api.getReceiptContentForExamine(accountKey, contentKey).pipe(take(1), catchError(function (err, caught) {
            if (err.status === 401) {
                _this._router.navigate(['/sessionexpired']);
                return null;
            }
            else {
                _this._router.navigate(['/content', 'examine', 'receipt', accountKey, contentKey, 'not-found']);
                return null;
            }
        }), map(function (model) {
            if (model) {
                // prepare the model as a view model with some defaults
                model.receiptItems.forEach(function (ri) { return (ri.quantity = ri.quantity || 1); });
                if (model.receipt.fingerprintCollisionGroups && model.receipt.fingerprintCollisionGroups.length > 0) {
                    var groups = model.receipt.fingerprintCollisionGroups;
                    var shaGroup = groups.find(function (g) { return g.category === 'Binary'; });
                    if (shaGroup) {
                        groups = groups.splice(groups.indexOf(shaGroup), 1);
                    }
                }
                if (model.receipt && model.receipt.fingerprintCollisionGroups) {
                    model.receipt.fingerprintCollisionGroups.forEach(function (group) {
                        group.collisions.forEach(function (collision) {
                            _this.determineOutcomeIndicators(collision);
                        });
                    });
                }
                _this._currentContent.setCurrentExamine(model);
                return null;
            }
            else {
                _this._currentContent.clearExamine();
                _this._router.navigate(['/content', 'receipt', accountKey, contentKey, 'not-found']);
                return null;
            }
        }));
    };
    ReceiptExamineContentResolver.prototype.determineOutcomeIndicators = function (item) {
        switch (item.moderationOutcome) {
            case Constants.moderationOutcomeSuccessful:
                item.outcomeIndicatorText = 'P';
                item.outcomeIndicatorBadgeClass = 'badge-success';
                item.outcomeIndicatorTooltip = 'Processed';
                break;
            case Constants.moderationOutcomeDuplicateImage:
            case Constants.moderationOutcomeDuplicateImageHuman:
                item.outcomeIndicatorText = 'D';
                item.outcomeIndicatorBadgeClass = 'badge-dark';
                item.outcomeIndicatorTooltip = 'Duplicate Receipt';
                break;
            default:
                if (item.moderationOutcome) {
                    item.outcomeIndicatorText = 'R';
                    item.outcomeIndicatorBadgeClass = 'badge-danger';
                    item.outcomeIndicatorTooltip = "Rejected - " + item.moderationOutcome;
                }
                else {
                    // not moderated yet
                    item.outcomeIndicatorText = 'Q';
                    item.outcomeIndicatorBadgeClass = 'badge-secondary';
                    item.outcomeIndicatorTooltip = 'Queued';
                }
                break;
        }
        item.outcomeIndicatorClasses = {
            badge: true
        };
        item.outcomeIndicatorClasses[item.outcomeIndicatorBadgeClass] = true;
    };
    return ReceiptExamineContentResolver;
}());
export { ReceiptExamineContentResolver };
